import Select from 'react-select'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import InputError from '../inputAlert/inputError'
import {Props} from "react-select";

export interface MySelectProps{
  placeholder?: string
    options: any[]
    isMulti?: boolean | any
    disabled?: boolean
    defaultValue?: any
}


export default function MySelect({isMulti, options, onChange, error, ...props }: MySelectProps) {
  return (
    <Select
      placeholder={!props.placeholder ? 'Выбрать' : props.placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={props.disabled}
      defaultValue={props.defaultValue}
      onChange={onChange}
      {...props}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          neutral0: '#ffffff', // input bg color
          neutral20: '#515CD1', // input border color
          //neutral30: '#243049',
          neutral80: '#1d273b', // input font color
          primary25: '#206bc4', // dropdown on hover color
          primary: '#206bc4', // dropdown chosen bg color, input bg on focus color
        },
      })}
      styles={{
        control: (provided, state) => ({
          ...provided,
          padding: '0',
        }),
        menu: (provided, state) => ({
          ...provided,
          backgroundColor: '#ffffff',
          border: '1px solid #515CD1',
          padding: '15px 24px',
        }),
        option: (provided, state) => ({
          ...provided,
          color: '#1d273b',
          backgroundColor: 'transparent',
          padding: '15px 24px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          backgroundColor: 'none',
        }),
        //внутреннние отступы для поля селекта
        valueContainer: (provided, state) => ({
          ...provided,
          height: '47px',
          padding: '0 24px',
          lineHeight: '16.8px',
          fontSize: '14px',
          color: '#8C8C8C',
        }),
        input: (provided, state) => ({
          ...provided,
          margin: '0',
          lineHeight: '16px',
        }),
      }}
    />
  )
}

export const ControlledSelect = ({ control, ...props }) => {
  return (
    <>
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <MySelect
            onBlur={onBlur}
            value={value}
            name={name}
            onChange={onChange}
            {...props}
          />
        )}
      />
      <InputError>{props.error}</InputError>
    </>
  )
}

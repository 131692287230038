import React, { useEffect, useState } from 'react'
import Form from 'react-validation/build/form'
import Select, { ControlledSelect } from '../../components/UI/newInput/select'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import Bordered from '../../components/UI/bordered/bordered'
import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'

import DropzoneComponent from 'react-dropzone-component'
import Button from '../../components/UI/button/button'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { platformRadio } from '../../utils/radioConsts'

import {
  createDealFromDraft,
  createDraft,
  getDrafts,
} from '../../services/offers.service'

import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Navbar from '../../components/UI/navBar/navbar'
import cls from '../../scss/components.scss'
import PageHeader from '../../components/pageHeader/page-header'
import FilesUploader from '../../components/UI/FilesUploader/filesUploader'

import { getBloggerById } from '../../services/blogger.service'
import {
  enoughBalance,
  isNumber,
  isUrl,
  notZero,
  required,
  validateField,
  validDeadline,
  validEmail,
} from '../../utils/validation'
import DateInput from '../../components/UI/newInput/dateInput'
import {
  parseFormObj,
  setKeyValue,
  validateFormObj,
} from '../../utils/formData'
import { INNModal } from '../../components/modals/INNModal'
import TextArea from '../../components/UI/newInput/textArea'
import { GenTaskModal } from '../../components/modals/genTaskModal'
import { delayedSetValue, typeEffectSetter } from '../../utils/misc'
import PriceInput from '../../components/UI/newInput/priceInput'
import {
  FieldValues,
  set,
  useForm,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { auctionSchema, offerSchema } from '../../utils/schemas'
import useField, { useFormCheckbox } from '../../hooks/useField'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Blogger, OfferDealCreateData, OfferDraft } from '../../types/abstract'
import { formatToUTC } from '../../utils/formatters'

const NewOfferCreate = () => {
  let { id }: { id: number } = useParams()
  let navigate: NavigateFunction = useNavigate()
  const [files, setFiles] = useState<any[]>([])
  const [genTaskVisible, setGenTaskVisible] = useState<boolean>(false)
  const [blogger, setBlogger] = useState<Blogger>(null)
  const [drafts, setDrafts] = useState<OfferDraft[]>([])
  const [filesInProgress, setFilesInProgress] = useState<any[]>([])
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  }: UseFormReturn<
    FieldValues,
    OfferDealCreateData
  > = useForm<OfferDealCreateData>({
    defaultValues: {
      locationImportant: false,
      saveToDrafts: true,
      createLink: true,
    },
    resolver: yupResolver(offerSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)

  const create = (data: OfferDealCreateData) => {
    console.log('FILES', filesInProgress)
    if (filesInProgress.length > 0)
      return toast.error('Дождитесь загрузки всех файлов')

    data.bloggerId = id
    data.acceptDeadline = formatToUTC(data.acceptDeadline)
    data.finishDeadline = data.acceptDeadline
    data.text = data.description
    data.platform = blogger.platform
    data.location = blogger.location
    data.themes = blogger.themes
    data.price = data.price ? data.price : 0
    data.files = files
    if (!data.price) {
      data.price = 0
    }
    createDealFromDraft(data)
      .then((res: { _id: number }) => {
        let id = res._id
        navigate(`/offer/${id}`, { replace: true })
      })
      .catch(err => {
        if (err.response.data.message === 'Not full info') {
          toast.error('Заполните все поля')
        } else {
          toast.error(err.response.data.message)
        }
      })
  }

  useEffect(() => {
    if (id) {
      getBloggerById(id)
        .then(res => {
          console.log(res)
          setBlogger(res)
        })
        .catch(() => {
          navigate('/404', { replace: true })
        })
    }
    getDrafts().then((res: OfferDraft[]) => {
      setDrafts(res)
    })
  }, [])

  // Установить поля в соответсвии с выбранным драфтом
  const applyDraft = (draft: { value: OfferDraft }) => {
    setValue('title', draft.value.title)
    setValue('description', draft.value.description)
    setValue('platform', draft.value.platform)
    setValue('url', draft.value.url)
    setValue('offerDraftId', draft.value._id)
    setFiles(draft.value.files)
  }

  console.log(watch())

  return (
    <div className="page">
      {/*<INNModal show={true}></INNModal>*/}
      <GenTaskModal
        show={genTaskVisible}
        onHide={() => setGenTaskVisible(false)}
        setOutput={value => {
          delayedSetValue(value, value => {
            setValue('description', value)
          })
        }}></GenTaskModal>
      <Navbar />
      <div className="page-wrapper">
        <div className="d-flex column align-items-center mb-40">
          <h2 className="text--page-title mb-3">Создание предложение</h2>
          <div>
            <span className="text--gradient fw-bold text-uppercase ">
              Предложение для блогера @{blogger?.username}
            </span>
          </div>
        </div>
        <div className="page-body mt-0">
          <div className={'container-xl justify-content-center d-flex'}>
            <form onSubmit={handleSubmit(create)} className="col-md-8 col-9">
              <div className="card-body">
                <div>
                  <div className="row">
                    <div className="mb-3">
                      <div className="mb-5">
                        <ControlledSelect
                          control={control}
                          name={'offerDraftId'}
                          onChange={(draft: { value: OfferDraft }) => {
                            applyDraft(draft)
                            trigger()
                          }}
                          placeholder={'Выбрать существующее предложение'}
                          options={drafts.map(item => {
                            return { label: item.title, value: item }
                          })}></ControlledSelect>
                      </div>

                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Заголовок предложения"
                          {...getFieldProps('title')}
                        />
                      </div>

                      <div className="mb-5">
                        <div className="mb-5 d-flex row row-gap-3 flex-wrap">
                          <div className="col-md-8 h-100">
                            <TextArea
                              style={{ minHeight: '300px', minWidth: '200px' }}
                              rows="6"
                              className="h-100"
                              placeholder={`Задание\n(информация о продукте, какую задачу ставите перед
блогерами, техническое задание, описание креатива)`}
                              {...getFieldProps('description')}></TextArea>
                          </div>
                          <div className="col-md-4 h-100">
                            <ul className="mb-3 list-unstyled text--purple">
                              <li>— информация о продукте,</li>
                              <li>— какую задачу ставите перед блогерами,</li>
                              <li>— техническое задание,</li>
                              <li>— описание креатив</li>
                              <li>— интересы, возраст и пол ЦА</li>
                            </ul>
                            <Button
                              type={'button'}
                              onClick={e => {
                                e.preventDefault()
                                setGenTaskVisible(true)
                              }}
                              className="text-wrap btn btn_secondary">
                              сгенерировать нейросетью
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col ">
                        <div className="row row-wrap-961">
                          <div className="col full-width-961 mb-3">
                            <DateInput
                              max="9999-12-31"
                              className="form-control"
                              label="Дата размещения"
                              error={errors?.acceptDeadline?.message}
                              value={watch('acceptDeadline')}
                              onChange={e => {
                                setValue('acceptDeadline', e.target.value)
                                console.log(e.target.value)
                                trigger('acceptDeadline')
                              }}
                            />
                          </div>
                          <div className="col full-width-961 mb-3">
                            <PriceInput
                              type="number"
                              className="form-control"
                              placeholder="Бюджет на рекламу"
                              {...getFieldProps('price')}
                            />
                          </div>
                        </div>
                        <div className="mb-5">
                          <NewInput
                            type="text"
                            className="form-control"
                            placeholder="Ссылка, которую должен разместить блогер"
                            {...getFieldProps('url')}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              Фото-, видео- материалы, документы, с которыми
                              необходимо ознакомиться для правильного выполнения
                              работы'
                            </Tooltip>
                          }>
                          <label className="mb-2 text-16-regular">
                            Материалы к заданию(?)
                          </label>
                        </OverlayTrigger>
                        <FilesUploader
                          setFiles={setFiles}
                          page="offer"
                          setFilesInProgress={
                            setFilesInProgress
                          }></FilesUploader>
                        <span className="text--disabled mt-2 d-block">
                          Презентация, макеты, референсы, креативы, образцы
                          (все, что бы вы хотели увидеть в рекламе у блогера)
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
              <div className="d-flex column justify-content-center align-items-center">
                <div className="col-auto mb-3">
                  <Button
                    disabled={
                      !isValid || isSubmitting || filesInProgress.length > 0
                    }
                    className="btn btn_primary w-280">
                    Создать предложение
                  </Button>
                </div>
                <div className="col">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Шаблоны можно повторно использовать для создания похожих
                        или аналогичных рекламных предложений
                      </Tooltip>
                    }>
                    <div className="col d-flex justify-content-center align-items-center">
                      <MyCheckBox {...getCheckboxProps('saveToDrafts')} />
                      <span>
                        Сохранить рекламное предложение как шаблон (?)
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewOfferCreate

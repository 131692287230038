import axios from 'axios'

const API_URL =
  (process.env.REACT_APP_BACKEND_API || 'http://127.0.0.1:8080/api/') +
  'bloggers'

export const getBloggerById = async id => {
  const { data } = await axios.get(API_URL + `/${id}`, {
    withCredentials: true,
  })
  console.log('Deals', data)

  if (!data) {
    await Promise.reject('No data')
  }

  return data
}

export const getBloggers = async filter => {
  console.log(API_URL)
  const { data } = await axios.get(API_URL + `?filter=${filter}`, {
    withCredentials: true,
  })
  console.log(data)
  return data
}

export const putBlogger = async (data, id) => {
  const response = await axios.put(API_URL + `/${id}` + `/update`, data, {
    withCredentials: true,
  })
  return response
}

export const deleteBlogger = async id => {
  return console.log('deleteBlogger', id)
  const response = await axios.delete(API_URL + `/${id}` + `/delete`, {
    withCredentials: true,
  })
  return response
}

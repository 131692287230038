import React, { useState, useRef, useEffect } from 'react'
import {Link, URLSearchParamsInit, useNavigate, useSearchParams} from 'react-router-dom'
import {useForm, SubmitHandler, DeepMap, FieldValues, UseFormWatch, Resolver, UseFormReturn} from 'react-hook-form';

import CheckButton from 'react-validation/build/button'
import AuthService from '../services/auth.service'
import { BrandVk, BrandInstagram, BrandYoutube } from 'tabler-icons-react'
import '@tabler/core/dist/css/tabler.min.css'
import { translateServerMsg } from '../utils/functional'
import Button from '../components/UI/button/button'
import NewInput from '../components/UI/newInput/newInput.tsx'
import { InputError } from '../components/UI/inputAlert/inputError'
import MyCheckBox from '../components/UI/myCheckBox/myCheckBox'
import { string, object } from 'yup'

import Bordered from '../components/UI/bordered/bordered'
import NewPasswordInput from '../components/UI/newInput/newPasswordInput.tsx'
import {YupShape} from "../types/auth/yup";
import AuthCard from '../components/AuthCard/authCard'
import { yupResolver } from '@hookform/resolvers/yup'
import { validEmail } from '../utils/validation'
import useField from '../hooks/useField'
import { LoginType } from '../types/auth/auth'
import {HandleLogin} from "../types/functional";

//import '@tabler/icons/iconfont/tabler-icons.min.css';
import('@tabler/core/dist/js/tabler.min.js')


export default function Login() {
  let navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const loginSchema = object<YupShape<LoginType>>().shape({
    email: string()
      .required('Обязательное поле')
      .test('email', 'Некорректный email', validEmail),

    password: string()
      .required('Обязательное поле')
      .min(6, 'Минимум 6 символов'),
  })


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    trigger,
  }:UseFormReturn<LoginType> = useForm<LoginType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const getFieldProps = useField(register, trigger, errors)

  console.log(watch())

  const handleLogin: HandleLogin = ({ email, password }) => {
    setMessage('')
    setLoading(true)

    if (isValid) {
      AuthService.login(email, password).then(
        () => {
          if (searchParams.get('redirect'))
            navigate(searchParams.get('redirect'))
          else navigate('/')
          window.location.reload()
        },
        error => {
          const resMessage: string =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()

          setLoading(false)
          setMessage(translateServerMsg(resMessage))
        }
      )
    } else {
      setLoading(false)
    }
  }


  return (
    <AuthCard
      bottomContent={
        <div className="text-center text-muted mt-3 ">
          Еще нет аккаунта?{' '}
          <Link to="/register" className="text--purple fw-bold" tabIndex={-1}>
            Зарегистрироваться!
          </Link>
        </div>
      }
    >
      <h2 className="text-center mb-4 text--auth-title modal_title text-uppercase">
        Войти в аккаунт
      </h2>
      {message && (
        <div className="mb-3">
          <InputError>{message}</InputError>
        </div>
      )}
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="mb-3">
          <NewInput
            type="email"
            className="form-control"
            placeholder="Электронная почта"
            autoComplete="off"
            {...getFieldProps('email')}
          />
        </div>
        <div className="mb-3">
          <NewPasswordInput
            placeholder="Пароль"
            autoComplete="off"
            {...getFieldProps('password')}
          />
        </div>
        <div className="mb-40">
          <label className="">
            <MyCheckBox type="checkbox"></MyCheckBox>
            <span className=" text--disabled">
              Запомнить меня на этом устройстве
            </span>
          </label>
        </div>
        <div className="form-footer d-flex justify-content-center">
          <Button
            type="submit"
            className="btn btn_primary w-240"
            disabled={!isValid}
          >
            {' '}
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Войти
          </Button>
        </div>
        <div className="mt-20 d-flex justify-content-center text-decoration-underline">
          <Link style={{ color: 'gray' }} to="/forgotpassword">
            Не помню пароль
          </Link>
        </div>
      </form>
    </AuthCard>
    /*
                            <div className="hr-text">or</div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><a href="/api/auth/vkontakte" className="btn btn-white w-100 mb-2">
                                        <BrandVk size="24" className="icon" />
                                        Войти через VK
                                    </a></div>
                                    <div className="col-12"><a href="/api/auth/instagram" className="btn btn-white w-100">
                                        <BrandInstagram size="24" className="icon" />
                                        Войти через Instagram
                                    </a></div>
                                </div>
                            </div>

                                        */
  )
}

import React from 'react'
import cls from './walletCard.module.scss'
///import walletCard.module.scss///

const walletCard = ({ value, descr, ...props }) => {
  return (
    <div
      className={
        cls['wallet-card'] +
        ' column d-flex justify-content-between ' +
        props.className
      }
      {...props}
    >
      <div className="d-flex justify-content-between column-gap-3 mb-3">
        <WalletIcon />
        <span className="f-geometria fw-bold ">
          {(value ? value : 0).toLocaleString().replace(',', '.')}₽
        </span>
      </div>
      <span className="text-start text-uppercase text-14-regular">{descr}</span>
    </div>
  )
}

const WalletIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.86126 2.84872C2.09498 2.9109 1.34526 2.9865 0.785278 3.07488C0.291296 3.15265 0 3.4673 0 4.09814V5.78593V11.44V21.1466C0 21.373 0.0827803 21.59 0.23013 21.7501C0.37748 21.9101 0.57733 22 0.785714 22H21.2143C21.4227 22 21.6225 21.9101 21.7699 21.7501C21.9172 21.59 22 21.373 22 21.1466V5.78593C22 5.5596 21.9172 5.34254 21.7699 5.1825C21.6225 5.02246 21.4227 4.93255 21.2143 4.93255H5.62352C5.41657 4.80196 5.18058 4.71209 4.92301 4.71209V3.65249H19.6674V0.853433C19.6674 0.72716 19.6416 0.602458 19.5919 0.488322C19.5422 0.374185 19.4697 0.273459 19.3798 0.193406C19.2899 0.113353 19.1847 0.0559699 19.0719 0.0253945C18.9591 -0.00518092 18.8415 -0.00818626 18.7275 0.0165949L2.86126 2.84872ZM15.6515 14.8854C15.9099 15.0729 16.2138 15.173 16.5246 15.173C16.9413 15.173 17.341 14.9932 17.6357 14.6731C17.9304 14.3531 18.096 13.9189 18.096 13.4663C18.096 13.1287 18.0038 12.7987 17.8311 12.5181C17.6585 12.2374 17.4131 12.0186 17.1259 11.8895C16.8388 11.7603 16.5228 11.7265 16.218 11.7923C15.9132 11.8582 15.6332 12.0207 15.4134 12.2594C15.1936 12.4981 15.044 12.8022 14.9833 13.1333C14.9227 13.4644 14.9538 13.8076 15.0727 14.1194C15.1917 14.4313 15.3931 14.6978 15.6515 14.8854Z"
        fill="#515CD1"
      />
    </svg>
  )
}

export default walletCard

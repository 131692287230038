import React, { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'
import cls from './newInput.module.scss'
import InputError from '../inputAlert/inputError'
import {PhoneInputProps} from "../../../types/components";

const MyPhoneInputDub = ({
  value,
  error,
  register = (_) => {},
  name,
    onChange,
  ...props
}: PhoneInputProps) => {
  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <>
      <PhoneInput
        {...props}
        value={currentValue}
        localization={ru}
        country="ru"
        containerClass={cls['phone-input']}
        {...register(name)}
        onChange={(phone, phoneData) => {
          console.log('changed phone')
            onChange(phone, phoneData)
        }}
      />
      {error && <InputError>{error}</InputError>}
    </>
  )
}

export default MyPhoneInputDub

import React from 'react'
import { Navigate, Route, Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import AuthService from '../services/auth.service'

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = AuthService.getCurrentUser()
  const location = useLocation()

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirect=${location.pathname}`} />
  )
}

export default ProtectedRoute

import React, { useState } from 'react'
import cls from './newInput.module.scss'
import { ReactComponent as Filters } from '../../../assets/icons/filters.svg'
import {FiltersInputProps} from "../../../types/components";

const FiltersInput = ({ register = (_) => {}, onButtonClick, ...props }: FiltersInputProps) => {
  return (
    <div className={'column d-flex ' + props.className}>
      <div
        className={cls['filters-wrapper'] + ' input-group'}
      >
        <input
          value={props.value}
          type={'text'}
          placeholder={props.placeholder}
          className={cls.input + ' form-control'}
          {...register(props.name)}
          {...props}
        />
        <button
          type="button"
          onClick={onButtonClick}
          className={cls['filters'] + ' '}
        >
          <Filters />
        </button>
      </div>
      {props.error && props.error}
    </div>
  )
}

export default FiltersInput

import React, { useEffect, useState } from 'react'
import Form from 'react-validation/build/form'
import Select, { ControlledSelect } from '../../components/UI/newInput/select'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import Bordered from '../../components/UI/bordered/bordered'
import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'

import DropzoneComponent from 'react-dropzone-component'
import Button from '../../components/UI/button/button'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { platformRadio } from '../../utils/radioConsts'

import {
  createDealFromDraft,
  createDraft,
  getDealById,
  getDrafts,
  getOfferById,
  updateDeal,
} from '../../services/offers.service'

import {
  Link,
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Navbar from '../../components/UI/navBar/navbar'
import cls from '../../scss/components.scss'
import PageHeader from '../../components/pageHeader/page-header'
import FilesUploader from '../../components/UI/FilesUploader/filesUploader'

import { getBloggerById } from '../../services/blogger.service'
import {
  enoughBalance,
  isNumber,
  isOneWord,
  isUrl,
  notNegative,
  notZero,
  required,
  validateField,
  validDeadline,
  validEmail,
} from '../../utils/validation'
import DateInput from '../../components/UI/newInput/dateInput'
import { parseFormObj, validateFormObj } from '../../utils/formData'
import { formatDateForInput, formatToUTC } from '../../utils/formatters'
import TextArea from '../../components/UI/newInput/textArea'
import PriceInput from '../../components/UI/newInput/priceInput'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { offerSchema } from '../../utils/schemas'
import useField, { useFormCheckbox } from '../../hooks/useField'
import { GenTaskModal } from '../../components/modals/genTaskModal'
import { delayedSetValue } from '../../utils/misc'

import { BackArrowButton } from '../../components/modals/backArrowButton'
import {
  Blogger,
  OfferDeal,
  OfferDealCreateData,
  OfferDealData,
  OfferDraft,
} from '../../types/abstract'

const UpdateOffer = () => {
  let { id }: { id: number } = useParams()
  let navigate: NavigateFunction = useNavigate()
  const [files, setFiles] = useState<string[]>([])
  const [initialFiles, setInitialFiles] = useState<string[]>([])
  const [genTaskVisible, setGenTaskVisible] = useState<boolean>(false)
  const [blogger, setBlogger] = useState<Blogger>(null)
  const [drafts, setDrafts] = useState<OfferDraft[]>([])
  const [filesInProgress, setFilesInProgress] = useState<string[]>([])

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({
    defaultValues: { locationImportant: false },
    resolver: yupResolver(offerSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)

  const updateOffer = (data: OfferDealCreateData) => {
    if (filesInProgress.length > 0) {
      return toast.error('Подождите, пока файлы загрузятся')
    }
    data.bloggerId = id
    data.acceptDeadline = formatToUTC(data.acceptDeadline)
    data.finishDeadline = data.acceptDeadline
    data.description = data.description
    data.platform = blogger.platform
    data.price = data.price ? data.price : 0
    data.files = files
    if (!data.price) {
      data.price = 0
    }
    updateDeal(id, data)
      .then(res => {
        navigate(`/offer/${id}`, { replace: true })
      })
      .catch(err => {
        if (err.response.data.message === 'Not full info') {
          toast.error('Заполните все поля')
        } else {
          toast.error(err.response.data.message)
        }
      })
  }

  useEffect(() => {
    if (id) {
      getDealById(id)
        .then((res: OfferDeal) => {
          for (let key in res.offerData) {
            setValue(key, res.offerData[key], { shouldValidate: true })
          }
          setValue('acceptDeadline', formatDateForInput(res.acceptDeadline), {
            shouldValidate: true,
          })
          setValue('price', res.price, { shouldValidate: true })
          setBlogger(res.blogger)
          setFiles(res.offerData.files)
          setInitialFiles(res.offerData.files)
        })
        .catch(() => {
          navigate('/404', { replace: true })
        })
    }
    getDrafts().then(res => {
      setDrafts(res)
    })
  }, [])

  // Установить поля в соответсвии с выбранным драфтом

  return (
    <div className="page">
      <GenTaskModal
        show={genTaskVisible}
        onHide={() => setGenTaskVisible(false)}
        setOutput={value => {
          delayedSetValue(value, value => {
            setValue('description', value)
          })
        }}></GenTaskModal>
      <Navbar />
      <div className="page-wrapper container-xl">
        <div className="d-flex  align-items-center column">
          <div className="position-relative row mt-5 mb-4 col-md-8  row-gap-5 col-9">
            <Link
              style={{ left: 0 }}
              className="form__back-arrow"
              to={`/offer/` + id}>
              <BackArrowButton></BackArrowButton>
            </Link>
            <h2 className="m-0 form__title text--page-title">
              Отредактировать предложение
            </h2>
          </div>
          <div className="col-md-8 col-9 d-flex justify-content-center ">
            <span className="text-center  text--gradient fw-bold text-uppercase">
              Предложение для блогера @{blogger?.username}
            </span>
          </div>
        </div>

        <div className="page-body">
          <div className={' justify-content-center d-flex '}>
            <form
              onSubmit={handleSubmit(updateOffer)}
              className="col-md-8 col-9">
              <div className="card-body">
                <div>
                  <div className="row">
                    <div className="mb-3">
                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Заголовок предложения"
                          {...getFieldProps('title')}
                        />
                      </div>

                      <div className="mb-5">
                        <div className="mb-5 d-flex row row-gap-3 flex-wrap">
                          <div className="col-md-8 h-100">
                            <TextArea
                              style={{ minHeight: '300px', minWidth: '200px' }}
                              rows="6"
                              className="h-100"
                              placeholder={`Задание\n(информация о продукте, какую задачу ставите перед
блогерами, техническое задание, описание креатива)`}
                              {...getFieldProps('description')}></TextArea>
                          </div>
                          <div className="col-md-4 h-100">
                            <ul className="mb-3 list-unstyled text--purple">
                              <li>— информация о продукте,</li>
                              <li>— какую задачу ставите перед блогерами,</li>
                              <li>— техническое задание,</li>
                              <li>— описание креатив</li>
                              <li>— интересы, возраст и пол ЦА</li>
                            </ul>
                            <Button
                              type={'button'}
                              onClick={e => {
                                e.preventDefault()
                                setGenTaskVisible(true)
                              }}
                              className="text-wrap btn btn_secondary">
                              сгенерировать нейросетью
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col ">
                        <div className="row row-wrap-961">
                          <div className="col full-width-961 mb-3">
                            <DateInput
                              max="9999-12-31"
                              className="form-control"
                              label="Дата размещения"
                              value={watch('acceptDeadline')}
                              onChange={e => {
                                setValue('acceptDeadline', e.target.value)
                                trigger('acceptDeadline')
                                console.log(e.target.value)
                              }}
                            />
                          </div>
                          <div className="col full-width-961 mb-3">
                            <PriceInput
                              type="number"
                              className="form-control"
                              placeholder="Бюджет на рекламу"
                              {...getFieldProps('price')}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <NewInput
                            type="text"
                            className="form-control"
                            placeholder="Сайт, ссылка на магазин, социальные сети"
                            {...getFieldProps('url')}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              Фото-, видео- материалы, документы, с которыми
                              необходимо ознакомиться для правильного выполнения
                              работы'
                            </Tooltip>
                          }>
                          <label className="mb-2 text-16-regular">
                            Материалы к заданию(?)
                          </label>
                        </OverlayTrigger>
                        <FilesUploader
                          setFiles={setFiles}
                          initialFiles={initialFiles}
                          page="offer"
                          setFilesInProgress={
                            setFilesInProgress
                          }></FilesUploader>
                        <span className="text--disabled mt-2 d-block">
                          Презентация, макеты, референсы, креативы, образцы
                          (все, что бы вы хотели увидеть в рекламе у блогера)
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
              <div className="d-flex column justify-content-center align-items-center">
                <div className="col-auto column d-flex align-items-center mb-3">
                  <Button
                    disabled={
                      !isValid || isSubmitting || filesInProgress.length > 0
                    }
                    className="btn btn_primary w-280">
                    Сохранить
                  </Button>
                </div>
                <div className="col">
                  <MyCheckBox {...getCheckboxProps('saveToDrafts')} />
                  Сохранить рекламное предложение
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateOffer


export const useFormField = (register, trigger, errors) => {
  return (fieldName, related = []) => {
    const handleBlur = () => {
      trigger([fieldName, ...related])
    }
    return {
      onBlur: handleBlur,
      error: errors?.[fieldName]?.message || null,
      name: fieldName,
      register: register,
    }
  }
}

export const useFormCheckbox = (setValue, trigger, watch) => {
  return (fieldName, related = []) => {
    const handleChange = () => {
      trigger([fieldName, ...related])
    }
    return {
      onChange: () => {
        setValue(fieldName, !watch(fieldName))
        handleChange()
      },
      checked: watch(fieldName),
      name: fieldName,
    }
  }
}

export default useFormField

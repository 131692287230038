import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast'
import NewInput from '../components/UI/newInput/newInput.tsx'
import { legalTypeRadio, profileAccTypeRadioExt } from '../utils/radioConsts'
import Button from '../components/UI/button/button'
import RadioButton from '../components/UI/radioButton/radioButton'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormField from '../hooks/useField'
import { legalSchema } from '../utils/schemas'

export const Legal = ({ inData, outData }) => {
  const [loading, setLoading] = useState(false)

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({
    resolver: yupResolver(legalSchema),
  })

  console.log(inData)

  const getFieldProps = useFormField(register, trigger, errors)

  useEffect(() => {
    if (!inData?.legalType) {
      setValue('legalType', 'LEGAL_TYPE_INDIVIDUAL')
    }
    else{
        setValue('legalType', inData?.legalType)
    }
    setValue('inn', inData?.inn)
    setValue('orgnip', inData?.ipData?.orgnip)
    setValue('fullOpf', inData?.oooData?.fullOpf)
    setValue('ogrn', inData?.oooData?.ogrn)
    setValue('payer', inData?.oooData?.payer)
    setValue('legalAddress', inData?.oooData?.legalAddress)
    setValue('physicalAddress', inData?.oooData?.physicalAddress)
    setValue('kpp', inData?.oooData?.kpp)
    setValue('rascSchet', inData?.oooData?.rascSchet)
    setValue('bank', inData?.oooData?.bank)
    setValue('korrSchet', inData?.oooData?.korrSchet)
    setValue('bik', inData?.oooData?.bik)
    setValue('podpisant', inData?.oooData?.podpisant)
    setValue('naOsnovanii', inData?.oooData?.naOsnovanii)

  }, [inData])

  const updateUserLegal = body => {
    return axios.put(
      (process.env.REACT_APP_BACKEND_API || 'http://127.0.0.1:8080/api/') +
        'user/update/legal',
      body,
      { withCredentials: true }
    )
  }

  const onUpdate = body => {
    const outData = {
      inn: body.inn,
      legalType: body.legalType,
      orgnip: body.orgnip,
      oooData: { ...body },
    }
    updateUserLegal(outData)
      .then(resp => {
        console.log(resp)
        toast.success('Успешно изменены юридические данные')
      })
      .catch(err => {
        console.log(err)
        toast.error('Ошибка изменения юридических данных')
      })
    console.log(body)
  }

  const initLegal = response => {
    console.log('initialized legal')
    console.log(response)

  }
  // useEffect(() => {
  //     trigger().then(() => console.log(watch()))
  //
  // }, [watch('legalType')])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + `user/profile`, {
        withCredentials: true,
      })
      .then(response => {
        initLegal(response)
        setLoading(false)
      })
  }, [])
  //
  // useEffect(() => {console.log(avatar)}, [avatar])

  return (
    <div className="">
      <div className="justify-content-end d-flex mb-2">
        <span className="h-line background-gradient col-md-8" />
      </div>
      <div className="justify-content-start d-flex mb-3">
        <span className="h-line background-gradient col-md-8" />
      </div>
      {loading && <div className="spinner-border" role="status" />}
      {!loading && (
        <div className="justify-content-between w-100">
          <div className="column align-items-center d-flex w-100">
            <form onSubmit={handleSubmit(onUpdate)} className="col-md-8">
              <div className="w-100 d-flex align-items-center column mb-5">
                <h4 className="text--gradient text-17-regular mb-1 lh-1 text-uppercase text--page-title">
                  Юридические данные
                </h4>
                <span>Данные для формирования договоров</span>
              </div>

              <div className="card-body d-flex column flex-wrap ">
                <div className={'row'}>
                  {legalTypeRadio.map(option => {
                    return (
                      <RadioButton
                        value={option.value}
                        className={'col-md-4 text-center'}
                        style={{ height: '47px', padding: 0 }}
                        id={option.id}
                        checked={watch().legalType == option.value}
                        onChange={e => {
                          setValue('legalType', e.target.value)
                        }}
                      >
                        {option.label}
                      </RadioButton>
                    )
                  })}
                </div>
                <div className="mb-5">
                  <div className="d-flex justify-content-center">
                    {/*<RadioGroup options={legalTypeRadio} buttonClassName='flex-fill' className='w-100 ' style={{columnGap: '0'}} setter={setLegalType} getter={legalType}/>*/}
                  </div>
                </div>
                {watch().legalType !== 'LEGAL_TYPE_PHYSICAL' && (
                  <div className="">
                    <div className="w-100 mb-3">
                      <NewInput
                        placeholder={'ИНН'}
                        {...getFieldProps('inn')}
                      ></NewInput>
                    </div>
                  </div>
                )}
                {watch().legalType === 'LEGAL_TYPE_LEGAL' && (
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Организационно-правовая форма'}
                            {...getFieldProps('fullOpf')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'ОГРН'}
                            {...getFieldProps('ogrn')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Плательщик'}
                            {...getFieldProps('payer')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'КПП'}
                            {...getFieldProps('kpp')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Юридический адрес'}
                            {...getFieldProps('legalAddress')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Подписант'}
                            {...getFieldProps('podpisant')}
                          />
                        </div>

                        <div className="mb-3">
                          <NewInput
                            placeholder={'Действующий на основании'}
                            {...getFieldProps('naOsnovanii')}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        {' '}
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Физический адрес'}
                            {...getFieldProps('physicalAddress')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Банк'}
                            {...getFieldProps('bank')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'БИК'}
                            {...getFieldProps('bik')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Расчетный счет'}
                            {...getFieldProps('rascSchet')}
                          />
                        </div>
                        <div className="mb-3">
                          <NewInput
                            placeholder={'Корреспондентский счет'}
                            {...getFieldProps('korrSchet')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {watch().legalType === 'LEGAL_TYPE_ENTREPRENEUR' && (
                  <div className="">
                    <div className="mb-3 w-100">
                      <NewInput
                        placeholder={'ОГРНИП'}
                        {...getFieldProps('orgnip')}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center ">
                <Button
                  className={'btn btn_primary px-6 w-240'}
                  disabled={!isValid}
                  type="submit"
                >
                  Сохранить
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

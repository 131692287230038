import { form, control, button } from 'react-validation'
import cls from './newInput.module.scss'

// Define own NewValidatedInput component
const newInput = ({ error, isChanged, isUsed, ...props }) => (
  <div style={{ width: '100%' }}>
    <input {...props} className={`${cls.input} form-control`} />
    {isChanged && isUsed && error}
  </div>
)

// Now call HOCs on components
const Input = control(newInput)

export default Input

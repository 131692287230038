import React, {useState, useRef, useEffect, SetStateAction} from 'react'
import {useNavigate, Link, NavigateFunction, URLSearchParamsInit} from 'react-router-dom'
import Form from 'react-validation/build/form'
import Input from '../components/UI/newInput/input'
import CheckButton from 'react-validation/build/button'
import Button from '../components/UI/button/button'

import AuthService from '../services/auth.service'
import { useSearchParams } from 'react-router-dom'
import axios, {AxiosError} from 'axios'

import '@tabler/core/dist/css/tabler.min.css'
import {
  required,
  validPassword,
  validUsername,
  validEmail,
  validateField,
  existingEmail,
  confirmPassword,
} from '../utils/validation'

import logo from '../assets/Bloggery.svg'

import Bordered from '../components/UI/bordered/bordered'
import { InputError } from '../components/UI/inputAlert/inputError'
import InputSuccess from '../components/UI/inputAlert/inputSuccess'
import AuthCard from '../components/AuthCard/authCard'
import NewPasswordInput from '../components/UI/newInput/newPasswordInput.tsx'
import {HandleForgotRequest, HandleForgotReset} from "../types/functional";

//import '@tabler/icons/iconfont/tabler-icons.min.css';
import('@tabler/core/dist/js/tabler.min.js')

type forgotPasswordMessage = {
    ok: boolean,
    text: string
}


export default function ForgotPassword() {
  let navigate:NavigateFunction = useNavigate()
  const form: React.MutableRefObject<undefined> = useRef()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirm, setConfirm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<forgotPasswordMessage>({ ok: false, text: '' })
  const [passwordChanging, setPasswordChanging] = useState<boolean>(false)
  const [passwordChangeEnabled, setPasswordChangeEnabled] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()


  const setSuccess: (success: string) => void = success => {
    setMessage({ ok: true, text: success })
  }
  const setError: (error: string) => void = error => {
    setMessage({ ok: false, text: error })
  }

  useEffect(() => {
    if (searchParams.has('token')) {
      setPasswordChanging(true)
    }
  }, [])

  useEffect(() => {
    if (passwordChanging) {
      validateField(password, [validPassword]).then(error => {
        if (error) {
          setPasswordChangeEnabled(false)
        } else {
          if (password === confirm) {
            setPasswordChangeEnabled(true)
          } else setPasswordChangeEnabled(false)
        }
      })
    }
  }, [password, confirm])

  const handleForgotRequest:HandleForgotRequest = e => {
    e.preventDefault()
    setMessage({...message, text: ''})
    setLoading(true)
    form.current.validateAll()
    axios
      .post(process.env.REACT_APP_BACKEND_API + 'auth/forgotPasswordRequest', {
        email: email,
      })
      .then(() => {
        setLoading(false)
        setSuccess(
          'Письмо для сброса пароля отправлено на почту. Проверьте ваш почтовый ящик.'
        )
      })
      .catch(error => {
        setLoading(false)
        if (error.response.data.message == 'User not found.')
          setError('Пользователь с такой почтой не найден')
        else if (
          error.response.data.message ==
          'You have requested password reset. Check your email box. Or try again in 10 minutes.'
        )
          setError(
            'Вы уже запрашивали сброс пароля. Проверьте ваш почтовый ящик. Или попробуйте снова через 10 минут.'
          )
        else setError('Ошибка')
      })
  }

  const handleForgotReset:HandleForgotReset = e => {
    e.preventDefault()
    setMessage({...message, text: ''})
    setLoading(true)

    form.current.validateAll()

    axios
      .post(process.env.REACT_APP_BACKEND_API + 'auth/forgotPasswordReset', {
        token: searchParams.get('token'),
        password: password,
      })
      .then(() => {
        setLoading(false)
        setSuccess(
          'Вы успешно поменяли свой пароль. Теперь вы можете войти в свой аккаунт.'
        )
        setPasswordChangeEnabled(false)
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.data.message === 'User Not found.')
          setError('Пользователь с такой почтой не найден')
        else if (
          error.response.data.message ===
          'You have requested password reset. Check your email box. Or try again in 10 minutes.'
        )
          setError(
            'Вы уже запрашивали сброс пароля. Проверьте ваш почтовый ящик. Или попробуйте снова через 10 минут.'
          )
        else setError('Ошибка')
      })
  }

  return (
    <AuthCard>
      {message.text &&
        (message.ok === false ? (
          <InputError children={message.text} />
        ) : (
          <InputSuccess>{message.text}</InputSuccess>
        ))}
      <h2 className="text-center text--auth-title modal_title">
        {searchParams.get('token') ? 'Сброс пароля' : 'Сброс пароля'}
      </h2>
      {!searchParams.get('token') && (
        <Form onSubmit={handleForgotRequest} ref={form}>
          <div className="mb-40">
            <Input
              type="email"
              name="email"
              className="form-control "
              placeholder="Электронная почта"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
                form.current.validate('email')
              }}
              onBlur={() => form.current.validate('email')}
              validations={[required, validEmail]}
            />
          </div>
          <div className="form-footer justify-content-center d-flex">
            <Button
              type="submit"
              className="btn btn_primary w-240"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Сбросить
            </Button>
          </div>
        </Form>
      )}
      {searchParams.get('token') && (
        <Form onSubmit={handleForgotReset} ref={form}>
          <div className="mb-3">
            <NewPasswordInput
              name="password"
              placeholder="Новый пароль"
              autoComplete="off"
              value={password}
              onChange={e => {
                setPassword(e.target.value)
              }}
            />
          </div>
          <div className="">
            <NewPasswordInput
              name="confirm"
              className="mb-40"
              placeholder="Подтвердите пароль"
              autoComplete="off"
              value={confirm}
              onChange={e => {
                setConfirm(e.target.value)
              }}
            />
          </div>

          <div className="d-flex justify-content-center">
            <Button
              type="submit"
              className="btn btn_primary w-240"
              disabled={loading || !passwordChangeEnabled}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Установить пароль
            </Button>
          </div>
        </Form>
      )}
    </AuthCard>
  )
}

import React, { useState } from 'react'
import { parseActivitiesData } from '../../utils/functional'
import { formatHour } from '../../utils/formatters'

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'

const daysOfWeek = {
  0: 'ПН',
  1: 'ВТ',
  2: 'СР',
  3: 'ЧТ',
  4: 'ПТ',
  5: 'СБ',
  6: 'ВС',
}

const formatYAxis = value => {
  return daysOfWeek[value]
}

const renderTooltip = props => {
  const { active, payload } = props

  if (active && payload && payload.length) {
    const data = payload[0] && payload[0].payload
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #8C8C8C',
          margin: 0,
          padding: '0.5rem',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #8C8C8C',
            paddingBottom: '0.2rem',
            marginBottom: '0.2rem',
          }}
        >
          {daysOfWeek[data.day]} | {formatHour(data.hour)}ч
        </div>
        <div>
          <span>Лайки: </span>
          {data.values.likes}
        </div>
        <div>
          <span>Комментарии: </span>
          {data.values.comments}
        </div>
        <div>
          <span>Взаимодействия: </span>
          {data.values.interactions}
        </div>
      </div>
    )
  }

  return null
}

const BubbleMatrixChart = ({ rows }) => {
  const [activity, setActicity] = useState(() => parseActivitiesData(rows))

  return (
    <div className="w-100 overflow-hidden">
      <div className="d-flex justify-content-center">
        <h2 className="card-title text-uppercase f-geometria text-gradient">
          время активности аудитории
        </h2>
      </div>
      <div className="d-flex justify-content-center">
        <div className="mx-auto" style={{ overflowX: 'scroll' }}>
          <ResponsiveContainer width={1250} height={390}>
            <ScatterChart
              width="100%"
              margin={{ top: 0, right: 0, bottom: 0, left: -25 }}
            >
              <CartesianGrid />
              <XAxis
                axisLine={false}
                tickLine={false}
                padding={{ left: 25, right: 25 }}
                dataKey="hour"
                domain={[0, 23]}
                type="number"
                name="hours"
                tickFormatter={formatHour}
                tickCount={24}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                padding={{ bottom: 25, top: 25 }}
                reversed
                dataKey="day"
                domain={[0, 6]}
                tickFormatter={formatYAxis}
                tickCount={7}
                type="number"
                name="days"
              />
              <ZAxis dataKey="averageValue" type="number" range={[20, 600]} />
              <Tooltip cursor={{ stroke: '#515CD1' }} content={renderTooltip} />
              <Scatter data={activity} fill="#515CD1" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default BubbleMatrixChart

import React, { useState, useRef, useEffect } from 'react'
import { CityInput } from '../UI/newInput/cityInput'

import {
  getValidatedClassnames,
  isCyrillic,
  isExistingCity,
  isOneWord,
  isTwoWords,
  required,
  validateField,
  validEmail,
  validNumberFormat,
} from '../../utils/validation'
import { InputError } from '../UI/inputAlert/inputError'
import MyPhoneInputDub from '../UI/newInput/MyPhoneInputDub.tsx'
import NewInput from '../UI/newInput/newInput.tsx'
import Button from '../UI/button/button'
import AccountTypeRadio from '../UI/radioButton/radioButton'
import RadioGroup from '../UI/radioGroup/radioGroup'
import { profileAccTypeRadio } from '../../utils/radioConsts'
import RadioButton from '../UI/radioButton/radioButton'
import { boolean, mixed, object, ref, string } from 'yup'
import {FieldValues, set, useForm, UseFormReturn} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormField from '../../hooks/useField'
import {YupShape} from "../../types/auth/yup";
import {RegistrationStepInfoType} from "../../types/auth/auth";
import {FormFieldProps} from "../../types/hooks";

const RegistrationStepInfo = ({
  secondTry,
  setSecondTry,
  setInfoData,
  onBack,
  onSubmit,
  data,
}) => {
  const [completed, setCompleted] = useState<boolean>(false)

  const stepInfoSchema = object<YupShape<RegistrationStepInfoType>>().shape({
    firstName: string()
      .required('Обязательное поле')
      .test('firstName', 'Некорректное имя', isCyrillic),
    middleName: string().test('middleName', 'Некорректное отчество', v => {
      if (v) {
        return isCyrillic(v)
      } else {
        return true
      }
    }),
    lastName: string()
      .required('Обязательное поле')
      .test('lastName', 'Некорректная фамилия', isCyrillic),
    city: string()
      .required('Обязательное поле')
      .test('city', 'Некорректный город', isExistingCity),
    phone: mixed()
      .required('Обязательное поле')
      .test('phone', 'Некорректный номер', (phone: {value: string, format: string}) => {
        return validNumberFormat(phone.value, phone.format)
      }),
    accType: string()
      .required('Обязательное поле')
      .test('accType', 'Выберите тип аккаунта', accType => {
        console.log(accType)
        return accType !== ''
      }),
  })

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
    trigger,
  }:UseFormReturn<RegistrationStepInfoType> = useForm<RegistrationStepInfoType>({
    defaultValues: {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      city: data.city,
      phone: { value: data?.phone?.value || '', format: data?.phone?.format },
      accType: data.accType,
    },
    resolver: yupResolver(stepInfoSchema),
  })

  const getFieldProps: (name: string) => FormFieldProps = useFormField(register, trigger, errors)

  const handleFinish = data => {
    console.log(data)
    setInfoData(data)
    setCompleted(true)
  }

  useEffect(() => {
    if (completed) {
      onSubmit()
    }
  }, [completed])

  const handleBack = () => {
    setInfoData(watch())
    setSecondTry(true)
    onBack()
  }

  return (
    <form onSubmit={handleSubmit(handleFinish)}>
      <div className="mb-3">
        <NewInput
          type="text"
          // className={getValidatedClassnames(firstName.error, firstName.changed)}
          placeholder="Имя"
          {...getFieldProps('firstName')}
        />
      </div>
      <div className="mb-3">
        <NewInput
          type="text"
          // className={getValidatedClassnames(lastName.error, lastName.changed)}

          placeholder="Фамилия"
          {...getFieldProps('lastName')}
        />
      </div>

      <div className="mb-3">
        <NewInput
          type="text"
          // className={getValidatedClassnames(middleName.error, middleName.changed)}
          placeholder="Отчество"
          {...getFieldProps('middleName')}
        />
      </div>
      <div className="mb-3">
        <CityInput
          type="text"
          placeholder="Город"
          {...getFieldProps('city')}
        ></CityInput>
      </div>

      <div className="mb-3">
        <MyPhoneInputDub
          value={watch('phone').value}
          onChange={(number, numberData) => {
            setValue('phone', { value: number, format: numberData.format })
            trigger('phone')
          }}
          {...getFieldProps('phone')}
        />
      </div>
      <h3 className="m-0 text-14-regular m-0 mb-1">Тип аккаунта</h3>
      {/*<RadioGroup between name='acc-type' getter={accType} setter={setAccType} options={profileAccTypeRadio}></RadioGroup>*/}
      <div className={'justify-content-between d-flex flex-wrap mb-40'}>
        {profileAccTypeRadio.map(option => (
          <RadioButton
            className="text--disabled radio-130"
            value={option.value}
            checked={watch('accType') == option.value}
            {...getFieldProps('accType')}
            id={option.id}
            children={option.label}
          />
        ))}
      </div>
      <div className="form-footer d-flex align-items-center column">
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          className="btn btn_primary w-240"
        >
          Зарегистрироваться
        </Button>
        <Button
          onClick={handleBack}
          type="reset"
          className="mt-3 btn btn_primary w-240"
        >
          Назад
        </Button>
      </div>
    </form>
  )
}

export default RegistrationStepInfo

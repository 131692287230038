import AuthService from '../services/auth.service'

const payWidget = offer => {
  const cp = window.cp

  const user = AuthService.getCurrentUser()

  const widget = new cp.CloudPayments({
    language: 'ru-RU',
    email: user.email,
    applePaySupport: true,
    googlePaySupport: false,
    yandexPaySupport: true,
    tinkoffInstallmentSupport: false,
  })

  const receipt = {
    Items: [
      //товарные позиции
      {
        label: 'Организация рекламного размещения', //наименование товара
        price: offer.price, //цена
        quantity: 1.0, //количество
        amount: offer.price, //сумма
        vat: null, //ставка НДС - не облагается
        method: 1, // тег-1214 признак способа расчета - признак способа расчета
        object: 4, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
        //"measurementUnit": "шт" //единица измерения
      },
    ],
    calculationPlace: 'bloggery.io', //место осуществления расчёта, по умолчанию берется значение из кассы
    taxationSystem: 2, //система налогообложения; необязательный, если у вас одна система налогообложения
    email: user.email, //e-mail покупателя, если нужно отправить письмо с чеком
    //"phone": "", //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
    //"customerInfo": "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
    // "customerInn": user.legal?.inn, // тег-1228 ИНН покупателя
    //"isBso": false, //чек является бланком строгой отчётности
    //"AgentSign": null, //признак агента, тег ОФД 1057
    amounts: {
      electronic: offer.price, // Сумма оплаты электронными деньгами
      advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
      credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
      provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
    },
  }

  widget.pay(
    'charge', // или 'charge'
    {
      //options
      publicId:
        process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_KEY ||
        'pk_fb790d5ecd0e27e818cd032952b1b', //id из личного кабинета
      description: `Рекламное предложение ${offer.offerData.title}`, //назначение
      amount: offer.price, //сумма
      currency: 'RUB', //валюта
      accountId: user.id, //идентификатор плательщика (необязательно)
      invoiceId: offer._id, //номер заказа  (необязательно)
      skin: 'mini', //дизайн виджета (необязательно)

      data: {
        CloudPayments: {
          CustomerReceipt: receipt,
        },
      },
    },
    {
      onSuccess: function (options) {
        // success
        window.location.reload()
        console.log('success', options)
      },
      onFail: function (reason, options) {
        // fail
        console.log('error', reason, options)
      },
      onComplete: function (paymentResult, options) {
        //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
        console.log('complete', paymentResult, options)
      },
    }
  )
}

export default payWidget

// @ts-ignore
import React, { useState, useRef, useEffect } from 'react'
import { boolean, object, ref, string } from 'yup'

import {
  checkExistingEmail,
  confirmPassword,
  existingEmail,
  existingUsername,
  required,
  requiredCheckBox,
  validateField,
  validEmail,
  validPassword,
  validUsername,
} from '../../utils/validation'

//@ts-ignore
import policy from '../../assets/privacy_policy.pdf'
import NewInput from '../UI/newInput/newInput.tsx'
import MyCheckBox from '../UI/myCheckBox/myCheckBox'
import Button from '../UI/button/button'
import NewPasswordInput from '../UI/newInput/newPasswordInput.tsx'
import {useForm, UseFormReturn, UseFormStateReturn} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useField } from 'formik'
import useFormField from '../../hooks/useField'
import { setKeyValue } from '../../utils/formData'
import {RegistrationStepMainType} from "../../types/auth/auth";
import {YupShape} from "../../types/auth/yup";
import {FormCheckboxProps, FormFieldProps} from "../../types/hooks";

const RegistrationStepMain = ({ setMainData, onNext, data }) => {
  const stepMainSchema = object<YupShape<RegistrationStepMainType>>().shape({
    email: string()
      .required('Обязательное поле')
      .test('existingEmail', 'Email уже используется', async function (value) {
        try {
          await checkExistingEmail(value) // Ваша функция для проверки существующего email
          return true // Email не существует
        } catch (error) {
          return false // Email уже существует
        }
      }),
    password: string()
      .required('Обязательное поле')
      .min(6, 'Минимум 6 символов'),
    confirm: string()
      .required('Обязательное поле')
      .oneOf([ref('password'), null], 'Пароли должны быть одинаковыми')
      .required('Подтверждение пароля обязательно'),
    isChecked: boolean()
      .required('Обязательное поле')
      .oneOf([true], 'Обязательное поле'),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors,  isValid },
    trigger,
  }: UseFormReturn<RegistrationStepMainType> = useForm<RegistrationStepMainType>({
    defaultValues: {
      email: data?.email || '',
      password: data?.password || '',
      isChecked: data?.isChecked || false,
      confirm: ''
    },
    resolver: yupResolver(stepMainSchema),
  })

  const getFieldProps: (name: string, related?: string[]) => FormFieldProps = useFormField(register, trigger, errors)

  const nextStep = ({ email, password, isChecked }: {email: string, password: string, isChecked: boolean}): void => {
    setMainData({ email, password, isChecked })
    onNext()
  }



  console.log(watch())
  console.log(isValid)

  return (
    <form onSubmit={handleSubmit(nextStep)}>
      <div className="mb-3">
        <NewInput
          type="email"
          placeholder="Электронная почта"
          {...getFieldProps('email')}
        />
      </div>
      <div className="mb-3">
        <div className="row g-2">
          <div className="col">
            <NewPasswordInput
              placeholder="Пароль"
              autoComplete="off"
              autoFill="off"
              {...getFieldProps('password', ['confirm'])}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <NewPasswordInput
          placeholder="Подтвердите пароль"
          autoComplete="off"
          {...getFieldProps('confirm')}
        />
      </div>
      <div className="mb-40">
        <label className="w-100">
          <MyCheckBox
            onClick={() => {
              setValue('isChecked', !watch('isChecked'))
              trigger('isChecked')
            }}
            checked={watch('isChecked')}
          />
          <span className="form-check-label">
            Согласен с{' '}
            <a href={policy} tabIndex={-1} target="_blank" rel="noreferrer">
              политикой конфиденциальности
            </a>
            .
          </span>
        </label>
      </div>
      <div className="form-footer d-flex justify-content-center">
        <Button
          disabled={!isValid}
          type="submit"
          className="btn btn_primary w-240"
        >
          Далее
        </Button>
      </div>
    </form>
  )
}

export default RegistrationStepMain

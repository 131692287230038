import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'
import { useEffect, useState } from 'react'
import DashboardBlogger from '../../components/dashboardBlogger'
import DashboardCompany from '../../components/dashboardCompany'

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import AuthService from '../../services/auth.service'
import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import PlusIcon from '../../components/UI/plusIcon/plusIcon'
import PlusButtonGroup from '../../components/UI/plusButtonGroup/plusButtonGroup'
import Button from '../../components/UI/button/button'
import ButtonSwitch from '../../components/UI/buttonSwith/buttonSwitch'

import '../../components/UI/dashboardCards/dashboard.scss'

import questionIcon from '../../assets/icons/question.svg'
import plusIcon from '../../assets/icons/plus.svg'
import pencilIcon from '../../assets/icons/pencil_icon.svg'
import searchIcon from '../../assets/icons/search.svg'
import { toast } from 'react-hot-toast'
import authService from "../../services/auth.service";

export default function Dashboard() {
  const [isBlogger, setIsBlogger] = useState(true)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(( ) => {
    setIsBlogger(authService.getCurrentUser()?.accType === "1")
  }, [])

  useEffect(() => {
    console.log(location)
    const url = new URL(window.location.href)
    const error = url.searchParams.get('error')
    if (error) {
      toast.error(error)
      navigate('/')
    }
  }, [location])

  const [hasDataBlogger, setHasDataBlogger] = useState(false)
  const [hasDataAdvertiser, setHasDataAdvertiser] = useState(false)

  return (
    <div className="page">
      <Navbar home></Navbar>
      <div className="page-wrapper dashboard">
        <div className="page-body" style={{ marginTop: '1.875rem' }}>
          <div className="container-xl">
            <div
              style={{ marginBottom: '2.5rem' }}
              className={`dashboard-btns d-flex flex-wrap row-gap-3 justify-content-${
                (isBlogger && hasDataBlogger) ||
                (!isBlogger && hasDataAdvertiser)
                  ? 'between'
                  : 'center'
              }`}
            >
              <div className="dashboard-switch d-flex align-items-center">
                <span
                  style={{ fontSize: '12px' }}
                  className="me-3 f-geometria text-uppercase"
                >
                  рекламодателям
                </span>
                <ButtonSwitch value={isBlogger} setValue={setIsBlogger} />
                <span
                  style={{ fontSize: '12px' }}
                  className="ms-3 f-geometria text-uppercase"
                >
                  блогерам
                </span>
              </div>

              {isBlogger
                ? hasDataBlogger && (
                    <div
                      key={'dshbrd_blggr_btns'}
                      className="dashboard-btns__side-btns d-flex justify-content-between"
                    >
                      <Button
                        onClick={() => navigate('/blogger/create')}
                        style={{ minWidth: '280px' }}
                        className="btn btn_secondary text-14-bold"
                      >
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={plusIcon}
                          alt=""
                        />
                        <span className="mx-auto">Добавить аккаунт</span>
                      </Button>
                      <Button
                        onClick={() => navigate('/offersauctions')}
                        style={{ minWidth: '280px' }}
                        className="btn btn_secondary text-14-bold"
                      >
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={searchIcon}
                          alt=""
                        />
                        <span className="mx-auto">Найти рекламодателя</span>
                      </Button>
                    </div>
                  )
                : hasDataAdvertiser && (
                    <div
                      key={'dshbrd_advrtsr_btns'}
                      className="dashboard-btns__side-btns d-flex justify-content-between"
                    >
                      <Button
                        onClick={() => navigate('/offersauction/create')}
                        style={{ minWidth: '280px' }}
                        className="btn btn_secondary text-14-bold"
                      >
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={pencilIcon}
                          alt=""
                        />
                        <span className="mx-auto">разместить предложение</span>
                      </Button>
                      <Button
                        onClick={() => navigate('/bloggers')}
                        style={{ minWidth: '280px' }}
                        className="btn btn_secondary text-14-bold"
                      >
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={searchIcon}
                          alt=""
                        />
                        <span className="mx-auto">найти блогера</span>
                      </Button>
                    </div>
                  )}
            </div>

            {isBlogger ? (
              <DashboardBlogger setHasData={setHasDataBlogger} />
            ) : (
              <DashboardCompany setHasData={setHasDataAdvertiser} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

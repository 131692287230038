import React, { useState, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import axios from 'axios'

import { Link, useNavigate } from 'react-router-dom'
import { IconCurrencyRubel, IconFileAnalytics } from '@tabler/icons-react'

import AdCalendarItem from './adCalendarItem'
import {
  getDeals,
  getDrafts,
  getOffersAuctions,
} from '../services/offers.service'
import { MyTable } from './UI/myTable/myTable'

import OfferInfo from './UI/offerInfo/offerInfo'
import IconAnalytics from './UI/icons/iconAnalytics/iconAnalytics'
import IconTurnOver from './UI/icons/iconTurnOver/iconTurnOver'
import authService from '../services/auth.service'
import DashboardAdvertiserCard from './UI/dashboardCards/dashboardAdvertiserCard'

import advertiserImg from '../assets/title_advertiser.webp'
import Button from './UI/button/button'

import pencilIcon from '../assets/icons/pencil_icon.svg'
import searchIcon from '../assets/icons/search.svg'
import ReadyAdModal from './modals/readyAdModal'

const DashboardCompany = ({ setHasData }) => {
  const navigate = useNavigate()

  const [deals, setDeals] = useState([])
  const [drafts, setDrafts] = useState([])
  const [auctions, setAuctions] = useState([])

  const [isEmpty, setIsEmpty] = useState(true)

  const [stats, setStats] = useState({})

  const [showModal, setShowModal] = useState(false)
  const onHideModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    getDeals().then(data => setDeals(data)) // верхняя левая
    getOffersAuctions().then(data => {
      setAuctions(data.filter(e => e.user == authService.getCurrentUser().id))
    }) // нижняя левая

    getDrafts().then(data => setDrafts(data)) // правая

    axios
      .get(process.env.REACT_APP_BACKEND_API + `dashboard/stats`, {
        withCredentials: true,
      })
      .then(response => {
        setStats(response.data)
      })
  }, [])

  useEffect(() => {
    if (deals.length > 0 || auctions.length > 0) {
      setHasData(true)
      setIsEmpty(false)
    }
  }, [deals, auctions])

  return (
    <div>
      {!isEmpty ? (
        <>
          {' '}
          <div className="d-flex align-items-center column w-auto">
            <div className="h-line background-gradient h-1 w-100"></div>
            <span className="text-uppercase mt-3 text-14-bold">
              хочу заказать рекламу «под ключ»
            </span>
            <Button
              style={{ minWidth: '280px' }}
              onClick={() => setShowModal(true)}
              className="mt-3 mb-4 w-auto btn btn_primary text-14-bold">
              заказать рекламу
            </Button>
          </div>
          <div className="h-line mb-6 background-gradient h-1 w-100"></div>
          <div className="col-lg-8 col-md-12 mt-2 mx-auto">
            <div className="d-flex justify-content-center">
              <h2 className="text-uppercase">Ваши предложения</h2>
            </div>
            <div className="row row-cards">
              {auctions.length > 0 &&
                auctions.map(auction => (
                  <DashboardAdvertiserCard
                    key={auction._id}
                    offerLink={'/offersauction/'}
                    _id={auction._id}
                    blogger={auction.blogger}
                    responses={auction.responses}
                    offerData={auction.offerData}
                    price={auction.price}
                    acceptDeadline={auction.acceptDeadline}
                    status={auction.status}
                  />
                ))}
              {deals.length > 0 &&
                deals.map(deal => (
                  <DashboardAdvertiserCard
                    key={deal._id}
                    offerLink={'/offer/'}
                    _id={deal._id}
                    blogger={deal.blogger}
                    offerData={deal.offerData}
                    price={deal.price}
                    acceptDeadline={deal.acceptDeadline}
                    status={deal.status}
                  />
                ))}
              {drafts.length > 0 &&
                drafts.map(draft => (
                  <DashboardAdvertiserCard
                    key={draft._id}
                    offerLink={'/draft/'}
                    _id={draft._id}
                    offerData={draft}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className="col-md-8 mt-2 mx-auto column align-items-center text-center">
          <span className="text-gradient text-uppercase f-geometria">
            Добро пожаловать на платформу
          </span>
          <span className="h1-title text-uppercase mt-1">Bloggery</span>
          <div className="text-uppercase mt-6 text-14-regular">
            Получи новых клиентов прямо сейчас
          </div>
          <div style={{ maxWidth: '270px', marginTop: '1.25em' }}>
            <img src={advertiserImg} alt="" />
          </div>
          <Button
            style={{ minWidth: '280px', marginTop: '2.5rem' }}
            onClick={() => navigate('/offersauction/create')}
            className="btn btn_secondary ">
            <img style={{ height: '13px' }} src={pencilIcon} alt="" />
            <span className="mx-auto text-14-bold">разместить предложение</span>
          </Button>

          <Button
            style={{ minWidth: '280px' }}
            onClick={() => navigate('/bloggers')}
            className="mt-3 btn btn_secondary ">
            <img
              style={{ height: '13px' }}
              className="ms-1"
              src={searchIcon}
              alt=""
            />
            <span className="text-14-bold mx-auto">найти блогера</span>
          </Button>

          <span
            style={{ marginTop: '2.5rem' }}
            className="text-uppercase text-14-bold">
            хочу заказать рекламу «под ключ»
          </span>
          <Button
            style={{ minWidth: '280px' }}
            onClick={() => setShowModal(true)}
            className="mt-4 btn btn_primary text-14-bold">
            заказать рекламу
          </Button>
        </div>
      )}
      <ReadyAdModal show={showModal} onHide={onHideModal} />
    </div>
  )
}

export default DashboardCompany

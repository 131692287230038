import React from 'react'
import Bordered from '../bordered/bordered'
///import offerCondition.module.scss///

const offerCondition = ({ keyWord, value }) => {
  return (
    <div
      style={{ padding: '0.75rem' }}
      className="p-sm-3 mb-3 bordered d-flex justify-content-between text-bold"
    >
      <span className="text--disabled">{keyWord}</span>
      <span className="text--purple fw-bold text-end text-bolder">{value}</span>
    </div>
  )
}

export default offerCondition

import React from 'react'

const ApplyEdit = ({ show, onHide, onApply }) => {
  return (
    <div
      className={'modal modal-blur fade' + (show ? 'show' : '')}
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Изменения</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Сохранить изменения?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn me-auto"
              data-bs-dismiss="modal"
              onClick={onHide}
            >
              Не сохранять
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={onApply}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyEdit

import React from 'react'

const QualityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
    >
      <rect width="3.4" height="11" rx="1.7" fill="white" />
      <rect x="4.53125" width="3.4" height="11" rx="1.7" fill="white" />
      <rect x="9.07031" width="3.4" height="11" rx="1.7" fill="white" />
      <rect
        x="13.8016"
        y="0.2"
        width="3"
        height="10.6"
        rx="1.5"
        stroke="white"
        stroke-width="0.4"
      />
    </svg>
  )
}

export default QualityIcon

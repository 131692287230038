import React, { useEffect, useState } from 'react'
import Form from 'react-validation/build/form'
import Select, { ControlledSelect } from '../../components/UI/newInput/select'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import Bordered from '../../components/UI/bordered/bordered'
import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'

import DropzoneComponent from 'react-dropzone-component'
import Button from '../../components/UI/button/button'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { platformRadio } from '../../utils/radioConsts'
import {
  createOfferAuction,
  getDrafts,
  getOfferAuctionById,
  getOffersAuctions,
  updateOfferAuction,
} from '../../services/offers.service'
import { Link, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Navbar from '../../components/UI/navBar/navbar'
import FilesUploader from '../../components/UI/FilesUploader/filesUploader'
import DateInput from '../../components/UI/newInput/dateInput'
import TextArea from '../../components/UI/newInput/textArea'
import cls from '../../scss/components.scss'
import {
  formatDate,
  formatDateForInput,
  formatToUTC,
} from '../../utils/formatters'
import PriceInput from '../../components/UI/newInput/priceInput'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { auctionSchema } from '../../utils/schemas'
import useField, { useFormCheckbox } from '../../hooks/useField'
import { delayedSetValue } from '../../utils/misc'
import { GenTaskModal } from '../../components/modals/genTaskModal'

import { ConfirmModal } from '../../components/modals/confirmModal'
import { BackArrowButton } from '../../components/modals/backArrowButton'
import { OfferData, OfferDeal, OfferDealCreateData } from '../../types/abstract'
///import NewOfferCreate.module.scss///

const UpdateAuction = () => {
  const [genTaskVisible, setGenTaskVisible] = useState<boolean>(false)
  const id: string = useParams().id
  const [initialFiles, setInitialFiles] = useState<string[]>([])
  const [filesInProgress, setFilesInProgress] = useState<string[]>([])

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm<FieldValues, OfferDealCreateData>({
    defaultValues: { locationNotImportant: true },
    resolver: yupResolver(auctionSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)

  const [files, setFiles] = useState([])

  let navigate = useNavigate()

  const update = data => {
    if (filesInProgress.length > 0)
      return toast.error('Дождитесь загрузки всех файлов')

    data.acceptDeadline = formatToUTC(data.acceptDeadline)
    data.finishDeadline = data.acceptDeadline
    data.files = files
    if (data.locationNotImportant) {
      data.geo = ''
    }

    console.log('upd', data)

    updateOfferAuction(id, data)
      .then(() => {
        navigate(`/offersauction/${id}`)
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    getOfferAuctionById(id).then((res: OfferDeal) => {
      let offerData: OfferData = res.offerData
      for (let key in offerData) {
        setValue(key, offerData[key], { shouldValidate: true })
      }
      setValue('acceptDeadline', formatDateForInput(res.acceptDeadline), {
        shouldValidate: true,
      })
      setValue('finishDeadline', formatDateForInput(res.acceptDeadline), {
        shouldValidate: true,
      })
      setValue('minFollowers', res.minFollowers, { shouldValidate: true })
      setValue('price', res.price, { shouldValidate: true })
      setValue('locationNotImportant', res.offerData.geo === '')
      setInitialFiles(res.offerData.files)
      setFiles(res.offerData.files)
    })
  }, [])

  console.log(watch())

  return (
    <div className="page">
      <GenTaskModal
        show={genTaskVisible}
        onHide={() => setGenTaskVisible(false)}
        setOutput={value => {
          delayedSetValue(value, value => {
            setValue('description', value, { shouldValidate: false })
          })
        }}></GenTaskModal>
      <Navbar />
      <div className="page-wrapper container-xl">
        <div className="d-flex justify-content-center">
          <div className="position-relative row mt-5 mb-40 row-gap-5 col-md-8 col-9">
            <Link
              style={{ left: 0 }}
              className="form__back-arrow"
              to={`/offersauction/` + id}>
              <BackArrowButton></BackArrowButton>
            </Link>
            <h2 className="m-0 form__title text--page-title">
              Отредактировать предложение
            </h2>
          </div>
        </div>
        <div className="page-body mt-0 d-flex justify-content-center">
          <div className="col-md-8 col-9">
            <form onSubmit={handleSubmit(update)} className="">
              <div>
                <div className="row">
                  <div className="mb-3">
                    <div className="mb-5">
                      <h3 className="h3">Платформа</h3>

                      <RadioGroup
                        big
                        between
                        getFieldProps={getFieldProps}
                        currentValue={watch('platform')}
                        name="platform"
                        options={platformRadio}
                      />
                    </div>
                    <div className="mb-3">
                      <NewInput
                        type="text"
                        className="form-control"
                        placeholder="Заголовок предложения"
                        {...getFieldProps('title')}
                      />
                    </div>

                    <div className="mb-5 d-flex row row-gap-3 flex-wrap">
                      <div className="col-md-8 h-100">
                        <TextArea
                          style={{ minHeight: '300px', minWidth: '200px' }}
                          rows="6"
                          className="h-100"
                          placeholder={`Задание\n(информация о продукте, какую задачу ставите перед
блогерами, техническое задание, описание креатива)`}
                          {...getFieldProps('description')}
                        />
                      </div>
                      <div className="col-md-4 h-100">
                        <ul className="mb-3 list-unstyled text--purple">
                          <li>— информация о продукте,</li>
                          <li>— какую задачу ставите перед блогерами,</li>
                          <li>— техническое задание,</li>
                          <li>— описание креатив</li>
                          <li>— интересы, возраст и пол ЦА</li>
                        </ul>
                        <Button
                          type={'button'}
                          onClick={e => {
                            e.preventDefault()
                            setGenTaskVisible(true)
                          }}
                          className="text-wrap btn btn_secondary">
                          сгенерировать нейросетью
                        </Button>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="row ">
                        <div className="full-width-961 col-md-6 mb-3">
                          <DateInput
                            containerClass={'flex-md-fill '}
                            className="form-control"
                            label="Дата размещения"
                            value={watch('acceptDeadline')}
                            error={errors.acceptDeadline?.message}
                            onChange={e => {
                              setValue('acceptDeadline', e.target.value)
                              trigger('acceptDeadline')
                            }}
                          />
                        </div>
                        <div className="full-width-961 col-md-6 mb-3">
                          <PriceInput
                            type="number"
                            className="form-control"
                            placeholder="Бюджет на рекламу"
                            {...getFieldProps('price')}
                          />
                        </div>
                      </div>
                      <div className="row row-wrap-961">
                        <div className="full-width-961 col-md-6 mb-3">
                          <NewInput
                            type="number"
                            className="form-control"
                            placeholder="Минимальное кол-во подписчиков"
                            {...getFieldProps('minFollowers')}
                          />
                        </div>
                        <div className=" full-width-961 col-md-6 mb-3">
                          <NewInput
                            className="form-control m"
                            placeholder={
                              !watch('locationNotImportant')
                                ? 'География будущих клиентов'
                                : 'Неважно'
                            }
                            disabled={watch('locationNotImportant')}
                            {...getFieldProps('geo')}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 justify-content-center justify-content-md-start full-width-961 align-items-center d-flex">
                          <MyCheckBox
                            {...getCheckboxProps('locationNotImportant')}
                            onClick={() => {
                              setValue('geo', '')
                            }}></MyCheckBox>
                          <span className="d-block h-auto">
                            География не важна
                          </span>
                        </div>
                      </div>

                      <div className="mb-5">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Ссылка, которую должен разместить блогер"
                          {...getFieldProps('url')}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Фото-, видео- материалы, документы, с которыми
                            необходимо ознакомиться для правильного выполнения
                            работы'
                          </Tooltip>
                        }>
                        <label className="mb-2 text-16-regular">
                          Материалы к заданию(?)
                        </label>
                      </OverlayTrigger>
                      <FilesUploader
                        setFiles={setFiles}
                        page="offer"
                        initialFiles={initialFiles}
                        setFilesInProgress={setFilesInProgress}></FilesUploader>
                      <span className="text--disabled mt-2 d-block">
                        Презентация, макеты, референсы, креативы, образцы (все,
                        что бы вы хотели увидеть в рекламе у блогера)
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="card-footer">
                <div className="d-flex column justify-content-center align-items-center">
                  <div className="col-auto align-items-center column d-flex mb-3">
                    <Button
                      disabled={!isValid || filesInProgress.length > 0}
                      className="btn btn_primary w-280 "
                      type="submit">
                      Сохранить
                    </Button>
                  </div>

                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Шаблоны можно повторно использовать для создания похожих
                        или аналогичных рекламных предложений
                      </Tooltip>
                    }>
                    <div className="col d-flex justify-content-center align-items-center">
                      <MyCheckBox {...getCheckboxProps('saveToDrafts')} />
                      <span>
                        Сохранить рекламное предложение как шаблон (?)
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateAuction

import { required, validateField, validINN } from '../../utils/validation'
import React, { useEffect, useState } from 'react'
import { updateINN } from '../../services/user.service'
import { toast, Toaster } from 'react-hot-toast'
import cls from './modals.module.scss'
import { BackArrowButton } from './backArrowButton'
import NewInput from '../UI/newInput/newInput.tsx'
import { Button } from 'react-bootstrap'
import isURL from 'validator/es/lib/isURL'
import axios from 'axios'
import {
  getDataArr,
  setKeyError,
  setKeyValue,
  validateFormObj,
} from '../../utils/formData'
import InputGroup from '../UI/newInput/InputGroup.tsx'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { auctionSchema } from '../../utils/schemas'
import useField from '../../hooks/useField'
import { string, object } from 'yup'

export const CreateShortLink = ({ onApply, show, onHide }) => {
  const shortLinkSchema = object().shape({
    url: string()
      .required()
      .test('validUrl', 'Некорректная ссылка', value => {
        return isURL(value)
      }),
    slug: string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    defaultValues: {
      slug: (Math.random() + 1).toString(36).substring(7),
    },
    resolver: yupResolver(shortLinkSchema),
  })

  const getFieldProps = useField(register, trigger, errors)

  const createLink = ({ url, slug }) => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_API + `links/create`,
        { slug, url },
        { withCredentials: true }
      )
      .then(response => {
        onApply()
        onHide()
      })
      .catch(error => {
        if(error.response.data.message === 'Link with this slug already exists.'){
          toast.error('Ссылка с таким именем уже существует')
        }
        else{
          toast.error(error.response.data.message)
        }



      })
  }

  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className=" modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content bordered bordered--thick bg-white pe-auto ">
          <form onSubmit={handleSubmit(createLink)} className=" ">
            <h3
              className={
                'w-100 modal_title text-uppercase text--page-title text-center ' +
                cls.title
              }
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onHide()
                }}
              >
                <BackArrowButton />
              </button>
              Создать короткую ссылку
            </h3>
            <InputGroup
              label={"https://uul.ink/"}
              containerClass={'mb-3'}
              placeholder={'Ссылка'}
              {...getFieldProps('slug')}
            />
            <div className="mb-3">
              <NewInput
                placeholder={'Адрес назначения'}
                {...getFieldProps('url')}
              />
            </div>

            <div className={'d-flex justify-content-center'}>
              <Button
                type="submit"
                disabled={!isValid}
                className={'px-6 btn btn_primary '}
              >
                Сохранить
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

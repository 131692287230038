import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import AuthService from '../services/auth.service'

import RegistrationStepMain from '../components/registration/registrationStepMain.tsx'
import RegistrationStepInfo from '../components/registration/registrationStepInfo.tsx'

import '@tabler/core/dist/css/tabler.min.css'
import { translateServerMsg } from '../utils/functional'

import AuthCard from '../components/AuthCard/authCard'
import {
  RegistrationStepInfoType,
  RegistrationStepMainType,
} from '../types/auth/auth'
import { HandleRegister } from '../types/functional'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import('@tabler/core/dist/js/tabler.min.js')

export default function Register() {
  let navigate = useNavigate()

  const [mainData, setMainData] = useState<RegistrationStepMainType>({
    email: '',
    password: '',
    confirm: 'string',
    isChecked: false,
  })
  const [infoData, setInfoData] = useState<RegistrationStepInfoType>({
    firstName: '',
    accType: undefined,
    phone: { value: '', format: '' },
    lastName: '',
    city: '',
    middleName: '',
  })
  const [secondTry, setSecondTry] = useState<boolean>(false)
  const [successful, setSuccessful] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const handleRegister: HandleRegister = () => {
    setMessage('')
    setSuccessful(false)
    if (localStorage.getItem('utm')) {
      mainData.utm = JSON.parse(localStorage.getItem('utm'))
    }

    AuthService.register({
      ...mainData,
      ...infoData,
      phone: infoData.phone.value,
    }).then(
      response => {
        setMessage(translateServerMsg(response.data.message))
        setSuccessful(true)
        console.log()

        AuthService.login(mainData.email, mainData.password).then(
          () => {
            console.log('LOGGED IN', infoData)
            // Тут мы вызваем обновление юзера и дополняем всю его инфу
            navigate('/')
            window.location.reload()
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()

            setMessage(translateServerMsg(resMessage))
          }
        )
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        setMessage(translateServerMsg(resMessage))
        setSuccessful(false)
      }
    )
  }

  const [registrationStep, setRegistrationStep] = useState<number>(0)
  const regStepComponent = [
    <RegistrationStepMain
      setMainData={setMainData}
      onNext={() => setRegistrationStep(registrationStep + 1)}
      data={mainData}
    />,
    <RegistrationStepInfo
      setSecondTry={setSecondTry}
      secondTry={secondTry}
      setInfoData={setInfoData}
      onBack={() => setRegistrationStep(registrationStep - 1)}
      onSubmit={handleRegister}
      data={infoData}
    />,
  ]

  return (
    <AuthCard
      bottomContent={
        <div className="text-center text-muted mt-3">
          у вас уже есть аккаунт?{' '}
          <Link className={'text--purple fw-bold'} to="/login" tabIndex="-1">
            Войти!
          </Link>
        </div>
      }>
      <h2 className="modal_title text-center   text--auth-title ">
        Создать новый аккаунт
      </h2>
      {message && (
        <div className="form-group">
          <div
            className={
              successful ? 'alert alert-success' : 'alert alert-danger'
            }
            role="alert">
            {message}
          </div>
        </div>
      )}

      {regStepComponent[registrationStep]}
    </AuthCard>
    /*
                        <div className="hr-text">or</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><a href="#" className="btn btn-white w-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon text-github" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5"></path></svg>
                                    Login with Github
                                </a></div>
                                <div className="col"><a href="#" className="btn btn-white w-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon text-twitter" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"></path></svg>
                                    Login with Twitter
                                </a></div>
                            </div>
                        </div>
                            */
  )
}

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import Select from '../../components/UI/newInput/select'
import { NotFoundMessage } from '../../components/notFoundMessage'

import 'rc-slider/assets/index.css'
import {
  accTypeRadio,
  ageRadio,
  platformRadio,
  genderRadio,
} from '../../utils/radioConsts'

import { useRef, useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

import { themes } from '../../utils/consts'

import InputDoubleRange from '../../components/UI/newInput/inputDoubleRange'
import { CityInput } from '../../components/UI/newInput/cityInput'
import NewInput from '../../components/UI/newInput/newInput.tsx'

import Button from '../../components/UI/button/button'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import PlusButtonGroup from '../../components/UI/plusButtonGroup/plusButtonGroup'

import BloggerCard from '../../components/UI/smallBloggerCard/bloggerCard'

import pencilIcon from '../../assets/icons/pencil_icon.svg'

import plusIcon from '../../assets/icons/plus.svg'
import { useQuery } from '../../hooks/useQuery'
import FiltersInput from '../../components/UI/newInput/filtersInput'
import SortSelect from '../../components/UI/sortSelect/sortSelect'

export default function Bloggers() {
  const navigate = useNavigate()
  const formRef = useRef()
  const filterTimeout = useRef(null)
  const [name, setName] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [theme, setTheme] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [gender, setGender] = useState('')
  const [location, setLocation] = useState('')
  const [sort, setSort] = useState({
    value: 'followers',
    label: 'По подписчикам',
  })
  //const [filter, setFilter] = useState("all");
  // const [filters, setFilters] = useState({});
  const sortOptions = [
    { value: 'createdAt', label: 'По новизне' },
    { value: 'followers', label: 'По подписчикам' },

    { value: 'pricing.post', label: 'По цене' },
  ]
  const [bloggers, setBloggers] = useState([])
  const [age, setAge] = useState('')

  const [storyPrice, setStoryPrice] = useState()
  const [postPrice, setPostPrice] = useState()
  const [layoutPrice, setLayoutPrice] = useState()

  // object {from: 111, to: 22}
  const [followersRange, setFollowersRange] = useState([0, 10000000])
  const [coverageRange, setCoverageRange] = useState([0, 100])

  const fetchBloggers = async (offset = 0) => {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_API +
          `bloggers?filters=${JSON.stringify(filters)}&offset=${offset || 0}`,
        { withCredentials: true }
      )
      .then(response => {
        if (offset == 0) setBloggers(response.data)
        else setBloggers(bloggers.concat(response.data))
      })
  }

  // useQuery(filters, setFilters, [platforms, theme, age, followersRange, coverageRange, storyPrice, layoutPrice, postPrice, location, name])

  // //debug log-----------
  // useEffect(() => {
  //     console.log('bloggers: ', bloggers);
  //
  //
  // }, [bloggers])

  //-------------------

  useEffect(() => {
    clearTimeout(filterTimeout.current)
    filterTimeout.current = setTimeout(() => {
      fetchBloggers()
    }, 500)
  }, [filters])

  const applyFilter = () => {
    const enormousNumber = Number(10 ** 10)
    const appliedSort = sort.value

    let _filters = {
      //acctype: e.target.acctype?.value || "any",
      gender: gender || 'any',
      platform: platforms || 'any',
      themes: theme.length > 0 ? { $in: theme.map(e => e.value) } : 'any',
      age: age || 'any',
      followers: {
        $gte: followersRange[0] || 0,
        $lte: followersRange[1] || 0,
      },
      // storiesCoverage: {
      //     $gte: coverageRange[0] / 100 || 0,
      //     $lte: coverageRange[1] / 100 || 0
      // },
      $and: [
        {
          $or: [
            { username: { $regex: `${name}`, $options: 'i' } },
            { fullname: { $regex: `${name}`, $options: 'i' } },
          ],
        },
      ],

      $or: [
        { 'pricing.story': { $lte: storyPrice || enormousNumber } },
        { 'pricing.post': { $lte: postPrice || enormousNumber } },
        { 'pricing.advLayout': { $lte: layoutPrice || enormousNumber } },
      ],

      location: location,
      //coverage: e.target.coverage?.value || 0,
      $sort: { [appliedSort]: -1 },
    }

    console.log(_filters)
    setFilters(_filters)

    //axios.get(process.env.REACT_APP_BACKEND_API + `bloggers?filters=${JSON.stringify(filters)}`, { withCredentials: true }).then(response => {
    //    setBloggers(response.data);
    //})
  }

  const resetFilter = (e, onSearchByName = false) => {
    setName('')
    setStoryPrice('')
    setPostPrice('')
    setLayoutPrice('')
    setFollowersRange([0, 1000000])
    setCoverageRange([0, 100])
    setAge('')
    setGender('')
    setTheme([])
    setLocation('')
    setPlatforms([])

    setFilters({})
    console.log(filters)
  }

  const sf = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API +
          `bloggers?filters=${JSON.stringify(filters)}`,
        { withCredentials: true }
      )
      .then(response => {
        setBloggers(response.data)
        //console.log(bloggers)
      })
  }

  useEffect(() => {
    applyFilter()
  }, [
    platforms,
    theme,
    age,
    followersRange,
    coverageRange,
    storyPrice,
    layoutPrice,
    postPrice,
    location,
    name,
    gender,
    sort,
  ])

  return (
    <div className="page bloggers">
      <Navbar bloggers></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Блогеры">
          <div className="btn-list">
            <Link
              to="/blogger/create"
              style={{ minWidth: '280px' }}
              className="btn btn_secondary text-14-bold">
              <img
                style={{ height: '13px' }}
                className="ms-1"
                src={plusIcon}
                alt=""
              />
              <span className="mx-auto">регистрация аккаунта</span>
            </Link>
          </div>
        </PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="col-md-12 d-flex column-gap-3  justify-content-end align-items-center mb-3">
              <span className="text--disabled text-12-regular">
                Сортировать список:
              </span>
              <SortSelect
                onChange={option => {
                  setSort(option)
                }}
                defaultValue={sort}
                options={sortOptions}></SortSelect>
            </div>
            <div className="row g-sm-0 g-md-5">
              <div className="col-md-4 mb-sm-0 mb-5 border-none border-r_gradient">
                <div className="mb-3">
                  <FiltersInput
                    placeholder="Поиск по имени"
                    onButtonClick={() => {
                      setShowFilters(!showFilters)
                    }}
                    value={name}
                    autoComplete="off"
                    onChange={e => {
                      setName(e.target.value)
                    }}></FiltersInput>
                </div>

                <form
                  className={
                    'd-md-block slide-down ' +
                    (showFilters ? ' d-block' : ' d-none ')
                  }
                  ref={formRef}
                  action="#"
                  method="get"
                  autoComplete="off"
                  noValidate=""
                  onSubmit={applyFilter}
                  onReset={resetFilter}>
                  <div className="mb-3">
                    <details>
                      <summary>
                        <div className="form-label">Платформа</div>
                        <span className="arrow" />
                      </summary>
                      <RadioGroup
                        options={platformRadio}
                        name={'platform'}
                        setter={setPlatforms}
                        getter={platforms}></RadioGroup>
                    </details>
                  </div>
                  {/* 
                                    <div className='mb-3'>
                                        <div className="form-label">Тип аккаунта</div>
                                        <RadioGroup options={accTypeRadio} name={'acctype'} setter={setAccTypes} getter={accTypes}></RadioGroup>
                                    </div> */}
                  <div className="mb-3">
                    <Select
                      isClearable
                      placeholder="Тематика"
                      options={themes}
                      isMulti
                      value={theme}
                      onChange={choice => {
                        setTheme(choice)
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          neutral0: '#1a2234',
                          neutral20: '#243049',
                          neutral10: '#243049',
                          neutral80: '#fff',
                          primary25: '#206bc4',
                          primary: '#206bc4',
                        },
                      })}
                      styles={{
                        menu: (provided, state) => ({
                          ...provided,
                          backgroundColor: '#242d42',
                          border: '1px solid white',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: 'white',
                        }),
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <details>
                      <summary>
                        <div className="form-label">Количество подписчиков</div>
                        <span className="arrow" />
                      </summary>
                      <InputDoubleRange
                        initialRange={followersRange}
                        forwardRange={setFollowersRange}
                      />
                    </details>
                  </div>

                  {/*<div className='mb-3'>*/}
                  {/*    <details>*/}
                  {/*        <summary>*/}
                  {/*            <div className="form-label">Охваты</div>*/}
                  {/*            <span className='arrow' />*/}
                  {/*        </summary>*/}
                  {/*        <InputDoubleRange min={0} max={100} step={1} initialRange={coverageRange} forwardRange={setCoverageRange} />*/}
                  {/*    </details>*/}
                  {/*</div>*/}

                  <div className="mb-3">
                    <CityInput
                      type="text"
                      onChange={e => {
                        setLocation(e.target.value)
                      }}
                      value={location}
                      name="location"
                      placeholder="Локация"
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-3">
                    <details>
                      <summary>
                        <div className="mt-2 form-label">Возраст</div>
                        <span className="arrow" />
                      </summary>
                      <div className="d-flex column-gap-3 flex-wrap row-gap-3">
                        <RadioGroup
                          options={ageRadio}
                          name={'age'}
                          setter={setAge}
                          getter={age}
                          between></RadioGroup>
                      </div>
                    </details>
                  </div>
                  <div className="mb-3">
                    <details>
                      <summary>
                        <div className="form-label">Основная аудитория</div>
                        <span className="arrow" />
                      </summary>
                      <div className="mb-3 d-flex column-gap-1 flex-nowrap row-gap-1">
                        <RadioGroup
                          options={genderRadio}
                          name="gender"
                          getter={gender}
                          setter={setGender}
                          between></RadioGroup>
                      </div>
                    </details>
                  </div>
                  <div className="mb-3">
                    <div className="form-label purple">Максимальная цена</div>
                    <div className="mb-3">
                      <NewInput
                        type="number"
                        className="form-control mb-3"
                        name="priceFrom"
                        placeholder="История"
                        value={storyPrice}
                        onChange={e => setStoryPrice(e.target.value)}
                      />
                      <NewInput
                        type="number"
                        className="form-control mb-3"
                        placeholder="Публикация"
                        value={postPrice}
                        onChange={e => setPostPrice(e.target.value)}
                      />
                      <NewInput
                        type="number"
                        className="form-control mb-3"
                        placeholder="Рекламный макет"
                        value={layoutPrice}
                        onChange={e => setLayoutPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{ position: 'sticky', bottom: '30px' }}
                    className="mt-4 d-flex flex-wrap justify-content-center">
                    <Button
                      type="reset"
                      className="mb-4 btn btn_secondary"
                      style={{ width: '160px', background: '#ffffff' }}>
                      Сбросить
                    </Button>
                  </div>
                </form>
              </div>

              <div className="col-md-8">
                {bloggers.length > 0 ? (
                  <div className="row row-gap-2">
                    {bloggers.map((card, i) => (
                      <div className=" col-6 col-md-12">
                        {' '}
                        <BloggerCard key={card._id} i={i} card={card} />
                      </div>
                    ))}
                    <div className="d-flex mt-5">
                      <Button
                        style={{ margin: '0 auto' }}
                        className="btn btn_primary w-240"
                        onClick={() => fetchBloggers(bloggers.length)}>
                        Загрузить еще
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="h-100 column align-items-center justify-content-center">
                    <span className="text-16-bold text-gradient text-uppercase">
                      упс, блогеры не найдены!
                    </span>
                    <span className="mt-2 text-16-regular">
                      Напишите нам и мы бесплатно подберем вам блогера
                    </span>
                    <div className="column mt-7" style={{ width: '280px' }}>
                      <Button
                        style={{ marginBottom: '1.25rem' }}
                        className="btn btn_secondary border_purple text-14-bold"
                        onClick={() => console.log('nav to support')}>
                        помогите мне
                      </Button>
                      <span
                        style={{ height: '1px', background: '#8C8C8C' }}></span>
                      <Button
                        style={{ marginTop: '1.25rem' }}
                        onClick={() => navigate('/offersauction/create')}
                        className="btn btn_secondary text-14-bold">
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={pencilIcon}
                          alt=""
                        />
                        <span className="mx-auto">разместить предложение</span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {/* TODO: Сделать пагинацию или бесконечный скролл */}
            </div>

            {/*<div className="card">
                            <div className="table-responsive">
                                <Grid
                                    data={data}
                                    columns={['Username', 'Платформа', 'Тематика', 'Цена', 'Действия']}
                                    className={{
                                        td: 'my-td-class',
                                        table: 'table table-vcenter card-table ',
                                        footer: 'card-footer d-flex align-items-center datatable',
                                        pagination: 'pagination pagination-sm m-0 ms-auto',
                                        paginationSummary: 'text-muted text-sm me-auto',
                                        paginationButton: 'page-item page-link',
                                        paginationButtonCurrent: 'page-item page-link active',
                                    }}
                                    pagination={{
                                        enabled: true,
                                        limit: 10,
                                        summary: false,
                                    }}
                                />
                            </div>
                                </div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

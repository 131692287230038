import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import { Grid } from 'gridjs'
import { _ } from 'gridjs-react'
import { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AuthService from '../../services/auth.service'
import axios from 'axios'
import moment from 'moment'

export default function AdminUser() {
  let { id } = useParams()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + `admin/users/${id}`, {
        withCredentials: true,
      })
      .then(res => {
        setUserData(res.data)
      })
  }, [id])

  return (
    <div className="page">
      <Navbar></Navbar>
      <div className="page-wrapper">
        <PageHeader title="User"></PageHeader>
        <div className="page-body">
          <div className="container-xl" style={{ whiteSpace: 'pre-wrap' }}>
            {userData && (
              <>
                <div className="card">
                  <div className="card-body">
                    <h3>Осн. данные</h3>
                    {JSON.stringify(userData.user, null, 2)}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3>Блогеры</h3>
                    {JSON.stringify(userData.bloggers, null, 2)}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3>Логи</h3>
                    {userData.actions.map(act => (
                      <>
                        {moment(act.date).format('DD.MM.YYYY hh:mm')} -{' '}
                        <b>{act.action}</b> -{' '}
                        {JSON.stringify(act.data, null, 1)}
                      </>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import cls from './modals.module.scss'
import { BackArrowButton } from './backArrowButton'

import PriceInput from '../UI/newInput/priceInput'
import DateInput from '../UI/newInput/dateInput'
import Button from '../UI/button/button'
import InputError from '../UI/inputAlert/inputError'
import React from 'react'

export const ConfirmModal = ({
  acceptText = 'Удалить',
  cancelText = 'Отменить',
  confirmText = 'Вы точно хотите удалить интернет?',
  show,
  onApply,
}) => {
  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className="modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content d-flex column align-items-center bordered bordered--thick bg-white pe-auto ">
          <h3
            className={
              'w-100 text-uppercase text--page-title mb-3 ' + cls.title
            }
          >
            Подтверждение
          </h3>
          <p className="text--black w-auto mb-5">{confirmText}</p>
          <div className={'d-flex justify-content-center column-gap-4'}>
            <Button
              style={{ minWidth: '150px' }}
              onClick={() => onApply(false)}
              className="btn btn_secondary"
            >
              {cancelText}
            </Button>
            <Button
              onClick={() => {
                onApply(true)
              }}
              style={{ minWidth: '150px' }}
              className={'btn btn_primary min'}
            >
              {acceptText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'
import {
  profileAccTypeRadioExt,
} from '../utils/radioConsts'

import Navbar from '../components/UI/navBar/navbar'
import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast'
import MyPhoneInputDub from '../components/UI/newInput/MyPhoneInputDub.tsx'
import { CityInput } from '../components/UI/newInput/cityInput'
import authService from '../services/auth.service'
import userService from '../services/user.service'
import NewInput from '../components/UI/newInput/newInput.tsx'
import Button from '../components/UI/button/button'
import MyCheckBox from '../components/UI/myCheckBox/myCheckBox'
import { Legal } from './legal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormField, { useFormCheckbox } from '../hooks/useField'
import RadioButton from '../components/UI/radioButton/radioButton'
import { resendEmail } from '../services/user.service'
import { userSchema } from '../utils/schemas'

interface UserProfile{
  initialEmail: string
  email: string
  firstName: string
  lastName: string
  middleName: string
  city: string
  phone: string
  accType: string
  notifications: {
    marketing: boolean
    service: boolean
    news: boolean
  }
}

export default function User() {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({ resolver: yupResolver(userSchema), mode: 'onChange', reValidateMode: 'onChange'})

  const getFieldProps = useFormField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)
  const [loading, setLoading] = useState(true)
  const [legal, setLegal] = useState({})


  const initUser = (response: {data: UserProfile}) => {
    setValue('initialEmail', response.data.email)
    setValue('news', response.data.notifications.news, {shouldValidate: true})
    setValue('service', response.data.notifications.service,{shouldValidate: true})
    setValue('marketing', response.data.notifications.marketing,{shouldValidate: true})
    setValue('accType', response.data.accType,{shouldValidate: true})
    setValue('email', response.data.email,{shouldValidate: true})
    setValue('firstName', response.data.firstName,{shouldValidate: true})
    setValue('lastName', response.data.lastName,{shouldValidate: true})
    setValue('middleName', response.data.middleName,{shouldValidate: true})
    setValue('city', response.data.city,{shouldValidate: true})
    authService.changeUsername(response.data.firstName)

    setValue('phone', {
      value: response.data.phone,
      format: '.'.repeat(response.data.phone.length),
    })
    authService.setAccType(response.data.accType)

  }

  const updateUser = data => {
    console.log('hello WORLD')
    axios
      .put(
        process.env.REACT_APP_BACKEND_API + `user/update`,
        {
          ...data,
          notifications: {
            marketing: data.marketing,
            service: data.service,
            news: data.news,
          },
          phone: data.phone.value,
        },
        { withCredentials: true }
      )
      .then(response => {
        toast.success('Профиль успешно обновлен')
        authService.setAccType(data.accType)
      })
  }



  useEffect(() => {
    userService.getUserProfile()
      .then(response => {
        initUser(response)
        setLoading(false)
        setLegal(response.data.legal)
      })
  }, [])
  //
  // useEffect(() => {console.log(avatar)}, [avatar])

  return (
    <div className="page">
      <Navbar home></Navbar>
      <div className="page-wrapper">
        <div className="d-flex justify-content-center">
          <h2 className="text--page-title mb-40 mh-40">Личный кабинет</h2>
        </div>
        <div className="page-body mt-0">
          <div className="container-xl">
            {loading && <div className="spinner-border" role="status" />}
            {!loading && (
              <form onSubmit={handleSubmit(updateUser)} className=" w-100">
                <div className="col">
                  <div className="w-100">
                    <div className="card-body">
                      <div className="d-flex row">
                        <div className="w-100 d-flex column align-items-center">
                          <div className="card-header col-md-6 justify-content-start mb-3">
                            <h4
                              style={{ fontSize: '14px' }}
                              className=" flex-wrap f-geometria text--gradient text-uppercase"
                            >
                              Настройка профиля
                            </h4>
                          </div>

                          <div className="col-md-6  row-gap-5 justify-content-between">
                            {/*{user && <UserAvatar initialImage={user?.avatar?.value} setImage={setAvatar} >*/}

                            {/*</UserAvatar>}*/}
                            <div className="d-flex column ">
                              <div className="mb-3">
                                <NewInput
                                  type="email"
                                  placeholder="Электронная почта"
                                  {...getFieldProps('email')}
                                />
                                {(watch('emailInfo') &&
                                  watch('emailInfo').verified === false) ||
                                (watch('emailInfo') &&
                                  watch('emailInfo').verifyToken != '') ? (
                                  <div className="d-flex column align-items-center mt-2">
                                    {' '}
                                    <span className="text--purple">
                                      На вашу почту отправлено письмо для
                                      подтверждения email
                                    </span>
                                    <span className="text--disabled d-flex row">
                                      Не получили письмо?
                                      <button
                                        onClick={() => {
                                          resendEmail()
                                            .then(response => {
                                              toast.success('Письмо отправлено')
                                            })
                                            .catch(error => {
                                              toast.error(
                                                'Ошибка отправки письма'
                                              )
                                            })
                                        }}
                                        type="button"
                                        style={{ borderBottom: '1px solid' }}
                                        className="w-auto bg-transparent border-0 d-flex column"
                                      >
                                        <span className=" text--gradient ">
                                          Отправить снова
                                        </span>
                                        <span className="h-line w-100 background-gradient " />
                                      </button>
                                    </span>
                                  </div>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <NewInput
                                  type="text"
                                  placeholder="Фамилия"
                                  {...getFieldProps('lastName')}
                                />
                              </div>
                              <div className="mb-3">
                                <NewInput
                                  type="text"
                                  placeholder="Имя"
                                  {...getFieldProps('firstName')}
                                />
                              </div>
                              <div className="mb-3">
                                <NewInput
                                  type="text"
                                  placeholder="Отчество"
                                  {...getFieldProps('middleName')}
                                />
                              </div>
                              <div className={'d-flex row row-gap-3 mb-4'}>
                                <div className="col-md-6">
                                  <CityInput
                                    type="text"
                                    placeholder="Город"
                                    {...getFieldProps('city')}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <MyPhoneInputDub
                                    value={watch('phone').value}
                                    onChange={(number, numberData) => {
                                      setValue('phone', {
                                        value: number,
                                        format: numberData.format,
                                      })
                                      trigger('phone')
                                    }}
                                    {...getFieldProps('phone')}
                                  />
                                </div>
                              </div>
                            </div>
                            {/*<RadioGroup className='col-md-4 h-100 row-gap-3' name='accType' buttonStyle={{marginTop: '-20px', height: '89px'}} setter={setAccType} getter={user.accType.value} between options={profileAccTypeRadioExt}></RadioGroup>*/}
                            <div className="d-flex row row-wrap-961 mb-5">
                              {profileAccTypeRadioExt.map(option => (
                                <RadioButton
                                  id={option.id}
                                  className={`col-md-4 full-width-961 justify-content-start`}
                                  style={{
                                    height: '100%',
                                    padding: '16px',
                                    paddingTop: '12px',
                                  }}
                                  checked={option.value == watch('accType')}
                                  value={option.value}
                                  {...getFieldProps('accType')}
                                  children={option.label}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex column align-items-center">
                        <div className="col-md-6 column d-flex align-items-start  mb-40 row-gap-3 text-14-regular">
                          <label className="">
                            <MyCheckBox
                              {...getCheckboxProps('news')}
                              type="checkbox"
                            />
                            <div className="d-flex column align-items-start">
                              <span className="form-check-label">
                                Новости платформы{' '}
                                <span className={'text--disabled'}>
                                  (обновления функций, статистика)
                                </span>
                              </span>
                            </div>
                          </label>
                          <label className="">
                            <MyCheckBox {...getCheckboxProps('service')} />
                            <div className="d-flex column align-items-start">
                              <span className="form-check-label">
                                Сервисные уведомления{' '}
                                <span className={'text--disabled'}>
                                  (сообщения от заказчиков)
                                </span>
                              </span>
                            </div>
                          </label>
                          <label className="col-md-6 ">
                            <MyCheckBox
                              {...getCheckboxProps('marketing')}
                              type="checkbox"
                            />
                            <div className="d-flex column align-items-start">
                              <span className="form-check-label">
                                Рекламная рассылка
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer d-flex mb-6 justify-content-center">
                  <div className="">
                    <Button
                      type="submit"
                      disabled={!isValid}
                      className="btn btn_primary w-240"
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </form>
            )}
            <Legal inData={legal}></Legal>
          </div>
        </div>
      </div>
    </div>
  )
}

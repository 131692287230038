import React from 'react'
import { Link } from 'react-router-dom'

import { statuses, translateSocials } from '../../../utils/translate'
import StatusIcon from '../icons/statusIcon/statusIcon'
import { fromatDateString } from '../../../utils/functional'
import { socialIcons } from '../../../utils/consts'

const DashboardAdvertiserCard = ({
  className,
  _id,
  blogger,
  offerData,
  price,
  acceptDeadline,
  status,
  responses,
  offerLink,
}) => {
  return (
    <div
      className={'card dashboard-card dashboard-card_advertiser ' + className}>
      <div className="card-body d-flex p-0 justify-content-center">
        <div className="w-100 row row-deck flex-nowrap justify-content-between align-items-center">
          <div className="first-block p-0 h-100 column align-items-start justify-content-between">
            <img
              className="social-icon"
              src={socialIcons[offerData.platform]}
              alt=""
            />
            <div className="w-100">
              <h4
                title={offerData.title}
                className="mb-0 w-100 text-truncate text-14-regular">
                <Link className="" to={offerLink + _id}>
                  {offerData.title}
                </Link>
              </h4>
              <div
                title={blogger?.username}
                style={{ cursor: 'default' }}
                className="w-100 blogger-username mt-1 text-truncate text-14-bold">
                {blogger && (
                  <Link className="purple" to={'/blogger/' + blogger._id}>
                    {'@' + blogger.username}
                  </Link>
                )}
                {!blogger && responses && 'Откликов: ' + responses.length}
              </div>
            </div>

            <div className="text-muted">
              {translateSocials(offerData.platform)}
            </div>
          </div>

          <div className="middle-block h-100">
            <p>{offerData.description}</p>
          </div>

          <div className="last-block column fw-bold h-100 align-items-center justify-content-between">
            <div className="f-geometria">
              {price &&
                price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + '₽'}
            </div>

            <div className="column align-items-center">
              <div className="text-lowercase">
                {status ? (
                  <>
                    <StatusIcon status={status} />
                    {statuses[status] ? statuses[status] : status}
                  </>
                ) : (
                  <>
                    <span
                      class="badge mx-2"
                      style={{ backgroundColor: 'gray' }}
                    />
                    шаблон
                  </>
                )}
              </div>

              <div className="mt-2 mb-1 text-muted">
                {status == 'pending' && 'Aктивен до '}
                {acceptDeadline &&
                  fromatDateString(acceptDeadline).slice(0, -5)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardAdvertiserCard

import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../button/button'
import { addSpacesToNumber, fromatDateString } from '../../../utils/functional'
import { translateSocials } from '../../../utils/translate'

const OfferAuctionCard = ({ i, card }) => {
  const navigate = useNavigate()

  return (
    <div className="col-md-6 col-lg-4">
      <div
        className={`card ${i % 2 ? 'bg-purple-pale' : 'bg-light'}`}
        style={{ borderRadius: '0', border: '1px solid var(--main-purple)' }}
      >
        <div className="card-body d-flex flex-column align-items-center p-3 text-center">
          <div className="pb-2 w-100 d-flex flex-column">
            <a
              title={card.title}
              className="pb-2 purple f-geometria text-14-bold text-uppercase text-truncate"
              href={`/offersauction/${card._id}`}
            >
              {card.title}
            </a>
            <div className="mt-auto text-start truncate-3-lines">
              {card.text.length > 100
                ? card.text.substring(0, 100) + '...'
                : card.text}
            </div>
          </div>
          <div className="mt-auto w-100">
            <div className="py-2 border-b_purple border-t_purple">
              <div className="d-sm-block d-flex align-items-start justify-content-between">
                <div className="geo mb-2 text-sm-center text-start">
                  {card.offerData.geo}
                </div>
                <div className="platform mb-2">
                  {translateSocials(card.offerData.platform)}
                </div>
              </div>
              <div className="d-sm-block d-flex align-items-center justify-content-between">
                <div>
                  <span className="text-muted">подписчиков от:</span>
                  <span className="purple d-flex align-items-center justify-content-center text-14-bold">
                    <svg
                      className="me-1"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2634 1.09084C9.56172 0.387386 8.5817 0 7.5 0C6.41253 0 5.42926 0.385042 4.73085 1.08415C4.02486 1.79095 3.68087 2.75155 3.76164 3.78882C3.92173 5.83523 5.59873 7.49996 7.5 7.49996C9.40127 7.49996 11.0754 5.83557 11.238 3.78949C11.3198 2.76159 10.9737 1.803 10.2634 1.09084ZM13.846 14.9999H1.15402C0.987895 15.0019 0.823376 14.9695 0.672434 14.905C0.521493 14.8406 0.387925 14.7457 0.281448 14.6273C0.0470799 14.3671 -0.0473886 14.0119 0.0225614 13.6526C0.32688 12.085 1.27661 10.7681 2.76936 9.84369C4.09553 9.02305 5.77541 8.57138 7.5 8.57138C9.22459 8.57138 10.9045 9.02339 12.2306 9.84369C13.7234 10.7678 14.6731 12.0846 14.9774 13.6523C15.0474 14.0115 14.9529 14.3668 14.7186 14.6269C14.6121 14.7454 14.4786 14.8404 14.3276 14.9049C14.1767 14.9694 14.0121 15.0019 13.846 14.9999Z"
                        fill="#515CD1"
                      />
                    </svg>
                    {addSpacesToNumber(card.minFollowers) || 'не указано'}
                  </span>
                </div>
                <div className="mt-1">
                  {fromatDateString(card.acceptDeadline) + 'г'}
                </div>
              </div>
            </div>

            <div className="w-100 pt-2">
              <div className="fw-bold f-geometria text-16-bold">
                {addSpacesToNumber(card.price) + '₽' || 'цена не указана'}
              </div>
              <Button
                onClick={() => navigate(`/offersauction/${card._id}`)}
                className="btn btn_primary mt-2 py-0"
                style={{ width: '60%', height: '42px' }}
              >
                Принять
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferAuctionCard

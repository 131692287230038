import axios from 'axios'
import authHeader from './auth-header'

// const API_URL = "http://127.0.0.1:8080/api/test/";
const API_URL = process.env.REACT_APP_BACKEND_API

const getPublicContent = () => {
  return axios.get(API_URL + 'all')
}

const getUserBoard = () => {
  return axios.get(API_URL + 'user', { headers: authHeader() })
}

const getModeratorBoard = () => {
  return axios.get(API_URL + 'mod', { headers: authHeader() })
}

const getAdminBoard = () => {
  return axios.get(API_URL + 'admin', { headers: authHeader() })
}

export const updateINN = body => {
  return axios.put(API_URL + 'user/update/inn', body, { withCredentials: true })
}

export const resendEmail = () => {
  return axios
    .get(API_URL + 'user/resendEmail', { withCredentials: true })
    .then(response => {
      return response.data
    })
}

const getUserProfile = async () => {
  return await axios.get(API_URL + `user/profile`,
    { withCredentials: true })
}

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUserProfile
}

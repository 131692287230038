import { Component } from 'react'
import { toast } from 'react-hot-toast'
import { Navigate } from 'react-router-dom'

class ErrorBoundary extends Component {
  state = {
    error: null,
  }
  static getDerivedStateFromError(error) {
    return { error }
  }
  render() {
    const { error } = this.state

    if (error) {
      return (
        <Navigate to={`/?error=Произошла какая то ошибка!`} replace={true} />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary

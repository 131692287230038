import React from 'react'
import cls from './newInput.module.scss'
import InputError from '../inputAlert/inputError'
import {InputGroupProps} from "../../../types/components";



const inputGroup:React.FC = ({
  label = 'hello',
  labelBefore = true,
  register = (_) => {},
  name,
  containerClass,
    error,
  ...props
}:InputGroupProps) => {
  return (
    <>
      <div className={cls['input-group'] + ' input-group mb-3'}>
        {labelBefore && (
          <span class="input-group-text input-group_label" id="basic-addon1">
            {label}
          </span>
        )}
        <input
          {...props}
          type="text"
          className={' form-control'}
          id="basic-url"
          aria-describedby="basic-addon3"
          {...register(name)}
        />
        {!labelBefore && (
          <span class="input-group-text input-group_label" id="basic-addon1">
            {label}
          </span>
        )}
      </div>
      {error && <InputError>{error}</InputError>}
    </>
  )
}

export default inputGroup

import React from 'react'
///import textArea.module.scss///
import cls from './newInput.module.scss'
import InputError from '../inputAlert/inputError'

const TextArea = ({ register = (_) => {}, error, name, ...props }) => {
  return (
    <>
      <textarea
        {...props}
        style={props.style}
        className={cls['textarea'] + ' ' + props.className + ' form-control'}
        placeholder={props.placeholder}
        {...register(name)}
      />
      {error && <InputError>{error}</InputError>}
    </>
  )
}

export default TextArea

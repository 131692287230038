import './Button.scss'

// TODO: disabled, spinner, icon

const Button = ({ type, disabled, className, children, ...props }) => (
  <button {...props} type={type} disabled={disabled} className={className}>
    {children}
  </button>
)

export default Button

import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'

import FilesUploader from '../UI/FilesUploader/filesUploader'

import Button from '../UI/button/button'
import cls from './modals.module.scss'
import { BackArrowButton } from './backArrowButton'

const FinishOfferUploadStatistics = ({ show, onHide, onApply }) => {
  const [message, setMessage] = useState('')
  const [linkToResult, setLinkToResult] = useState('')
  const [files, setFiles] = useState([])
  const [filesInProgress, setFilesInProgress] = useState([])

  const handleApply = () => {
    if (filesInProgress.length > 0) {
      toast.error('Загрузка файлов не завершена')
      return
    }
    if (files.length == 0 || files?.[0] == null)
      return toast.error('Скриншоты обязательны')
    onApply({ message, linkToResult, files })
  }

  return (
    <div
      className={
        'modal modal-blur fade ' + cls.modal + ' ' + (show ? 'show' : '')
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="bordered bordered--thick modal-content">
          <div className="">
            <h5 className={'modal_title text--page-title ' + cls.title}>
              <button onClick={onHide}>
                <BackArrowButton />
              </button>
              Добавление статистики размещения
            </h5>
            <div className="mb-5">
              <label className="text--purple mb-3">Скриншоты:</label>
              <FilesUploader
                setFiles={setFiles}
                page="statistics"
                setFilesInProgress={setFilesInProgress}
                acceptedFiles="image/jpeg,image/png,image/gif"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn w-240 btn_primary"
              data-bs-dismiss="modal"
              onClick={handleApply}
            >
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinishOfferUploadStatistics

import { isEmail, isMobilePhone } from 'validator'
import axios from 'axios'
import cityList from '../assets/city-list.json'
import React, { useState, useRef, useEffect } from 'react'
import { InputError } from '../components/UI/inputAlert/inputError'
import { json } from 'react-router-dom'
import { getWalletBalance } from '../services/wallet.service'
import authService from '../services/auth.service'

export const notNegative = value => {
  if (value < 0) {
    return <InputError>Не может быть отрицательным</InputError>
  }
}

export const required = value => {
  if (value) {
    return true
  }
}

export const requiredIf = (value, condition) => {
  if (condition && !value) {
    return <InputError>Обязательное поле</InputError>
  }
}

export const notEmptyArray = value => {
  if (value.length === 0) {
    return <InputError>Обязательное поле</InputError>
  }
}

export const validINN = inn => {
  var result = false
  var errorMessage = ''

  if (typeof inn === 'number') {
    inn = inn.toString()
  } else if (typeof inn !== 'string') {
    inn = ''
  }

  if (!inn.length) {
    return false
  } else if (/[^0-9]/.test(inn)) {
    return false
  } else if ([10, 12].indexOf(inn.length) === -1) {
    return false
  } else {
    var checkDigit = function (inn, coefficients) {
      var n = 0
      for (var i in coefficients) {
        n += coefficients[i] * inn[i]
      }
      return parseInt((n % 11) % 10)
    }

    switch (inn.length) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (n10 === parseInt(inn[9])) {
          result = true
        }
        break
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = true
        }
        break
    }

    if (!result) {
      return false
    }
  }

  return errorMessage === ''
}

export const validPhysicalINN = value => {
  if (value.length !== 12) {
    return <InputError>ИНН должен быть 12 цифр</InputError>
  }
  const innDigits = value.split('').map(Number)
  const innWeights = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
  let sum = 0

  for (let i = 0; i < innWeights.length; i++) {
    sum += innDigits[i] * innWeights[i]
  }

  const remainder = sum % 11
  const controlDigit = remainder === 10 ? 0 : remainder
  console.log(controlDigit, innDigits[innDigits.length - 1])
  return controlDigit !== innDigits[innDigits.length - 1] ? (
    <InputError>Невалидный ИНН физического лица</InputError>
  ) : null
}

export const validDeadline = value => {
  if (authService.getRole() > 6) {
    return true
  }
  console.log(authService.getRole())
  let newDate = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000)
  return new Date(value) >= newDate
}
//function that gets value from input type date and returns inputError component if date is less three days from now

export const isSocialLink = value => {
  const social = new RegExp(
    '(tiktok.com\\/(.*))|(youtube.com\\/(.*))|(instagram.com\\/(.*))|(vk.com\\/(.*))|(twitter.com\\/(.*))|(t.me\\/(.*))'
  )
  console.log(social.test(value))
  if (!social.test(value)) {
    return <InputError>Неверный формат ссылки</InputError>
  }
}

export const isUrl = value => {
  const protocolPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  const nonProtocolPattern =
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
  return protocolPattern.test(value) || nonProtocolPattern.test(value)
}

export const isValidUrl = value => {
  //validate value and if it is a link return true, else false
}

export const validUsername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <InputError>Имя пользователя должно быть от 3 до 20 символов.</InputError>
    )
  }
}

export const validPassword = value => {
  if (value.length < 6 || value.length > 40) {
    return <InputError>Пароль должен быть от 6 до 40 символов.</InputError>
  }
}

export const notZero = value => {
  if (value === 0) {
    return <InputError>Не может быть равно 0</InputError>
  }
}

export const validEmail = value => {
  return isEmail(value)
}

export const requiredCheckBox = (value, props, components) => {
  if (!props.checked) {
    return (
      <div style={{ position: 'absolute', right: '10.5%', marginRight: '' }}>
        <img
          src={`data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='17'%20height='17'%20viewBox='0%200%2024%2024'%20fill='none'%20stroke='%23d63939'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'%3E%3Cline%20x1='18'%20y1='6'%20x2='6'%20y2='18'%3E%3C/line%3E%3Cline%20x1='6'%20y1='6'%20x2='18'%20y2='18'%3E%3C/line%3E%3C/svg%3E`}
          alt=""
        ></img>
      </div>
    )
  }
}

export const confirmPassword = (value, secondValue) => {
  if (value !== secondValue) {
    return <InputError>Пароли не совпадают.</InputError>
  }
}

export const isNumber = value => {
  if (isNaN(+value)) {
    return <InputError>Значение может состоять только из чисел.</InputError>
  }
}

export const moreThan500 = value => {
  if (value < 500) {
    return (
      <div className="mt-2 alert alert-danger" role="alert">
        Значение должно быть больше 500.
      </div>
    )
  }
}

export async function checkExistingEmail(value) {
  await axios.post(process.env.REACT_APP_BACKEND_API + 'auth/validate/email', {
    email: value,
  })
}

export function existingEmail(value) {
  return new Promise((resolve, reject) => {
    checkExistingEmail(value)
      .then(error => {
        resolve(true)
      })
      .catch(error => {
        reject(false)
      })
  })
}

export const existingUsername = async value => {
  try {
    await axios.post(
      process.env.REACT_APP_BACKEND_API + 'auth/validate/username',
      { username: value }
    )
    return null
  } catch (error) {
    if (error.response && error.response.status === 409) {
      return <InputError>Имя пользователя уже используется.</InputError>
    } else {
      console.error(error)
      return (
        <InputError>
          Произошла ошибка при проверке имени пользователя
        </InputError>
      )
    }
  }
}

export const isCyrillic = value => {
  return /^[а-яА-ЯёЁ\s\-]+$/.test(value)
}

export const isExistingCity = value => {
  let isExisting = cityList.filter(city => {
    if (city.name.toLowerCase() === value.toLowerCase()) {
      return true
    }
  })
  if (!(isExisting.length === 0)) {
    return true
  }
}

export const isTwoWords = value => {
  let words = value.split(' ').filter(word => {
    return word !== ''
  })
  if (words.length !== 3) {
    return <InputError>ФИО должно состоять из трех слов</InputError>
  }
}

export const validNumberFormat = (value, format) => {
  if (format) {
    let count = format.split('.').length - 1
    console.log(count, value.length)
    console.log(count === value.length)
    return value.length === count
  }
  return false
}

export const isOneWord = value => {
  let words = value.split(' ').filter(word => {
    return word !== ''
  })
  if (words.length !== 1) {
    return <InputError>Поле должно состоять из одного слова</InputError>
  }
}

export const isPhoneNumber = value => {
  if (!value) {
    return (
      /* Особенность использования библиотеки, приходится сперва высылать пустой темплейт*/
      <template></template>
    )
  }
  if (!isMobilePhone(value, 'any')) {
    return <InputError>Неверный формат номера телефона</InputError>
  }
}

const isValueRequired = async validators => {
  return validators.some(validator => {
    if (typeof validator === 'function' && validator.name === 'required') {
      return true
    } else if (
      typeof validator === 'object' &&
      validator.validator &&
      validator.validator.name === 'required'
    ) {
      return true
    }
    return false
  })
}

export const enoughBalance = async value => {
  console.log(value)
  let balance = await getWalletBalance()
  console.log(balance)
  if (parseInt(value) > balance.balance) {
    return <InputError>Недостаточно средств</InputError>
  }
}

export const validateField = async (data, validators = [], ignore = null) => {
  let validatorDub = validators?.slice(0) || []
  console.log(validatorDub)
  // Функция валидации поля, принимает в себя данные и массив валидаторов/объектов со структурой {validator: validator, args: []}
  // Возвращает ошибку или null

  let isRequired = await isValueRequired(validatorDub)
  if (ignore) {
    if (data === ignore) {
      return null
    }
  }
  if (isRequired) {
    validatorDub.unshift(required)
  } else {
    if (!data) {
      return null
    }
  }
  for (let i = 0; i < validatorDub.length; i++) {
    let error = null
    if (typeof validatorDub[i] === 'function') {
      error = await Promise.resolve(validatorDub[i](data))
    } else if (typeof validatorDub[i] === 'object') {
      error = await Promise.resolve(
        validatorDub[i].validator(data, ...validatorDub[i].args)
      )
    } else {
      console.log(validatorDub[i])
      throw new Error('Неверный тип валидатора')
    }

    if (error) return error
  }
}

export const getValidatedClassnames = (error, changed) => {
  return `form-control ${
    error && changed ? 'is-invalid' : !error && changed ? 'is-valid' : ''
  }`
}

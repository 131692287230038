import {
  array,
  string,
  object,
  date,
  boolean,
  number,
  ref,
  setLocale,
  mixed,
} from 'yup'
import * as Yup from 'yup'
import {
  checkExistingEmail,
  isCyrillic,
  isExistingCity,
  isNumber,
  isUrl,
  notZero,
  required,
  validDeadline,
  validEmail,
  validINN,
  validNumberFormat,
} from './validation'
import { useState } from 'react'

const priceField = number()
  .typeError('Введите число')
  .required()
  .min(1000, 'Цена должна быть больше/ равна 1000₽')
const phoneField = mixed()
  .required('Обязательное поле')
  .test('phone', 'Некорректный номер', phone => {
    return validNumberFormat(phone.value, phone.format)
  })

setLocale({
  mixed: {
    required: 'Обязательное поле',
    notType: 'Некорректное значение',
  },
  string: {
    email: 'Некорректный email',
    url: 'Некорректная ссылка',
    min: 'Минимум ${min} символов',
    max: 'Максимум ${max} символов',
    length: 'Длина должна быть равна ${length} символам',
  },
  number: {
    min: 'Минимум ${min}',
    max: 'Максимум ${max}',
    integer: 'Должно быть целым числом',
    notType: 'Некорректное число',
  },
  date: {},
})

export const bloggerSchema = object().shape({
  link: string().required().test('isUrl', 'Некорректная ссылка', isUrl),
  themes: array().test('notEmpty', 'Выберите тематику', (value = []) => {
    return value.length > 0
  }),
  location: string().required(),
  description: string().required(),
  advLayout: priceField,
  story: priceField,
  post: priceField,
  isPolicy: boolean().test('isPolicy', 'Обязательное поле', value => {
    return value
  }),
  gender: string().required('Обязательное поле'),
  age: string().required('Обязательное поле'),
  screenshots: array(),
})

export const offerSchema = object().shape({
  title: string().required(),
  url: string()
    .required('Обязательное поле')
    .test('isUrl', 'Некорректная ссылка', isUrl),
  acceptDeadline: date()
    .required('Обязательное поле')
    .test(
      'validDeadline',
      'Дата должна быть не раньше чем через 3 дня',
      validDeadline
    ),
  saveToDrafts: boolean(),
  offerDraftId: string(),
  price: priceField,
  createLink: boolean(),
  description: string().required('Обязательное поле'),
  files: array(),
})

export const auctionSchema = offerSchema.shape({
  locationImportant: boolean(),
  geo: string().test(
    'locationNotImportant',
    'Обязательное поле',
    function (value) {
      if (!this.parent.locationNotImportant) {
        return required(value)
      }
      return true
    }
  ),
  minFollowers: number().typeError('Введите число подписчиков').min(0),
  platform: string().required('Обязательное поле'),
})

const requiredIfLegal = [
  'requiredIf',
  'Обязательное поле',
  function (value) {
    console.log(this.parent)
    if (!(this.parent.legalType === 'LEGAL_TYPE_LEGAL')) {
      console.log(this.parent.legalType)
      return true
    }
    return required(value)
  },
]

const requiredIfEnt = [
  'requiredIf',
  'Обязательное поле',
  function (value) {
    if (!(this.parent.legalType === 'LEGAL_TYPE_ENTREPRENEUR')) {
      return true
    }
    return required(value)
  },
]

export const legalSchema = object().shape({
  legalType: string().required(),
  inn: string().required().test('validINN', 'Некорректный ИНН', validINN),
  orgnip: string().test(...requiredIfEnt),
  fullOpf: string().test(...requiredIfLegal),
  ogrn: string().test(...requiredIfLegal),
  payer: string().test(...requiredIfLegal),
  legalAddress: string().test(...requiredIfLegal),
  physicalAddress: string().test(...requiredIfLegal),
  kpp: string().test(...requiredIfLegal),
  rascSchet: string().test(...requiredIfLegal),
  bank: string().test(...requiredIfLegal),
  korrSchet: string().test(...requiredIfLegal),
  bik: string().test(...requiredIfLegal),
  podpisant: string().test(...requiredIfLegal),
  naOsnovanii: string().test(...requiredIfLegal),
})

export const invoiceSchema = object().shape({
  inn: string().required().test('validINN', 'Некорректный ИНН', validINN),
  payer: string().required(),
  legalAddress: string().required(),
  physicalAddress: string().required(),
  kpp: string().required(),
  rascSchet: string().required(),
  bank: string().required(),
  korrSchet: string().required(),
  bik: string().required(),
  email: string()
    .required()
    .test('validEmail', 'Некорректный email', validEmail),
  number: phoneField,
})

export const userSchema = object().shape({
  initialEmail: string(),
  email: string()
    .required('Обязательное поле')
    .test('existingEmail', 'Email уже используется', async function (value) {
      if (value === this.parent.initialEmail) return true
      try {
        await checkExistingEmail(value) // Ваша функция для проверки существующего email
        return true // Email не существует
      } catch (error) {
        return false // Email уже существует
      }
    }),
  firstName: string()
    .required('Обязательное поле')
    .test(
      'firstName',
      'Может содержать только кириллицу или знак "-"',
      isCyrillic
    ),
  middleName: string().test(
    'middleName',
    'Может содержать только кириллицу или знак "-"',
    v => {
      if (v) {
        return isCyrillic(v)
      } else {
        return true
      }
    }
  ),
  lastName: string()
    .required('Обязательное поле')
    .test(
      'lastName',
      'Может содержать только кириллицу или знак "-"',
      isCyrillic
    ),
  city: string()
    .required('Обязательное поле')
    .test('city', 'Некорректный город', isExistingCity),
  phone: phoneField,
  accType: string()
    .required('Обязательное поле')
    .test('accType', 'Выберите тип аккаунта', accType => {
      console.log(accType)
      return accType !== ''
    }),
})

import axios from 'axios'
import { Cookies } from 'react-cookie'
//import Cookies from 'js-cookie';

const API_URL =
  (process.env.REACT_APP_BACKEND_API || 'http://127.0.0.1' + ':8080/api/') +
  'auth/'

console.log(process.env.REACT_APP_BACKEND_API)

const register = data => {
  return axios.post(
    API_URL + 'register',
    {
      ...data,
    },
    { withCredentials: true }
  )
}

const login = (email, password) => {
  return axios
    .post(
      API_URL + 'login',
      {
        email,
        password,
      },
      { withCredentials: true }
    )
    .then(response => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }

      //const cookies = new Cookies();
      //cookies.set('x-access-token', response.data.accessToken, {path: '/'});

      return response.data
    })
}

const logout = () => {
  localStorage.removeItem('user')
}

const getCurrentUser = () => {
  let u = JSON.parse(localStorage.getItem('user'))
  if (!u || u.expires < Date.now()) {
    logout()
    u = undefined
  }
  return u
}

const getRole = () => {
  const user = getCurrentUser()
  return user.role
}

const changeUsername = username => {
  let user = JSON.parse(localStorage.getItem('user'))
  user.firstName = username
  localStorage.setItem('user', JSON.stringify(user))
}

export const setUtm = utm => {
  localStorage.setItem('utm', JSON.stringify(utm))
}

export const setAccType = accType => {
  localStorage.setItem('user', JSON.stringify({ ...getCurrentUser(), accType }))
}

export default {
  register,
  login,
  logout,
  getCurrentUser,
  changeUsername,
  getRole,
  setAccType,
}

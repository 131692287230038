import React from 'react'

const NotificationCard = ({ readNotification, title, text, date, link }) => {
  return (
    <div onClick={readNotification} className="list-group-item">
      <a href={link}>
        <div className="d-flex align-items-center">
          <div className="me-3">
            <span className="status-dot status-dot-animated bg-red d-block" />
          </div>
          <div className="col" style={{ minWidth: '12rem' }}>
            <span className="d-block text-body mt-1">{title}</span>
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className="d-block text-body mt-1"
            ></div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default NotificationCard

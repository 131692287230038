import axios from 'axios'
import { toast } from 'react-hot-toast'
const API_URL = process.env.REACT_APP_BACKEND_API

// draft
export const getDrafts = async () => {
  const { data } = await axios.get(API_URL + `offers/drafts`, {
    withCredentials: true,
  })
  return data
}
export const getDraftById = async id => {
  const { data } = await axios.get(API_URL + `offers/draft/${id}`, {
    withCredentials: true,
  })
  return data
}
export const createDraft = async payload => {
  const { data } = await axios.post(API_URL + `offers/draft/create`, payload, {
    withCredentials: true,
  })
  return data
}
/* payload
    {
        title,
        platform,
        descrition,
        url,
        geo,
        files
    }
*/
export const updateDraft = async (id, payload) => {
  const { data } = await axios.post(
    API_URL + `offers/draft/${id}/update`,
    payload,
    { withCredentials: true }
  )
  return { data }
}

// deal
export const getDeals = async () => {
  const { data } = await axios.get(API_URL + `offers`, {
    withCredentials: true,
  })
  console.log('Deals', data)
  return data
}
export const getDealsToMe = async () => {
  const { data } = await axios.get(API_URL + `offers/me`, {
    withCredentials: true,
  })
  console.log('Deals to Me', data)
  return data
}
export const getDealById = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}`, {
    withCredentials: true,
  })
  return data
}

export const getDealOrdMarker = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/markord`, {
    withCredentials: true,
  })
  return data
}

export const updateDeal = async (id, payload) => {
  const { data } = await axios.put(API_URL + `offers/${id}/update`, payload, {
    withCredentials: true,
  })
  return data
}

/*  payload:
    {
        "offerDraftId": "any",
        "bloggerId": "any",
        "price": "any",
        "acceptDeadline": "any"
    }
*/
export const createDealFromDraft = async payload => {
  const { data } = await axios.post(API_URL + `offers/send`, payload, {
    withCredentials: true,
  })
  return data
}

export const acceptDealBlogger = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/accepttowork`, {
    withCredentials: true,
  })
  return data
}

export const declineDealBlogger = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/decline`, {
    withCredentials: true,
  })
  return data
}

export const cancelDealAdvertiser = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/cancel`, {
    withCredentials: true,
  })
  return data
}

/*  payload:
    {
        "message": "any",
        "linkToResult": "any",
        "files": "any"
    }
*/
export const finishDealBlogger = async (id, payload) => {
  const { data } = await axios.post(API_URL + `offers/${id}/finish`, payload, {
    withCredentials: true,
  })
  return data
}

export const addStatisticsDealBlogger = async (id, payload) => {
  const { data } = await axios.post(
    API_URL + `offers/${id}/addstatistics`,
    payload,
    { withCredentials: true }
  )
  return data
}

export const finishDealAdvertiser = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/acceptfinish`, {
    withCredentials: true,
  })
  return data
}

export const declineDealFinishAdvertiser = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/declinefinish`, {
    withCredentials: true,
  })
  return data
}

/* Оплатить предложение с внутреннего счёта */
export const payDealFromAccount = async (id) => {
  const { data } = await axios.post(API_URL + `offers/${id}/pay`, {}, { withCredentials: true} )
  return data
}

/*

Offer Auction

*/
export const getOfferAuctionById = async id => {
  const { data } = await axios.get(API_URL + `offers/auction/${id}`, {
    withCredentials: true,
  })
  return data
}

export const getOffersAuctions = async () => {
  const { data } = await axios.get(API_URL + `offers/auctions`, {
    withCredentials: true,
  })
  return data
}
/*  payload:
    {
        "price": "any",
        "blogger": "any",
        "finishDeadline": "any",
        "message": "any"
    }
*/
export const sendOfferAuctionResponse = async (id, payload) => {
  const { data } = await axios.post(
    API_URL + `offers/auction/${id}/response`,
    payload,
    { withCredentials: true }
  )
  return data
}

export const acceptOfferAuctionResponse = async (id, blogger_id) => {
  const { data } = await axios.post(
    API_URL + `offers/auction/${id}/acceptresponse`,
    { blogger: blogger_id },
    { withCredentials: true }
  )
  return data
}

export const cancelOfferAuction = async (id, blogger_id) => {
  const { data } = await axios.get(API_URL + `offers/auction/${id}/cancel`, {
    withCredentials: true,
  })
  return data
}

export const updateOfferAuction = async (id, payload) => {
  const { data } = await axios.put(
    API_URL + `offers/auction/${id}/update`,
    payload,
    { withCredentials: true }
  )
  return data
}

export const createOfferAuction = async payload => {
  const { data } = await axios.post(
    API_URL + `/offers/auction/create`,
    payload,
    { withCredentials: true }
  )
  return data
}

// message
export const getOfferMessages = async id => {
  const { data } = await axios.get(API_URL + `offers/${id}/messages`, {
    withCredentials: true,
  })
  return data
}

/*  payload:
    {
        "message": "any"
    }
*/

export const sendOfferMessage = async (id, payload) => {
  const { data } = await axios.post(
    API_URL + `offers/${id}/messages/send`,
    payload,
    { withCredentials: true }
  )
  return data
}

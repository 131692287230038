import React from 'react'
import PlusIcon from '../plusIcon/plusIcon'
import { Link } from 'react-router-dom'
import cls from './plusButtonGroup.module.scss'
///import plusButtonGroup.module.scss///

const plusButtonGroup = ({ to, children, onClick }) => {
  return (
    <Link
      onClick={onClick}
      to={to}
      className="bold text-black align-items-center d-flex column-gap-2"
    >
      <span className={cls.description} style={{ textAlign: 'left' }}>
        {children}
      </span>
      <PlusIcon />
    </Link>
  )
}

export default plusButtonGroup

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import { _ } from 'gridjs-react'
import React, { useEffect, useState } from 'react'

import axios from 'axios'

import Modal from 'react-bootstrap/Modal'

import toast, { Toaster } from 'react-hot-toast'
import TableDeleteButton from '../../components/UI/icons/tableIcons/tableDeleteButton'
import TableStatsButton from '../../components/UI/icons/tableIcons/tableStatsButton'

import NewInput from '../../components/UI/newInput/newInput.tsx'
import Button from '../../components/UI/button/button'

import { MyTable } from '../../components/UI/myTable/myTable'
import { CreateShortLink } from '../../components/modals/createShortLink'
import { ShortLinkStats } from '../../components/modals/shortLinkStats'

import plusIcon from '../../assets/icons/plus.svg'
import { ConfirmModal } from '../../components/modals/confirmModal'

export default function Links() {
  const [show, setShow] = useState(false)
  const [showStats, setShowStats] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [data, setData] = useState([])
  const [statsLink, setStatsLink] = useState(null)
  const [linkToDelete, setLinkToDelete] = useState(null)

  useEffect(() => {
    sf('my')
  }, [])

  const sf = f => {
    //setFilter(f)
    console.log(f)

    axios
      .get(process.env.REACT_APP_BACKEND_API + `links?filter=${f}`, {
        withCredentials: true,
      })
      .then(response => {
        console.log(response.data)
        setData(response.data)
      })
  }

  useEffect(() => {
    console.log(data)
  }, [data])

  const deleteLink = id => {
    axios
      .delete(process.env.REACT_APP_BACKEND_API + `links/delete/${id}`, {
        withCredentials: true,
      })
      .then(response => {
        console.log(response.data)
        toast.success('Ссылка успешно удалена')
        sf('my')
      })
      .catch(function (error) {
        toast.error(error.response.data.message)
        console.log(error.toJSON())
      })
  }

  const linkStats = id => {
    setStatsLink(id)
    setShowStats(true)
  }

  return (
    <div className="page">
      <Navbar links></Navbar>
      <ConfirmModal
        confirmText={'Вы точно хотите удалить ссылку?'}
        show={showDeleteConfirm}
        onApply={confirmed => {
          if (confirmed) {
            deleteLink(linkToDelete)
          }
          setShowDeleteConfirm(false)
        }}
      ></ConfirmModal>
      <div className="page-wrapper">
        <PageHeader title="Короткие ссылки">
          <div className="btn-list">
            <Button
              onClick={handleShow}
              style={{ minWidth: '280px' }}
              className="btn btn_secondary text-14-bold"
            >
              <img
                style={{ height: '13px' }}
                className="ms-1"
                src={plusIcon}
                alt=""
              />
              <span className="mx-auto">создать ссылку</span>
            </Button>
          </div>
        </PageHeader>

        <div className="page-body">
          <div className="container-xl">
            {show && (
              <CreateShortLink
                show={show}
                onHide={() => setShow(false)}
                onApply={() => {
                  sf('my')
                  handleClose()
                }}
              />
            )}

            {/*<Modal show={show} style={{ borderRadius: '0px' }} onHide={handleClose} dialogClassName="modal-dialog-centered" backdrop={false}  >*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>Создать короткую ссылку</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-8">*/}
            {/*                <div className="mb-3">*/}
            {/*                    <label className="form-label">Короткая ссылка</label>*/}
            {/*                    <div className="input-group input-group-flat">*/}
            {/*                        <InputGroup label={'https://lyy.ink/'} value={slug} onChange={e => setSlug(e.target.value)} placeholder={'Ссылка'}></InputGroup>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="mb-5">*/}
            {/*            <NewInput type="url" className="form-control" value={url} onChange={e => setUrl(e.target.value)} placeholder="Адрес назначения" />*/}
            {/*        </div>*/}
            {/*        <div className='d-flex justify-content-between'>*/}
            {/*            <Button secondary className="btn me-auto" onClick={handleClose}>*/}
            {/*                Закрыть*/}
            {/*            </Button>*/}
            {/*            <Button className="btn btn_primary" onClick={createLink}>*/}
            {/*                Создать*/}
            {/*            </Button></div>*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}

            {/*<Modal show={showStats} onHide={() => setShowStats(false)} dialogClassName="modal-dialog-centered" backdrop={false}  >*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>Статистика</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <div className='mb-3'>*/}
            {/*            {stats.visits && stats.visits.map((visit) => {*/}
            {/*                return (<p>👤 {visit.date} {visit.ua}</p>)*/}
            {/*            }) && 'По вашей ссылке пока нет статистики'}*/}
            {/*        </div>*/}

            {/*        <Button className="btn btn_secondary" onClick={() => setShowStats(false)}>*/}
            {/*            Закрыть*/}
            {/*        </Button>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}

            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}

            <ShortLinkStats
              show={showStats}
              id={statsLink}
              onHide={() => setShowStats(false)}
            />

            <div className="d-flex flex-wrap row-gap-3   justify-content-between mb-5">
              <div>
                <p className="m-0">
                  <span className="text--gradient text-uppercase fw-bold f-geometria">
                    Bloggery.io предоставляет сервис коротких ссылок
                  </span>
                </p>
                <span className="text--disabled">
                  {' '}
                  Здесь вы можете сократить любую ссылку
                </span>
              </div>
            </div>

            <div>
              <MyTable tableContainer={{ border: 'none', overflowX: 'auto' }}>
                <thead>
                  <tr className="fs-3" style={{ border: '1px solid #515CD1' }}>
                    <th className={'th-mw-150'}>Короткая ссылка</th>
                    <th className={'th-mw-150'}>Переходы</th>
                    <th className={'th-mw-150'}>Назначение</th>
                    <th className={'th-mw-150'}>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({ _id, slug, visits, url }) => (
                    <tr key={_id}>
                      <td>
                        <a
                          className="text-black"
                          href={'https://uul.ink/' + slug}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          uul.ink/{slug}
                        </a>
                      </td>
                      <td>{visits}</td>
                      <td>
                        <a href={url}>{url}</a>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <button
                            className={'bg-transparent border-0'}
                            onClick={e => linkStats(_id)}
                          >
                            <TableStatsButton />
                          </button>
                          <button
                            className={'bg-transparent border-0'}
                            onClick={() => {setShowDeleteConfirm(true); setLinkToDelete(_id)}}
                          >
                            <TableDeleteButton />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </MyTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

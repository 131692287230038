import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import { getFilesMetadata } from '../../services/file.service'

import { Grid } from 'gridjs'
import { _ } from 'gridjs-react'
import { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import AuthService from '../../services/auth.service'
import axios from 'axios'
import moment from 'moment'

export default function AdminBlogger() {
  let { id } = useParams()
  const [bloggerData, setBloggerData] = useState(null)
  const [files, setFiles] = useState([])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + `admin/bloggers/${id}`, {
        withCredentials: true,
      })
      .then(res => {
        setBloggerData(res.data)
        getFilesMetadata(
          res.data.blogger.screenshots &&
            res.data.blogger.screenshots?.join(',')
        ).then(files => setFiles(files))
      })
  }, [id])

  const forceUpdate = async () => {
    toast.success('Обновление запущено')
    axios
      .get(process.env.REACT_APP_BACKEND_API + `statisticsForce?id=${id}`, {
        withCredentials: true,
      })
      .then(res => {
        toast.success('Успешно обновлено')
        console.log(res)
      })
      .catch(error => {
        toast.error('Ошибка')
        console.log(error)
      })
  }

  return (
    <div className="page">
      <Navbar></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Blogger"></PageHeader>
        <div className="page-body">
          <div className="container-xl" style={{ whiteSpace: 'pre-wrap' }}>
            {bloggerData && (
              <>
                <div className="card">
                  <div className="card-body">
                    <button onClick={() => forceUpdate()}>
                      Принудительно обновить статистику
                    </button>
                    <h3>Осн. данные</h3>
                    {JSON.stringify(bloggerData.blogger, null, 2)}

                    <div className={'d-flex mb-7'}>
                      {files &&
                        files.map(file => {
                          const src =
                            process.env.REACT_APP_BACKEND_API +
                            'file/' +
                            file.id
                          return (
                            <a
                              className="d-block h-25 w-25"
                              key={file.id}
                              href={src}
                              type={file.ContentType}
                              target="_blank"
                              rel="noreferrer">
                              {file.ContentType.split('/')[0] == 'image' ? (
                                <img
                                  className=""
                                  style={{
                                    objectFit: 'contain',
                                    objectPosition: 'left',
                                  }}
                                  src={src}
                                />
                              ) : (
                                decodeURI(file.Metadata.originalname)
                              )}
                            </a>
                          )
                        })}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3>Сделки</h3>
                    {JSON.stringify(bloggerData.deals, null, 2)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

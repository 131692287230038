import { formatHour } from './formatters'

export const translateServerMsg = message => {
  switch (message) {
    case 'Not full info':
      return 'Не полная информация'
    case 'Blogger already exists.':
      return 'Такой блогер уже существует'
    case 'Blogger was registered successfully!':
      return 'Блогер был успешно зарегистрирован!'
    case 'Link was registered successfully!':
      return 'Ссылка успешно создана!'
    case "You can't delete this link.":
      return 'Вы не можете удалить данную ссылку'
    case 'Error':
      return 'Ошибка'
    case 'Not found':
      return 'Не найден'
    case "You can't accept this offer":
      return 'Вы не можете принять данный заказ'
    case 'Offer already has deal':
      return 'Данное предложение уже заключено'
    case 'Offer exclusive not for you':
      return 'Предложение эксклюзивно не для Вас'
    case "You can't decline this offer":
      return 'Вы не можете отменить это предложение'
    case 'Offer declined':
      return 'Отклонено'
    case "Message can't be empty":
      return 'Сообщение не может быть пустым'
    case 'Campaign offer was registered successfully!':
      return 'Рекламное предложение было успешно зарегистрировано'
    case "You can't delete this.":
      return 'Вы не можете это удалить'
    case 'Not allowed to tableEditButton':
      return 'У Вас нет прав для редактирования данной информации'
    case 'User was registered successfully!':
      return 'Пользователь был успешно зарегистрирован!'
    case 'User Not found.':
      return 'Пользователь не найден'
    case 'Invalid Password!':
      return 'Неверный пароль'
    case 'You have requested password reset. Check your email box. Or try again in 10 minutes.':
      return 'Вы запрашивали сброс пароля. Проверьте свою эл. почту. Или повторите попытку через 10 минут.'
    case 'Check your email':
      return 'Проверьте свою эл. почту'
    case 'Success':
      return 'Успешно'
    case 'Failed! Username is already in use!':
      return 'Данное имя пользователя уже занято'
    case 'Failed! Email is already in use!':
      return 'Данная почта уже используется'
    case 'Internal server error.':
      return 'Внутренняя ошибка сервера'
    case 'User was not found':
      return 'Пользователь не найден'
    case 'Email already in use':
      return 'Данная почта уже используется'
    case 'Verification code is invalid':
      return 'Неверный код подтверждения'
    case 'You have no email to verify':
      return 'У Вас нет почты для подтверждения'
    case 'Not enough balance':
      return 'Недостаточный баланс'

    default:
      return ''
  }
}

export const fromatDateString = (dateString, timezoneOffset = false) => {
  const formattedDate = new Date(dateString).toLocaleDateString('ru-RU') // dd.mm.yyyy

  return formattedDate
}

export const formatNumber = n => {
  if (n < 1000) return n
  if ((n >= 1000) & (n < 1000000))
    return (n / 1000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] + 'k'
  if (n >= 1000000)
    return (
      (Math.round(n / 1000) / 1000)
        .toString()
        .match(/^-?\d+(?:\.\d{0,1})?/)[0] + 'm'
    )
  return n
}

/*

    {
        '1': [{hour: 0, index: 1, value: getAverage({ likes, comments, interactions })}, {}, {}],
    }

*/

export const parseActivitiesData = data => {
  if (!data) return []

  const activities = []
  for (let i = 0; i < 7; i++) {
    for (let j = 0; j < 24; j++) {
      let currentData = data.find(item => item.time === `${i + 1}_${j}`)
      const { likes, comments, interactions } = currentData
        ? currentData
        : { likes: 0, comments: 0, interactions: 0 }
      activities.push({
        day: i,
        hour: j,
        values: {
          likes: Math.round(likes * 100) / 100,
          comments: Math.round(comments * 100) / 100,
          interactions: Math.round(interactions * 100) / 100,
        },
        averageValue: Math.round(interactions * 100) / 100 + 1,
      })
    }
  }
  return activities
}

export const getAverage = ({ likes, comments, interactions }) => {
  return (likes + comments + interactions + 3) / 3
}

export const addSpacesToNumber = number => {
  if (!number) return '0'
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const fixUrlProtocol = url => {
  return /^(ftp|http|https):\/\/[^ "]+$/.test(url) ? url : '//' + url
}

import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// import required modules
import { Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/navigation'

import './slider.scss'

const SwiperComponent = props => {
  return (
    <Swiper
      className={props.className}
      slidesPerView={props.slidesPerView || 4}
      spaceBetween={props.spaceBetween || 25}
      modules={[Navigation]}
      navigation={true}
    >
      {props.children.map((e, i) => (
        <SwiperSlide key={i}>{e}</SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperComponent

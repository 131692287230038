// import {Tooltip} from "bootstrap";
// import {Tooltip} from "@tabler/core/dist/libs/bootstrap/dist/js/bootstrap.esm";

import { isUrl, notNegative, required } from './validation'

export const delayedSetValue = (value, setValue) => {
  const valueArray = Array.from(value)
  let index = 0

  const setValueWithDelay = () => {
    setTimeout(() => {
      if (index < valueArray.length) {
        console.log(valueArray.slice(0, index + 1))
        setValue(valueArray.slice(0, index + 1).join(''))
        index++
        console.log(valueArray[index])
        if (valueArray[index] === ',') {
          setTimeout(setValueWithDelay, Math.random() + 55)
        } else if (valueArray[index] === '.') {
          setTimeout(setValueWithDelay, Math.random() + 100)
        } else {
          setTimeout(setValueWithDelay, Math.random() + 30)
        }
      } else {
      }
    })
  }

  setValueWithDelay() // Start the delayed updating process
}

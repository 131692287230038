import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../components/UI/navBar/navbar'
import PageHeader from '../components/pageHeader/page-header'

import { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import {
  getInvoices,
  getTransactions,
  getWalletBalance,
} from '../services/wallet.service'
import PriceInput from '../components/UI/newInput/priceInput'
import Button from '../components/UI/button/button'
import { MyTable } from '../components/UI/myTable/myTable'

import { formatDate } from '../utils/formatters'
import { transactionTypes } from '../utils/translate'
import WalletCard from '../components/UI/walletCard/walletCard'
import payWidget from '../services/payWidget'
import { Link } from 'react-router-dom'

import { toast } from 'react-hot-toast'

export default function Wallet() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [transactions, setTransactions] = useState([])
  const [amount, setAmount] = useState(1000)
  const [wallet, setWallet] = useState({})
  const [invoices, setInvoices] = useState([])

  const [withdrawInvoice, setWithdrawInvoice] = useState(null)

  useEffect(() => {
    getWalletBalance().then(data => setWallet(data))
    getInvoices().then(data => setInvoices(data))
    getTransactions().then(data => setTransactions(data))
    if (searchParams.get('orderId')) {
      console.log(searchParams.get('orderId'))
      axios
        .get(
          process.env.REACT_APP_BACKEND_API +
            `wallet/verify?orderId=${searchParams.get('orderId')}`,
          { withCredentials: true }
        )
        .then(response => {
          console.log(response.data)
        })
    }
  }, [])

  const topUp = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API + `wallet/topup?amount=${amount}00`,
        {
          withCredentials: true,
        }
      )
      .then(response => {
        console.log(response)
        window.open(response.data.formUrl)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const cashOut = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API +
          `wallet/requestwithdrawal?amount=${amount}`,
        {
          withCredentials: true,
        }
      )
      .then(response => {
        console.log(response)

        setWithdrawInvoice(response.data.orderNumber)
      })
      .catch(error => {
        toast.error('Недостаточно средств для вывода')
        console.log(error)
      })
  }

  //DEGUB log
  useEffect(() => {
    console.log(invoices)
  }, [invoices])
  useEffect(() => {
    //console.log(transactions[0].invoice);
    console.log(transactions)
    // console.log(
    //    transactions.map(({_id, amount, invoice}) => {
    //        return {
    //            _id,
    //            amount,
    //            invoice: JSON.parse(invoice.split('\n').join('')),
    //        }
    //    })
    //);
  }, [transactions])

  return (
    <div className="page">
      <Navbar></Navbar>

      <div className="page-wrapper">
        <div className="d-flex justify-content-center">
          <h2 className="text--page-title">Кошелек</h2>
        </div>
        <div className="container-xl">
          <div
            style={{
              border: '1px solid #FF5FAB',
              padding: '1rem',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}>
            Пока Bloggery работает в тестовом режиме. Вернитесь сюда позже!
          </div>
        </div>

        <div className="page-body">
          <div className="container-xl">
            <div className="mb-5 d-flex column-gap-3">
              <WalletCard
                value={wallet.balance}
                descr={
                  <span>
                    ваш текущий <br /> баланс
                  </span>
                }></WalletCard>
              <WalletCard
                value={0}
                descr={
                  <span>
                    Заработано
                    <br /> за весь период
                  </span>
                }></WalletCard>
            </div>

            <div className="">
              <div className="card-body">
                <div className="d-flex justify-content-center column-gap-3">
                  <div className="col-md-3"></div>
                  {/*<div className='col-md-6'>
                                        <h2 className='text--gradient'>Пополнение</h2>

                                        <p className='text--gradient'>Пополнение баланса личного кабинета пока производится в ручном режиме.</p>

                                        <div className="mb-5 ">
                                            <PriceInput type="text" className="form-control" placeholder="1000" value={amount} onChange={e => setAmount(e.target.value)} autoComplete="off" />
                                        </div>
                                        <div className="d-flex justify-content-center ">
                                            <Button onClick={() => payWidget({title: 'offer1', amount: 1000})} className="btn btn_primary px-6">
                                                Пополнить
                                            </Button>
                                        </div>
                                    </div>*/}
                  <div className="col-md-8">
                    <div className={'align-items-center column d-flex'}>
                      <h2 className="text--gradient f-geometria text-uppercase mb-2">
                        Вывод средств
                      </h2>
                      <p className="text-14-regular text--black mb-5">
                        Вывод средств производится в ручном режиме.
                      </p>
                    </div>

                    <div className="mb-5 ">
                      {!withdrawInvoice && (
                        <>
                          <PriceInput
                            type="text"
                            containerClass="mb-2"
                            placeholder="1000"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            autoComplete="off"
                          />
                          <p className="text-center text--disabled text-14-regular">
                            Для физических лиц необходимо получить статус
                            самозанятого. Вывод средств производится в ручном
                            режиме.
                          </p>
                        </>
                      )}

                      <div className={!withdrawInvoice ? 'd-none' : ''}>
                        <p
                          style={{
                            background: 'transparent',
                            borderRadius: '0',
                            border: '2px solid var(--main-purple)',
                          }}
                          className="p-2 mt-3 text-wrap fs-3">
                          <b>
                            Мы получили вашу заявку на вывод денежных средств с
                            нашего сервиса!
                          </b>
                          <br />
                          Передали ее в бухгалтерию, в течение двух рабочих дней
                          Вам поступит ссылка по номеру телефона от ООО
                          «ДОНАТФАНС» на подписание закрывающих актов и
                          формирование чека.
                          <br /> Вам необходимо будет перейти и заполнить ваши
                          реквизиты, после этого оплата будет произведена!
                        </p>
                        <p>
                          Запрос <b>№ {withdrawInvoice}</b>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mb-40">
                      {!withdrawInvoice && (
                        <Button
                          onClick={cashOut}
                          className="btn btn_secondary w-280">
                          Запросить вывод
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div className={'d-flex justify-content-center'}>
                  <h2 className="text-center m-0 mb-3 text-16-bold text-uppercase text--gradient">
                    Операции
                  </h2>
                </div>
                <div className="mb-40">
                  <MyTable
                    tableContainer={{ border: 'none', overflowX: 'auto' }}
                    style={{ minHeight: 0 }}
                    className="table table-transparent table-responsive">
                    <thead>
                      <tr>
                        <th className="">Операция</th>
                        <th className="th-mw-150 ">Дата</th>
                        <th className="th-mw-150">Статус</th>
                        <th className="th-mw-150">Сумма</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((tr, i) => (
                        <tr key={tr._id}>
                          <td>
                            <p className="strong mb-1">
                              {tr.type == 'topup' && 'Пополнение'}{' '}
                              {tr.type == 'withdrawal' && 'Вывод'}
                            </p>
                            <div className="text-muted">{tr.paymentMethod}</div>
                          </td>
                          <td className="">{formatDate(tr.createdAt)}</td>
                          <td className="">{tr.status}</td>
                          <td className="">{tr.amount}.00 руб</td>
                        </tr>
                      ))}
                    </tbody>
                  </MyTable>
                </div>

                <div className={'d-flex justify-content-center'}>
                  <h2 className="text-center m-0 mb-3 text-16-bold text-uppercase text--gradient">
                    Транзакции
                  </h2>
                </div>
                <MyTable
                  tableContainer={{ border: 'none', overflowX: 'auto' }}
                  style={{ minHeight: 0 }}
                  className="table table-transparent table-responsive">
                  <thead>
                    <tr>
                      <th className=" ">Транзакция</th>
                      <th className={'th-mw-150'}>Дата</th>
                      <th className="th-mw-150">Статус</th>
                      <th className="th-mw-150">Сумма</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((tr, i) => (
                      <tr key={tr._id}>
                        <td>{transactionTypes[tr.type]}</td>
                        <td className="">{formatDate(tr.createdAt)}</td>
                        <td>
                          {tr.offer ? (
                            <Link to={`/offer/${tr.offer}`}>
                              Предложение блогеру
                            </Link>
                          ) : (
                            tr.status
                          )}
                        </td>

                        <td className="">{tr.amount}.00 руб</td>
                      </tr>
                    ))}
                  </tbody>
                </MyTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

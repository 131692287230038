import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_BACKEND_API

export const getWalletBalance = async () => {
  const { data } = await axios.get(API_URL + `wallet`, {
    withCredentials: true,
  })
  return data
}

export const getInvoices = async () => {
  const { data } = await axios.get(API_URL + `wallet/invoices`, {
    withCredentials: true,
  })
  return data
}

export const getTransactions = async () => {
  const { data } = await axios.get(API_URL + `wallet/transactions`, {
    withCredentials: true,
  })
  return data
}

import React from 'react'
import AccountTypeRadio from '../radioButton/radioButton'
import RadioButton from '../radioButton/radioButton'

const radioGroup = ({
  className,
  options,
  setter,
  getter,
  name,
  between,
  big,
  currentValue,
  buttonClassName,
  buttonStyle,
  getFieldProps = () => {},
}) => {
  return (
    <div
      className={`d-flex column-gap-2 flex-wrap column-gap-2 row-gap-2 ${
        between ? 'justify-content-between fill' : ''
      } ${className ? className : ''}`}
    >
      {options.map(option => (
        <RadioButton
          name={name}
          value={option.value}
          style={{
            paddingRight: big ? '50px' : '',
            paddingLeft: big ? '50px' : '',
            ...buttonStyle,
          }}
          id={option.id}
          className={
            ` form-selectgroup-input" ${between ? ' flex-fill' : ''} ` +
            buttonClassName
          }
          onChange={e => {
            setter(e.target.value)
          }}
          checked={
            getter ? getter == option.value : option.value === currentValue
          }
          children={option.label}
          {...getFieldProps(name)}
        />
      ))}
    </div>
  )
}

export default radioGroup

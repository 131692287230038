import React from 'react'
import styles from '../../../pages/blogger/viewBlogger.module.scss'
import Bordered from '../bordered/bordered'
///import bloggerStatistics.module.scss///
import cls from './bloggerStatistics.module.scss'
import { addSpacesToNumber } from '../../../utils/functional'

const bloggerStatistics = ({ primary, title, value, icon }) => {
  return (
    <div className="col-6 col-md-3" style={{ minHeight: '108px' }}>
      <div
        className={
          'h-100 card py-4 align-items-center d-flex column ' +
          (primary ? cls.primary : cls.secondary)
        }
      >
        <div
          style={{ marginBottom: '0.75rem' }}
          className=" card-borderless justify-content-center"
        >
          <span className="justify-content-center d-flex align-items-center column-gap-2 text-white f-geometria text-uppercase text-12-bold">
            <span
              style={{ height: '11px' }}
              className="d-flex align-items-center pb-1"
            >
              {icon}
            </span>
            {title}
          </span>
        </div>
        <div
          className="w-75 bg-white"
          style={{ height: '1px', marginBottom: '0.75rem' }}
        />
        <div className="text-16-bold text-center">
          {value ? (
            addSpacesToNumber(value)
          ) : (
            <span className="invisible">''</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default bloggerStatistics

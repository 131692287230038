import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../components/UI/navBar/navbar'
import PageHeader from '../components/pageHeader/page-header'

import { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import AuthService from '../services/auth.service'

import { Link } from 'react-router-dom'

import moment from 'moment'

export default function Referrals() {
  const [referrals, setRefferals] = useState([])
  const [amount, setAmount] = useState(1000)
  const [wallet, setWallet] = useState({})
  const [invoices, setInvoices] = useState([])

  const [withdrawInvoice, setWithdrawInvoice] = useState(null)

  const fetchReferrlas = async (offset = 0) => {
    await axios
      .get(process.env.REACT_APP_BACKEND_API + `user/referrals`, {
        withCredentials: true,
      })
      .then(response => {
        setRefferals(response.data)
        console.log(response.data)
      })
  }

  useEffect(() => {
    fetchReferrlas()
  }, [])

  return (
    <div className="page">
      <Navbar></Navbar>

      <div className="page-wrapper">
        <PageHeader title="Реферальный кабинет"></PageHeader>

        <div className="page-body">
          <div className="container">
            <div className="mb-5 d-flex column-gap-3"></div>

            <h2 className="text--gradient">Реферальная система</h2>

            <p>
              Реферальная система bloggery позволяет привлекать блогеров на
              площадку и получать доход с каждой сделки!{' '}
            </p>
            <p>Пригласите пользователя по своей уникальной ссылке: </p>
            <div style={{ maxWidth: '100vw', overflow: 'auto' }}>
              <b>
                https://bloggery.io/?utm_source=
                {AuthService.getCurrentUser().id}
                &utm_medium=link&utm_campaign=ref
              </b>
            </div>

            <h2 className="text--gradient mt-5">Привлеченные пользователи</h2>

            {referrals.length == 0 && (
              <p>
                У вас нет привлеченных пользователей. Зовите знакомых и
                получайте доход!
              </p>
            )}

            {referrals.map((usr, i) => (
              <div key={usr._id}>
                <div>
                  <p className="strong mb-1">
                    Пользователь {usr.firstName} {usr.lastName} -{' '}
                    {moment(usr.createdAt).format('DD.MM.YYYY')}
                  </p>
                  <div className="text-muted">
                    {usr.bloggersCount} аккаунт(ов) на платформе
                  </div>
                  <div>
                    {usr.bloggers.map((blo, i) => (
                      <div key={blo._id} className={'mb-3 mt-3 d-inline-flex'}>
                        <Link to={'/blogger/' + blo._id}>
                          <img src={blo.avatar} height={64} width={64} />
                        </Link>
                        <div style={{ marginLeft: '16px' }}>
                          <Link to={'/blogger/' + blo._id}>
                            <h4
                              style={{
                                fontSize: '17px',
                                lineHeight: '1.2em',
                                letterSpacing: '0.01em',
                                textTransform: 'uppercase',
                                color: '#515CD1',
                              }}
                            >
                              @{blo.username}
                            </h4>
                          </Link>
                          <p>{blo.platform}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

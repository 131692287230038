import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../components/UI/navBar/navbar'
import PageHeader from '../components/pageHeader/page-header'
import { HashLink as Link } from 'react-router-hash-link'
import { useRef, useEffect } from 'react'

import AuthService from '../services/auth.service'

export default function Support() {
  return (
    <div className="page">
      <Navbar></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Инструкция"></PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h3>Рекламодателю</h3>
                    <ul>
                      <li>
                        <Link to="#offerauction">
                          Разместить публичное рекламное предложение (сделать
                          аукцион)
                        </Link>
                      </li>
                      <li>
                        <Link to="#offerpersonal">
                          Оперативно выбрать блогера и опубликовать рекламу
                        </Link>
                      </li>
                      <li>
                        {' '}
                        <Link to="#key">
                          Кнопка, которая все сделает за Вас
                        </Link>
                      </li>
                    </ul>

                    <h3>Блогеру</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card card-lg">
                  <div className="card-body">
                    <p>
                      Добро пожаловать на платформу BLOGGERY Сейчас мы Вам
                      расскажем, как тут все устроено! Обещаем, что нашим
                      сервисом пользоваться легко и удобно!
                    </p>
                    <p>
                      Сразу говорим Вам, что в правом нижнем углу размещен чат
                      поддержи, Вы всегда можете в него обратиться по всем
                      возникающим вопросам!
                    </p>

                    <p>
                      Давайте выберем, что вы хотите сделать в первую очередь:
                    </p>

                    <h3
                      style={{ fontSize: '20px', marginTop: '48px' }}
                      id="offerauction"
                    >
                      Разместить публичное рекламное предложение (сделать
                      аукцион)
                    </h3>

                    <p>
                      Разместить публичное рекламное предложение (сделать
                      аукцион), на которое смогут откликнуться множество
                      блогеров, среди которых Вы выберете одного или нескольких,
                      то следует нажать на «разместить предложение»
                      <img src={'https://i.imgur.com/coedzco.png'} />
                      Если Вы приняли решение разместить публичное рекламное
                      предложение (сделать аукцион), то сейчас мы Вам расскажем,
                      как это правильно сделать:
                    </p>
                    <img src={' https://i.imgur.com/6o8tAsx.png'} />

                    <ol>
                      <li>
                        Выбираете платформу (социальную сеть), в которой нам
                        нужна реклама
                      </li>
                      <li>
                        Делаете краткий заголовок, чтобы блогерам было понятно,
                        что будем рекламировать
                      </li>
                      <li>
                        Заполняете техническое задание (все нюансы, подробности
                        о ваших потребителях, креативы и идеи) (если коротко –
                        то, как бы Вы хотели, чтобы выглядела Ваша реклама
                        мечты)/ либо если Вы еще не определились, как должно
                        выглядеть ТЗ, то можете воспользоваться нашей нейросетью
                        – (стрелка)
                      </li>
                      <li>Далее заполняете дату размещения рекламы</li>
                      <li>
                        {' '}
                        Указываете стоимость рекламы, которую вы готовы оплатить
                        за ОДНОГО БЛОГЕРА
                      </li>
                      <li>
                        Указываете минимальное количество подписчиков у блогера
                      </li>
                      <li>
                        {' '}
                        При необходимости, выбираете город, в котором должен
                        находиться блогер и его основная аудитория (это крайне
                        востребовано для рекламы шоурумов, кафе и офлайн точек)
                      </li>
                      <li>
                        {' '}
                        Заполняете ссылку на сайт или социальную сеть
                        рекламируемого продукта
                      </li>
                      <li>
                        При необходимости, подгружаете презентацию, фото или
                        видео-материалы вашего продукта
                      </li>
                      <li>
                        Вы можете сохранить данное рекламное предложение в
                        качестве шаблона и затем предлагать его всем блогерам
                      </li>
                      <li>Создать предложение</li>
                    </ol>
                    <p>
                      ВСЕ, ТЕПЕРЬ ВСЕ ВАШИ ПРЕДЛОЖЕНИЯ БУДУТ ОТОБРАЖАТЬСЯ НА
                      ГЛАВНОЙ СТРАНИЦЕ, ТАМ ВЫ СМОЖЕТЕ ОТСЛЕЖИВАТЬ СТАТУСЫ И
                      ОТКЛИКИ ОТ БЛОГЕРОВ
                    </p>

                    <h3
                      style={{ fontSize: '20px', marginTop: '48px' }}
                      id="offerpersonal"
                    >
                      Оперативно выбрать блогера и опубликовать рекламу
                    </h3>

                    <p>
                      Если Вы хотите оперативно заказать рекламу у конкретного
                      блогера или посмотреть список наших блогеров, то следует
                      нажать на «найти блогера»
                      <br />
                      Если Вы приняли решение оперативно заказать рекламу у
                      конкретного блогера или посмотреть список наших блогеров,
                      то сейчас мы Вам расскажем, как это правильно сделать:
                    </p>
                    <img src={'https://i.imgur.com/lFBA9dy.png'} />

                    <ol>
                      <li>
                        Слева расположен фильтр – при помощи его вы можете
                        подобрать блогера, который идеально подойдет вашему
                        бизнесу
                      </li>
                      <li>
                        Для подробного изучения блогера нажмите на его аккаунт
                      </li>
                      <li>
                        {' '}
                        Для заказа рекламы нажмите на кнопку «предложить
                        рекламу»
                      </li>
                      <li>
                        {' '}
                        Сейчас мы Вам расскажем, как правильно сделать рекламное
                        предложение, которое наверняка заинтересует блогера:
                      </li>
                      <li>
                        Делаете краткий заголовок, чтобы блогерам было понятно,
                        что будем рекламировать – (стрелка)
                      </li>
                      <li>
                        {' '}
                        Заполняете техническое задание (все нюансы, подробности
                        о ваших потребителях, креативы и идеи) (если коротко –
                        то, как бы Вы хотели, чтобы выглядела Ваша реклама
                        мечты)/ либо если Вы еще не определились, как должно
                        выглядеть ТЗ, то можете воспользоваться нашей нейросетью
                        – (стрелка)
                      </li>
                      <li>Указываете желаемую дату размещения рекламы </li>
                      <li>
                        Указываете стоимость рекламы, которую вы готовы оплатить
                        ВЫБРАННОМУ БЛОГЕРУ (НО НЕ МЕНЬШЕ МИНИМАЛЬНО УКАЗАННОЙ
                        СТОИМОСТИ){' '}
                      </li>
                      <li>
                        Заполняете ссылку на сайт или социальную сеть
                        рекламируемого продукта
                      </li>
                      <li>
                        При необходимости, подгружаете презентацию, фото или
                        видео-материалы вашего продукта{' '}
                      </li>
                      <li>
                        Вы можете сохранить данное рекламное предложение в
                        качестве шаблона и затем предлагать его другим блогерам
                        без траты времени на повторное заполнение{' '}
                      </li>
                      <li>
                        Ура! Мы сделали это! Осталось самое простое, но очень
                        важное – ОПЛАТА!
                        <br />
                        <br />
                        ОБЯЗАТЕЛЬНО НЕОБХОДИМО ВНЕСТИ ОПЛАТУ В ТЕЧЕНИЕ 15 МИНУТ
                        – БЛОГЕР НЕ ПОЛУЧИТ ВАШЕ ПРЕДЛОЖЕНИЕ ДО ОПЛАТЫ
                      </li>
                      <li>
                        После оплаты Вы можете написать в чат все интересующие
                        вопросы блогеру
                      </li>
                      <li>
                        ВСЕ, ТЕПЕРЬ ВСЕ ВАШИ ПРЕДЛОЖЕНИЯ БУДУТ ОТОБРАЖАТЬСЯ НА
                        ГЛАВНОЙ СТРАНИЦЕ, ТАМ ВЫ СМОЖЕТЕ ОТСЛЕЖИВАТЬ СТАТУСЫ И
                        СООБЩЕНИЯ ОТ БЛОГЕРОВ
                      </li>
                    </ol>

                    <h3
                      style={{ fontSize: '20px', marginTop: '48px' }}
                      id="key"
                    >
                      Узнать о волшебной кнопке, которая все сделает за Вас
                    </h3>

                    <p>
                      Но есть волшебная кнопка, когда Вы нажимаете ее и
                      заполняете контакты– мы все вопросы берем на себя и
                      подбираем Вам рекламу «под ключ»
                    </p>

                    <img src={'https://i.imgur.com/P6cE9o7.png'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

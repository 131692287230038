import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useSearchParams,
  useLocation,
} from 'react-router-dom'

import Dashboard from './pages/dashboard/dashboard'
import Login from './pages/login.tsx'
import Register from './pages/register.tsx'
import ForgotPassword from './pages/forgotPassword.tsx'
import Error404 from './pages/404'
import Bloggers from './pages/dashboard/bloggers'
import Campaigns from './pages/dashboard/campaigns'
import OfferAuctions from './pages/dashboard/offerAuctions'
import Admin from './pages/dashboard/admin'
import AdminBlogger from './pages/admin/admBlogger'
import AdminUser from './pages/admin/admUser'

import User from './pages/user.tsx'
import Chats from './pages/chats'

import './App.css'
import CreateBlogger from './pages/blogger/createBlogger.tsx'
import ViewBlogger from './pages/blogger/viewBlogger'
import Wallet from './pages/wallet'
import Support from './pages/support'

import Referrals from './pages/referrals'

import Links from './pages/dashboard/links'

import AdminEditBlogger from './pages/admin/editBlogger'

import ProtectedRoute from './components/protectedRoute'
import Drafts from './pages/dashboard/drafts'
import CreateDraft from './pages/drafts/createDraft'
import ViewDraft from './pages/drafts/viewDraft'
import CreateDeal from './pages/deals/createDeal'
import ViewDeal from './pages/deals/viewDeal'
import CreateOffer from './pages/offers/createOffer'
import ViewOffer from './pages/offers/viewOffer'

import { ConfirmEmail } from './pages/confirmEmail.tsx'
import NewAuctionCreate from './pages/NewAuction/NewAuctionCreate.tsx'
import NewOfferCreate from './pages/NewOffer/NewOfferCreate.tsx'
import EditBlogger from './pages/blogger/editBlogger.tsx'
import UpdateAuction from './pages/NewAuction/updateAuction.tsx'
import UpdateOffer from './pages/NewOffer/updateOffer.tsx'
import { Legal } from './pages/legal'
import { Toaster } from 'react-hot-toast'
import { Bill } from './pages/bill'
import { UtmProvider } from './components/utmProvider'
import ErrorBoundary from './components/errorBoundary'


function App() {
  return (
    <>
      <Toaster />
      <Router>
        <Routes>
          <Route element={<UtmProvider></UtmProvider>}>
            <Route path="/" element={<ProtectedRoute />}>
              <Route index element={<Dashboard />} />

              <Route path="confirmemail" element={<ConfirmEmail />} />
              <Route path="createinvoice" element={<Bill></Bill>} />

              <Route path="user" element={<User />} />
              <Route path="user/legal" element={<Legal />} />
              <Route path="wallet" element={<Wallet />} />

              <Route path="referrals" element={<Referrals />} />

              <Route path="support" element={<Support />} />

              <Route path="campaigns" element={<Campaigns />} />

              <Route path="offersauctions" element={<OfferAuctions />} />

              <Route
                path="offersauction/create"
                element={<NewAuctionCreate />}
              />
              <Route
                path="offersauction/edit/:id"
                element={<UpdateAuction />}
              />
              <Route
                path="offersauction/:id"
                element={
                  <ErrorBoundary>
                    <ViewOffer />
                  </ErrorBoundary>
                }
              />

              <Route path="chats" element={<Chats />} />

              <Route path="campaign/create/:id" element={<CreateOffer />} />
              <Route path="campaign/create" element={<NewAuctionCreate />} />

              <Route
                path="campaign/create/to/:exclusive"
                element={<CreateOffer />}
              />
              <Route path="campaign/:id" element={<ViewOffer />} />

              <Route path="drafts" element={<Drafts />} />
              <Route path="draft/create" element={<CreateDraft />} />
              <Route path="draft/:id" element={<ViewDraft />} />

              <Route path="offer/to/:id" element={<NewOfferCreate />} />
              <Route path="offer/:id" element={<ViewDeal />} />
              <Route path="offer/edit/:id" element={<UpdateOffer />} />

              <Route path="links" element={<Links />} />

              <Route path="admin" element={<Admin />} />
              <Route path="admin/user/:id" element={<AdminUser />} />
              <Route path="admin/blogger/:id" element={<AdminBlogger />} />

              <Route path="bloggers" element={<Bloggers />} />
              <Route path="blogger/create" element={<CreateBlogger />} />
              <Route path="blogger/edit/:id" element={<EditBlogger />}></Route>
              <Route path="blogger/:id" element={<ViewBlogger />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/*" element={<Error404 />} />
          </Route>
        </Routes>
      </Router>
    </>
  )
}

export default App

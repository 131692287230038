import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './navBarLink.scss'
import { useLocation } from 'react-router-dom'

const NavBarLink = ({ children, svg, to }) => {
  const route = useLocation()

  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(to === route.pathname)
  }, [route.pathname])

  return (
    <Link to={to} className="nav-link new-navbar-link">
      <div
        className={` d-flex align-items-center ${
          active ? 'new-navbar-item--active' : ''
        }`}
      >
        <img src={svg} className="nav-link-icon d-md-none d-lg-inline-block" />
        <span className="nav-link-title new-nav-link-title">{children}</span>
      </div>
    </Link>
  )
}

export default NavBarLink

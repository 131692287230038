import React from 'react'
import cls from './newInput.module.scss'

import InputError from '../inputAlert/inputError'
import {NewInputProps, PriceInputProps} from "../../../types/components";
///import priceInput.module.scss///

const priceInput = ({ register = (_) => {},error,    unit, name, ...props }: PriceInputProps) => {
  return (
    // <label className={cls['input-group'] + ' w-100'}>
    //   <NewInput  containerClass={'w-100'} type='number' {...props}></NewInput>
    //   <span>{unit ? unit : '₽'}</span>
    //
    // </label>

    <>
      <div
        className={cls['input-group'] + ' input-group ' + props.containerClass}
      >
        <input
          type="number"
          className={' form-control'}
          id="basic-url"
          aria-describedby="basic-addon3"
          {...register(name)}
          {...props}
        />
        <span class="input-group-text input_price-unit" id="basic-addon3">
          {unit ? unit : '₽'}
        </span>
      </div>
      {error && <InputError>{error}</InputError>}
    </>
  )
}

export default priceInput

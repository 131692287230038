import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import { useRef, useEffect, useState, useLayoutEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { async } from 'q'
import { getDraftById, updateDraft } from '../../services/offers.service'
import ApplyEdit from '../../components/modals/applyEdit'
import Select from '../../components/UI/newInput/select'
import { getFilesMetadata } from '../../services/file.service'
import FilesUploader from '../../components/UI/FilesUploader/filesUploader'

const ViewDraft = () => {
  let { id } = useParams()
  const [draft, setDraft] = useState({})

  const [applyVisible, setApplyVisible] = useState(false)

  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [platform, setPlatform] = useState()
  const [url, setUrl] = useState()
  const [geo, setGeo] = useState()
  const [files, setFiles] = useState()
  const [filesIds, setFilesIds] = useState()

  const [edit, setEdit] = useState(false)

  const platforms = [
    { value: 'vk', label: 'VK' },
    { value: 'instagram', label: 'Instagram*' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'dzen', label: 'Дзен' },
    { value: 'odnoklassniki', label: 'Одноклассники' },
  ]

  const platformLabels = {
    vk: 'VK',
    instagram: 'Instagram*',
    youtube: 'YouTube',
    telegram: 'Telegram',
    dzen: 'Дзен',
    odnoklassniki: 'Одноклассники',
  }

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
      return
    }
    setApplyVisible(true)
  }

  useLayoutEffect(() => {
    getDraftById(id)
      .then(data => {
        setDraft(data)

        setTitle(data.title)
        setPlatform(data.platform)
        setDescription(data.description)
        setUrl(data.url)
        setGeo(data.geo)
        console.log('files', data.files)
        data.files.lenght > 0 &&
          getFilesMetadata(data.files.join(',')).then(files => {
            setFiles(
              files.sort((a, b) =>
                a.ContentType.split('/')[0] == 'image' ? 1 : -1
              )
            )
            setFilesIds(files.map(e => e.id))
          })
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    console.log(files)
  }, [files])

  return (
    <div className="page">
      <Navbar drafts />
      <div className="page-wrapper">
        <PageHeader title="Шаблон">
          <div className="btn-list">
            <button
              onClick={handleEdit}
              className="btn btn-primary d-sm-inline-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              {edit ? 'Сохранить' : 'Редактировать'}
            </button>
          </div>
        </PageHeader>

        <div className="page-body">
          <div className="container-xl">
            <div className="card">
              <div className="card-body col-md-3">
                <div className="mb-3">
                  <label className="form-label">Название: </label>
                  <input
                    className="form-control"
                    defaultValue={title ?? ''}
                    disabled={!edit}
                    onChange={e => setTitle(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Платформа: </label>
                  {edit ? (
                    <Select
                      placeholder="Выберите..."
                      options={platforms}
                      disabled={!edit}
                      value={platform}
                      onChange={choice => setPlatform(choice.value)}
                    />
                  ) : (
                    <input
                      className="form-control"
                      defaultValue={platform ?? ''}
                      disabled={!edit}
                    />
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Описание: </label>
                  <input
                    className="form-control"
                    defaultValue={description ?? ''}
                    disabled={!edit}
                    onChange={e => setDescription(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Ссылка: </label>
                  <input
                    className="form-control"
                    defaultValue={url ?? ''}
                    disabled={!edit}
                    onChange={e => setUrl(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Локация: </label>
                  <input
                    className="form-control"
                    defaultValue={geo ?? ''}
                    disabled={!edit}
                    onChange={e => setGeo(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Файлы: </label>
                </div>
              </div>

              <div
                style={{ margin: '0 auto' }}
                className="m-3 d-flex flex-wrap"
              >
                {/* 
                                    Как работает сейчас: Добавляет новые файлы к уже существующим
                                    Можно сделать: Удаление всех файлов и загрузка новых
                                */}
                {edit ? (
                  <FilesUploader setFiles={setFilesIds} page="draft" />
                ) : (
                  files?.map(file => {
                    const src =
                      process.env.REACT_APP_BACKEND_API + 'file/' + file.id
                    return (
                      <a
                        className="btn m-1 w-25 text-wrap"
                        key={file.id}
                        href={src}
                        type={file.ContentType}
                        target="_blank" rel="noreferrer"
                      >
                        {file.ContentType.split('/')[0] == 'image' ? (
                          <img className="w-8" src={src} />
                        ) : (
                          decodeURI(file.Metadata.originalname)
                        )}
                      </a>
                    )
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyEdit
        show={applyVisible}
        onHide={() => {
          setApplyVisible(false)
          window.location.reload()
        }}
        onApply={() => {
          updateDraft(id, {
            title,
            platform,
            description,
            link: url,
            geo,
            files: filesIds,
          })
          window.location.reload()
        }}
      />
    </div>
  )
}

export default ViewDraft

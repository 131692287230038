import React from 'react'
import cls from './inputAlert.module.scss'

const InputSuccess = ({ children, ...props }) => {
  return (
    <div {...props} className={cls['input__alert--success']} role="alert">
      {children}
    </div>
  )
}

export default InputSuccess

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import Select from 'react-select'

import { _ } from 'gridjs-react'
import { useRef, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import AuthService from '../../services/auth.service'
import { ExternalLink } from 'tabler-icons-react'

import axios from 'axios'
import DraftCard from '../../components/draftCard'
import { getDrafts } from '../../services/offers.service'
import { NotFoundMessage } from '../../components/notFoundMessage'

const Drafts = () => {
  const [drafts, setDrafts] = useState([])

  const [campaignPrice, setCampaignPrice] = useState('')

  const [theme, setTheme] = useState([])

  const handleMultiSelect = chices => {
    let a = []
    chices.map(({ value, name }) => {
      a.push(value)
    })

    console.log(a)
    setTheme(a)
  }

  useEffect(() => {
    getDrafts().then(data => setDrafts(data))
  }, [])

  useEffect(() => {
    console.log(drafts)
  }, [drafts])

  return (
    <div className="page">
      <Navbar drafts></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Рекламные шаблоны">
          <div className="btn-list">
            <Link
              to="/draft/create"
              className={'btn btn-primary d-sm-inline-block'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Создать шаблон
            </Link>
          </div>
        </PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="row g-4">
              <div className="col-md-3">
                <form
                  action="#"
                  method="get"
                  autoComplete="off"
                  noValidate=""
                  className="sticky-top"
                  onSubmit={''}
                >
                  {/*<div className="mt-4">
                                        <button className="btn btn-primary w-100" >
                                            Применить
                                        </button>
                                        <a href="#" className="btn btn-link w-100" onClick={""}>
                                            Сбросить
                                        </a>
                                    </div>*/}
                </form>
              </div>

              <div className="col-md-9">
                {drafts.length > 0 ? (
                  drafts.map((card, i) => (
                    <DraftCard key={card._id} i={i} card={card} />
                  ))
                ) : (
                  <NotFoundMessage>
                    У вас нет созданных рекламных шаблонов
                  </NotFoundMessage>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drafts

import React from 'react'
import cls from './bordered.module.scss'

const bordered = props => {
  return (
    <div
      {...props}
      className={
        (props.thick ? cls['bordered-thick'] : cls.bordered) +
        ' ' +
        props.className
      }
    >
      {props.children}
    </div>
  )
}

export default bordered

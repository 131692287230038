import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import styles from './viewBlogger.module.scss'
import './viewBlogger.scss'

import AuthService from '../../services/auth.service'
import OfferCondition from '../../components/UI/offerConditions/offerCondition'
import Button from '../../components/UI/button/button'
import Bordered from '../../components/UI/bordered/bordered'
import BloggerStatistics from '../../components/UI/bloggerStatistics/bloggerStatistics'
import FollowersIcon from '../../components/UI/icons/blogger/followersIcon'
import ERIcon from '../../components/UI/icons/blogger/ERIcon'
import AudienceIcon from '../../components/UI/icons/blogger/audienceIcon'

import SimilarBloggers from '../../components/bloggerPage/similarBloggers'
import OtherSocial from '../../components/bloggerPage/otherSocials'
import DayStatistics from '../../components/bloggerPage/dayStatistics'
import LastPosts from '../../components/bloggerPage/lastPosts'
import Tags from '../../components/bloggerPage/tags'
import AgeGenderStats from '../../components/bloggerPage/ageGenderStats'
import Chart from 'react-apexcharts'

import {
  addSpacesToNumber,
  formatNumber,
  parseActivitiesData,
} from '../../utils/functional'
import BubbleMatrixChart from '../../components/bubbleMatrixChart/bubbleMatrixChart'
import { jagajamActivity } from '../../assets/testStatistics'
import authService from '../../services/auth.service'
import QualityIcon from '../../components/UI/icons/blogger/qualityIcon'
import HeartIcon from '../../components/UI/icons/heartIcon'

import aiIcon from '../../assets/ai_icon.svg'
import heartBorderIcon from '../../assets/icons/heart_border.svg'
import eyeIcon from '../../assets/icons/eye_purple.svg'
import userPurpleIcon from '../../assets/icons/userPurple.svg'
import arrowIcon from '../../assets/icons/arrow.svg'
import editIcon from '../../assets/icons/edit.svg'

import Slider from '../../components/UI/slider/slider'
import { translateSocials } from '../../utils/translate'

export default function ViewBlogger() {
  const navigate = useNavigate()

  let { id } = useParams()

  const [blogger, setBlogger] = useState(null)
  const [aiDescr, setAiDescr] = useState()
  const [statistics, setStatistics] = useState({})
  const [subscribersStatistic, setSubscribersStatistic] = useState([])

  const [myAcc, setMyAcc] = useState(false)
  const [days, setDays] = useState(10)

  const getER = () => {
    return String(
      statistics.avgER
        ? Math.round(statistics.avgER * 10000) / 100 + '%'
        : blogger.storiesCoverage
        ? blogger.storiesCoverage
        : 0
    )
  }

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(process.env.REACT_APP_BACKEND_API + `bloggers/${id}`, {
          withCredentials: true,
        })
        .then(res => {
          setBlogger(res.data)

          if (
            !res.data.aiDescrFetch ||
            res.data.aiDescrFetch < Date.now() - 24 * 60 * 60 * 1000
          ) {
            console.log('no ai')

            axios
              .get(
                process.env.REACT_APP_BACKEND_API + `bloggers/${id}/aidescr`,
                { withCredentials: true }
              )
              .then(res => {
                setAiDescr(res.data)
                console.log('got ai', res.data)
              })
              .catch(error => {
                console.log('ai descr error', error)
              })
          }
        })
        .catch(error => {
          console.log('fetch error', error)
          navigate('/404')
        })
      axios
        .get(process.env.REACT_APP_BACKEND_API + `bloggers/${id}/statistics`, {
          withCredentials: true,
        })
        .then(res => {
          setStatistics(res.data)
          console.log('statistics', res.data)
          setSubscribersStatistic(
            res.data.jagajamRetrospective.series.current.slice(-91).slice(0, -1)
          )
        })
        .catch(error => {
          console.log('fetch stats error', error)
        })
    }
    fetchData()
  }, [id])

  useEffect(() => {
    console.log(blogger)
    console.log(authService.getCurrentUser().id)
    console.log(blogger?.user === authService.getCurrentUser().id)
    setMyAcc(blogger?.user === authService.getCurrentUser().id)
  }, [blogger])

  useEffect(() => {
    console.log('статистика подписчиков', subscribersStatistic)
  }, [subscribersStatistic])

  if (!blogger) return <div>Loading</div>

  return (
    <div className="page">
      <Navbar bloggers></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Блогер" row>
          <div className="btn-list">
            {myAcc && (
              <>
                <Link
                  to={`/blogger/edit/${id}`}
                  className={'btn btn_secondary desktop'}>
                  Редактировать
                </Link>
                <Link to={`/blogger/edit/${id}`} className="mobile">
                  <img src={editIcon} alt="Редактировать" />
                </Link>
              </>
            )}
          </div>
        </PageHeader>

        <div className="page-body blogger">
          <div className="container-xl">
            {!blogger.verified && myAcc && (
              <div
                style={{
                  border: '3px solid #FF5FAB',
                  padding: '1rem',
                  marginBottom: '1rem',
                }}>
                Ваш профиль должен пройти проверку модерацией.
              </div>
            )}

            {!blogger.verified && !myAcc && (
              <div
                style={{
                  border: '3px solid #FF5FAB',
                  padding: '1rem',
                  marginBottom: '1rem',
                }}>
                Этот профиль только что добавлен на платформу и еще не прошел
                проверку.
              </div>
            )}

            {blogger.hidden && myAcc && (
              <div
                style={{
                  border: '3px solid #FF5FAB',
                  padding: '1rem',
                  marginBottom: '1rem',
                }}>
                Профиль скрыт из общей подборки. Возможно, вы не прошли
                модерацию или скрыли профиль самостоятельно.
              </div>
            )}

            {blogger.hidden && !myAcc && (
              <div
                style={{
                  border: '3px solid #FF5FAB',
                  padding: '1rem',
                  marginBottom: '1rem',
                }}>
                Профиль скрыт из общей подборки. Возможно, он не прошел
                модерацию или скрыт владельцем.
              </div>
            )}

            <div className="row row-cards row-deck row-gap-4">
              <div className="col-md-8">
                <div
                  className={
                    'bordered card bg-transparent border-sm-none  h-100 ' +
                    styles.card
                  }>
                  <div className="blogger-page_card-header justify-content-between align-items-center">
                    <div className="d-flex">
                      <h3
                        className="mb-0 f-geometria text-14-bold text-uppercase"
                        style={{
                          borderRight: '1px solid #111111',
                          paddingRight: '1.25rem',
                        }}>
                        {blogger.platform !== 'telegram'
                          ? `@${blogger.username}`
                          : blogger.fullname}{' '}
                        {/* TODO: или тут иконка соц.сети */}
                      </h3>
                      <div className="d-flex">
                        <div
                          style={{ marginLeft: '1.25rem' }}
                          className="text-muted d-flex align-items-center">
                          <img
                            style={{ marginRight: '0.75rem' }}
                            src={heartBorderIcon}
                            alt=""
                          />
                          {
                            //TODO (no data)
                          }
                        </div>
                        <div
                          style={{ marginLeft: '1.25rem' }}
                          className="text-muted d-flex align-items-center">
                          <img
                            style={{ marginRight: '0.75rem' }}
                            src={eyeIcon}
                            alt=""
                          />
                          {
                            //TODO (no data)
                          }
                        </div>
                      </div>
                    </div>
                    <div className="blogger__themes pb-2 flex-wrap justify-content-end align-items-center d-flex column-gap-3 text-end border-b_purple border-sm-none">
                      {blogger.themes.map((e, i) => (
                        <span key={i}>
                          <svg
                            className="h-100 me-1"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="10" height="10" fill="#FF5FBF" />
                          </svg>
                          <span className="text-12-bold f-geometria text-uppercase text--gradient">
                            {e}
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="card-body d-flex justify-content-between column">
                    <div className="row">
                      <div className="col-4 col-sm-3 mb-3">
                        <img
                          className="border_purple_bold"
                          src={`${
                            statistics?.image
                              ? statistics.image
                              : blogger.avatarFile
                              ? process.env.REACT_APP_BACKEND_API +
                                'file/' +
                                blogger.avatarFile
                              : blogger.avatar
                              ? blogger.avatar
                              : '/avatar.png'
                          }`}
                          alt="Blogger avatar"
                        />
                      </div>
                      <div className="col">
                        <span className="text--disabled">
                          {translateSocials(blogger.platform)}
                        </span>
                        <div
                          className="text-muted small desktop"
                          dangerouslySetInnerHTML={{
                            __html: blogger.description,
                          }}
                        />
                        <br />
                      </div>
                      <div
                        className="text-muted small mb-5 mobile"
                        dangerouslySetInnerHTML={{
                          __html: blogger.description,
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center gap-1">
                      <a
                        href={blogger.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ minWidth: '280px' }}
                        className="btn btn_secondary text-14-bold">
                        <img
                          style={{ height: '13px' }}
                          className="ms-1"
                          src={arrowIcon}
                          alt=""
                        />
                        <span className="mx-auto">Страница Блогера</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogger__price bordered w-100 border-sm-none">
                  <div
                    className={
                      ' h-100 bg-transparent card p-3 border-sm-none ' +
                      styles.card
                    }>
                    <div className="d-flex justify-content-center">
                      <h3 className="text-uppercase f-geometria">
                        Варианты размещения рекламы
                      </h3>
                    </div>

                    <div>
                      <div className="mb-3">
                        <OfferCondition
                          value={
                            blogger?.pricing?.story
                              ? addSpacesToNumber(blogger.pricing.story) + ' ₽'
                              : ''
                          }
                          keyWord={
                            blogger.platform != 'telegram'
                              ? 'История'
                              : 'Репост'
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <OfferCondition
                          value={
                            blogger?.pricing?.post
                              ? addSpacesToNumber(blogger.pricing.post) + ' ₽'
                              : ''
                          }
                          keyWord={
                            blogger.platform != 'telegram'
                              ? 'Публикация'
                              : 'Публикация'
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <OfferCondition
                          value={
                            blogger?.pricing?.advLayout
                              ? addSpacesToNumber(blogger.pricing.advLayout) +
                                ' ₽'
                              : ''
                          }
                          keyWord={'Рилс'}
                        />
                      </div>

                      <div>
                        <div className="d-flex column align-items-center">
                          <Button className="btn btn_primary px-6">
                            <Link
                              to={`/offer/to/${blogger._id}`}
                              className="fw-bold text-white text-decoration-none">
                              Заказать рекламу
                            </Link>
                          </Button>
                          {blogger.external && (
                            <div className="text-muted mt-2">
                              Сделку будет сопровождать наш менеджер
                              {/* Тут мы пишем что блогер не зареган на нашей платформе напрямую */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <BloggerStatistics
                primary
                icon={<FollowersIcon />}
                title="Подписчики"
                value={
                  statistics.usersCount
                    ? statistics.usersCount
                    : blogger.followers
                }
              />

              <BloggerStatistics
                icon={<QualityIcon />}
                title={'индекс качества'}
                value={
                  statistics?.qualityScore
                    ? Math.round(statistics?.qualityScore * 100) + '%'
                    : ''
                }
              />

              <BloggerStatistics
                primary
                icon={<HeartIcon />}
                title={'РЕАКЦИИ НА ПОСТ'}
                value={statistics?.avgInteractions}
              />

              <BloggerStatistics
                icon={<ERIcon />}
                title={'вовлеченность (ER)'}
                value={getER()}
              />

              <div className="col-md-8">
                <div className="card border_purple">
                  <div className="row row-0">
                    <div
                      className="desktop col-2 column justify-content-center border-r_purple position-relative"
                      style={{ padding: '2rem' }}>
                      <img src={aiIcon} alt="" />
                      <span
                        className="text-gradient f-geometria"
                        style={{
                          position: 'absolute',
                          right: '1rem',
                          bottom: '1rem',
                        }}>
                        ИИ
                      </span>
                    </div>
                    <div className="col">
                      <div className="card-body">
                        <h3 className="card-title text-uppercase">
                          оценка профиля от нейросети
                        </h3>
                        <p>
                          {blogger.aiDescr ||
                            aiDescr ||
                            'Описание генерируется...'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card bordered blogger__price border-sm-none">
                  <div className="card-body">
                    <h3 className="card-title text-uppercase text-center text-sm-start text-14-regular">
                      отзывы пользователей
                    </h3>
                    <div className=" d-flex justify-content-center">
                      <span className="text-gradient f-geometria text-center display-6">
                        {blogger.reviewScore > 0
                          ? blogger.reviewScore + '/5'
                          : 'Отзывов пока нет'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div
                  style={{ border: 'none' }}
                  className="card justify-content-start">
                  <h3 className="text-uppercase f-geometria text-14-regular text-center">
                    <span className="purple">@{blogger.username} </span>в других
                    социальных сетях
                  </h3>
                  <div>
                    {blogger.linkedProfiles.map(e => (
                      <div
                        key={e._id}
                        style={{ marginTop: '0.75rem', padding: '0.75rem 0' }}
                        className="border_purple text-center">
                        <Link
                          style={{ color: '#111111' }}
                          to={`/blogger/${e._id}`}>
                          {translateSocials(e.platform)}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-6 column">
                <div
                  style={{ border: 'none' }}
                  className="card justify-content-between">
                  <h3 className="text-uppercase f-geometria text-14-regular text-center">
                    вам могут подойти
                  </h3>
                  <div className="blogger__similar">
                    {statistics?.jagajamData?.similar && (
                      <Slider
                        slidesPerView={window.innerWidth > 1200 ? 3 : 2}
                        spaceBetween={window.innerWidth > 567 ? 16 : 8}>
                        {statistics.jagajamData.similar.map(e => (
                          <div className="similar-card border_purple">
                            <div>
                              <div
                                className="avatar w-100"
                                style={{
                                  backgroundImage: `url(${e.image})`,
                                  height: '126px',
                                  borderRadius: 0,
                                }}></div>
                            </div>
                            <div className="similar-card__body column">
                              <span
                                title={e.name}
                                className="text-uppercase f-geometria text-14-bold text-truncate purple">
                                @{e.name}
                              </span>
                              <span className="mt-3 text-muted text-12-regular">
                                Подписчики:
                              </span>
                              <span className="purple mt-2 text-12-bold d-flex align-items-center justify-content-center">
                                <img
                                  className="me-2"
                                  style={{ width: '10px' }}
                                  src={userPurpleIcon}
                                />
                                {addSpacesToNumber(e.usersCount)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
              {/*
                                <BloggerStatistics
                                    primary
                                    icon={<AudienceIcon />}
                                    title={'Аудитория'}
                                    value={
                                        <div className='d-flex column'>
                                            <span>{blogger.age ? blogger.age + 'лет' : ''}</span>
                                            <span className='fs-4'>
                                                Качество: {Math.round(statistics.qualityScore ? statistics.qualityScore * 100 : 0)} баллов
                                            </span>
                                        </div>
                                    }
                                />
                            */}

              {/*<div className="col-md-12">
                                <div className={"card "+styles.card }>
                                    <div className="card-body d-flex justify-content-evenly">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2 icon icon-tabler icon-tabler-map-pin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#206bc4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                            </svg>
                                            {blogger.location}
                                        </div>
                                        <div>
                                            тип аккаунта: {blogger.acctype} {"// не сохраняется //"}
                                        </div>
                                    </div>
                                </div>
                            </div>*/}

              {/* */}
              {statistics &&
                statistics?.jagajamData?.communityStatus == 'COLLECTING' && (
                  <div
                    style={{
                      border: '1px solid #FF5FAB',
                      padding: '1rem',
                      marginBottom: '1rem',
                      marginTop: '1rem',
                    }}>
                    Статистика для этого профиля собирается. Через несколько
                    дней тут будет подробный отчет.
                  </div>
                )}

              {/*<div className='row mt-5 mb-5'>*/}
              {/*<OtherSocial socials={[{*/}
              {/*    socName: 'Telegram',*/}
              {/*    socLink: 'https://t.me/' + blogger.username,*/}
              {/*},*/}
              {/*    {*/}
              {/*        socName: 'Instagram',*/}
              {/*        socLink: 'https://instagram.com/' + blogger.username,*/}
              {/*    },*/}
              {/*    {*/}
              {/*        socName: 'TikTok',*/}
              {/*        socLink: 'https://tiktok.com/@' + blogger.username*/}
              {/*    }*/}
              {/*]}></OtherSocial>*/}
              {/*<SimilarBloggers data={statistics.jagajamData.similar}></SimilarBloggers>*/}
              {/*</div>*/}

              {/*<BubbleMatrixChart rows={jagajamActivity}></BubbleMatrixChart>*/}
              {statistics &&
                statistics.jagajamActivity?.length > 0 &&
                statistics.jagajamActivity.some(e => e.interactions > 0) && (
                  <BubbleMatrixChart rows={statistics?.jagajamActivity} />
                )}

              {/*
                                Age:
                                    {statistics?.jagajamData?.membersGendersAges?.data?.length > 0 ? <AgeGenderStats ages={statistics.jagajamData.membersGendersAges.data} /> : null}
                                Days stats:
                                    {statistics?.jagajamRetrospective?.series?.current ? <DayStatistics dayStats={statistics.jagajamRetrospective.series.current}></DayStatistics> : null}
                                */}

              {subscribersStatistic && subscribersStatistic.length == 90 && (
                <div
                  style={{ marginTop: '2.5rem' }}
                  className="col-12 row row-gap-3 text-center justify-content-center">
                  <div>
                    <h3>
                      <span className="text-uppercase f-geometria text-gradient">
                        Количество подписчиков
                      </span>
                    </h3>
                    <h4 className="text-14-regular">
                      На графике показано изменение количества подписчиков за
                      последние 3 месяца и до 30 самых значимых постов{' '}
                    </h4>
                  </div>

                  <div>
                    <Chart
                      height={340}
                      type="area"
                      width="100%"
                      options={{
                        chart: {
                          fontFamily: 'inherit',
                          height: 240,
                          parentHeightOffset: 0,
                          toolbar: {
                            show: false,
                          },
                          animations: {
                            enabled: false,
                          },
                        },

                        fill: {
                          type: 'solid',
                          opacity: 1,
                          colors: ['rgba(81, 92, 209, 0.15)'],
                        },
                        stroke: {
                          width: 2,
                          lineCap: 'round',
                          curve: 'straight',
                          colors: ['#515CD1'],
                        },
                        colors: ['#515CD1'],
                        tooltip: {
                          theme: 'light',
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        grid: {
                          padding: {
                            top: -20,
                            right: 0,
                            left: -4,
                            bottom: -4,
                          },
                          strokeDashArray: 4,
                        },
                        xaxis: {
                          labels: {
                            enabled: false,
                            padding: 0,
                          },
                          tooltip: {
                            enabled: false,
                          },
                          //hideOverlappingLabels: true,
                          type: 'datetime',
                          rotate: 0,
                        },
                        yaxis: {
                          labels: {
                            padding: 4,
                            hideOverlappingLabels: true,
                          },
                        },
                        labels: subscribersStatistic.map(obj => {
                          return new Date(
                            obj.date.substring(6, 10),
                            obj.date.substring(3, 5),
                            obj.date.substring(0, 2)
                          )
                            .toISOString()
                            .split('T')[0]
                        }),
                      }}
                      series={[
                        {
                          name: 'Подписчики',
                          data: subscribersStatistic.map(obj => obj.usersCount),
                        },
                      ]}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-12 flex-column justify-content-center align-items-center">
                <h3 className="text-uppercase f-geometria text-center">
                  отзывы
                </h3>
                <div>
                  {blogger.reviews.length > 0 ? (
                    blogger.reviews.map(e => <div></div>)
                  ) : (
                    <div>Отзывов пока нет</div>
                  )}
                </div>
              </div>

              {/*
                            <div className="row row-gap-3">
                                <h3>Время активности аудитории</h3>
                            
                                    </div>*/}

              {/* Latest posts */}
              {
                //statistics.jagajamData && statistics.jagajamData.lastPosts.length > 0 &&
                //  <LastPosts posts={statistics.jagajamData.lastPosts} />
              }

              {
                //statistics?.jagajamData?.ratingTags && statistics.jagajamData.ratingTags.length > 0 ?
                //      <Tags tags={statistics.jagajamData.ratingTags} />
                //      :
                //       null
              }
              <div className=" mt-4">
                {/*JSON.stringify(blogger)*/}
                {blogger.screenshots.forEach(file => {
                  console.log(file)
                  let src = process.env.REACT_APP_BACKEND_API + 'file/' + file
                  return (
                    <p key={file} src={src}>
                      {file}
                    </p>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
///import ERIcon.module.scss///

const ERIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
    >
      <path
        d="M2.5 15H0V9H2.5V15ZM9.5 15H7V6H9.5V15ZM13 15H10.5V2.5H13V15ZM6 15H3.5V0H6V15Z"
        fill="white"
      />
    </svg>
  )
}

export default ERIcon

import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import cls from './modals.module.scss'
import FilesUploader from '../UI/FilesUploader/filesUploader'
import NewInput from '../UI/newInput/newInput.tsx'
import { object, string } from 'yup'
import isURL from 'validator/es/lib/isURL'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useField from '../../hooks/useField'
import { BackArrowButton } from './backArrowButton'

const FinishOffer = ({ show, onHide, onApply }) => {
  const finishOfferSchema = object().shape({
    message: string().required('Обязательное поле'),
    linkToResult: string()
      .required('Обязательное поле')
      .test('validUrl', value => {
        return isURL(value)
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    defaultValues: {
      slug: (Math.random() + 1).toString(36).substring(7),
    },
    resolver: yupResolver(finishOfferSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const [files, setFiles] = useState([])

  const handleApply = ({ message, linkToResult }) => {
    onApply({ message, linkToResult, files }).then(() => {
      onHide()
    })
  }

  return (
    <div
      className={
        'modal modal-blur fade ' + cls.modal + ' ' + (show ? 'show' : '')
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={'bordered bordered--thick modal-content'}>
          <form onSubmit={handleSubmit(handleApply)} className="">
            <div className="">
              <h5
                className={'w-100  modal_title text--page-title ' + cls.title}
              >
                <button type='button' onClick={onHide}>
                  <BackArrowButton />
                </button>
                Завершение оффера
              </h5>
              <div className="mb-3">
                <NewInput
                  type="text"
                  className="form-control"
                  placeholder="Сообщение"
                  {...getFieldProps('message')}
                />
              </div>
              <div className="mb-5">
                <NewInput
                  type="text"
                  className="form-control"
                  placeholder="Ссылка на результат"
                  {...getFieldProps('linkToResult')}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn_primary"
                  disabled={!isValid}
                  data-bs-dismiss="modal"
                  type="submit"
                >
                  Отправить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FinishOffer

import React, { ComponentProps } from 'react'
import cls from './newInput.module.scss'
import InputError from '../inputAlert/inputError'
import {FieldPath, UseFormRegister} from 'react-hook-form'
import { InputProps } from 'react-select'
import {NewInputProps} from "../../../types/components";


const NewInput: React.FC<NewInputProps> = ({
  name,
  error,
  type,
  className,
  register = (_) => {},
  ...props
}: NewInputProps) => {
  return (
    <>
      <input
        type={type ? type : 'text'}
        className={`${cls.input} form-control ` + className}
        {...register(name)}
        {...props}></input>
      {error ? <InputError>{error}</InputError> : null}
    </>
  )
}

export default NewInput

import React from 'react'
import { useNavigate } from 'react-router'

import Navbar from '../components/UI/navBar/navbar'

import homeIcon from '../assets/icons/home.svg'
import Button from '../components/UI/button/button'

const Error404 = () => {
  const navigate = useNavigate()

  return (
    <div className="page">
      <Navbar />
      <div className="page-wrapper">
        <div className="page-body">
          <div className="column align-items-center mt-6 px-2">
            <span className="f-geometria text-uppercase text-gradient fs-1">
              упс, ошибка
            </span>
            <span
              style={{ fontSize: '5rem' }}
              className="f-geometria text-uppercase text-gradient"
            >
              404
            </span>
            <span className="mt-2 text-center fs-1">
              Запрашиваемая страница не найдена <br />
              Но не отчаивайтесь, вы можете поискать еще
            </span>
            <Button
              onClick={() => navigate('/')}
              style={{ minWidth: '280px' }}
              className="btn btn_secondary text-14-bold mt-6"
            >
              <img
                style={{ height: '13px' }}
                className="ms-1"
                src={homeIcon}
                alt=""
              />
              <span className="mx-auto">На главную</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404

import PageHeader from '../components/pageHeader/page-header'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Button from '../components/UI/button/button'
import React, { useEffect, useState } from 'react'
import NewInput from '../components/UI/newInput/newInput.tsx'
import { required, validEmail, validINN } from '../utils/validation'
import { isEmail } from 'validator'
import {
  getDataArr,
  parseFormObj,
  setKeyError,
  setKeyValue,
  validateFormObj,
} from '../utils/formData'
import Navbar from '../components/UI/navBar/navbar'
import axios from 'axios'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { bloggerSchema, invoiceSchema } from '../utils/schemas'
import useFormField from '../hooks/useField'
import MyPhoneInputDub from '../components/UI/newInput/MyPhoneInputDub.tsx'

export const Bill = () => {
  const [inParams, setInParams] = useSearchParams()
  console.log(inParams)

  const [params, setParams] = useState({
    amount: inParams.get('amount'),
    orderId: inParams.get('orderId'),
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({ resolver: yupResolver(invoiceSchema) })

  const getFieldProps = useFormField(register, trigger, errors)

  const [showMessage, setShowMessage] = useState(false)
  const [billId, setBillId] = useState('')
  const [invoiceData, setInvoiceData] = useState()

  const submit = data => {
    const invoice = {
      amount: params.amount,
      orderId: params.orderId,
      invoiceData: { ...data, number: data.number.value },
    }
    axios
      .post(
        process.env.REACT_APP_BACKEND_API + 'wallet/createinvoice',
        invoice,
        { withCredentials: true }
      )
      .then(res => {
        console.log(res)
        setBillId(res.data.tr._id)
        setInvoiceData(res.data.tr)
        //window.location.href = '/wallet'
        window
          .open(
            process.env.REACT_APP_BACKEND_API +
              'wallet/downloadbill/' +
              res.data.tr._id,
            '_blank'
          )
          .focus()
        setShowMessage(true)
      })
  }

  return (
    <div className="page">
      <Navbar home></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Выставление счета"></PageHeader>
        <div className="page-body">
          {showMessage && (
            <div className="container-xl justify-content-center text-center">
              <b>Счет №{moment(invoiceData.createdAt).unix()} успешно создан</b>
              <br />
              <p>
                Скачайте файл по кнопке ниже и оплатите. Средства будут
                зачислены в течение двух рабочих дней.{' '}
              </p>
              <br />
              <a
                href={
                  process.env.REACT_APP_BACKEND_API +
                  'wallet/downloadbill/' +
                  billId
                }
              >
                Скачать bill.docx
              </a>
            </div>
          )}
          {!showMessage && (
            <div className="container-xl justify-content-center d-flex">
              <form onSubmit={handleSubmit(submit)} className="col-md-8">
                <h1 className="m-0 mb-4">
                  <span className="text--gradient text-uppercase fw-bold f-geometria">
                    Реквизиты юр.лица
                  </span>
                </h1>

                <div className="mb-3">
                  <NewInput
                    placeholder={'Наименование юр.лица'}
                    {...getFieldProps('payer')}
                  />
                </div>
                <div className="mb-3">
                  <NewInput
                    placeholder={'Юридический адрес'}
                    {...getFieldProps('legalAddress')}
                  />
                </div>
                <div className="mb-3">
                  <NewInput
                    placeholder={'Физический адрес'}
                    {...getFieldProps('physicalAddress')}
                  />
                </div>
                <div className="mb-3">
                  <NewInput placeholder={'ИНН'} {...getFieldProps('inn')} />
                </div>
                <div className="mb-4">
                  <NewInput placeholder={'КПП'} {...getFieldProps('kpp')} />
                </div>

                <h1 className="m-0 mb-4">
                  <span className="text--gradient text-uppercase fw-bold f-geometria">
                    Банковские реквизиты
                  </span>
                </h1>
                <div className="mb-3">
                  <NewInput
                    placeholder={'Расчетный счет'}
                    {...getFieldProps('rascSchet')}
                  />
                </div>
                <div className="mb-3">
                  <NewInput
                    placeholder={'Банк'}
                    {...getFieldProps('bank')}
                  ></NewInput>
                </div>
                <div className="mb-4">
                  <NewInput
                    placeholder={'Корреспондентский счет'}
                    {...getFieldProps('korrSchet')}
                  />
                </div>
                <div className="mb-3">
                  <NewInput placeholder={'БИК'} {...getFieldProps('bik')} />
                </div>

                <h1 className="m-0 mb-4">
                  <span className="text--gradient text-uppercase fw-bold f-geometria">
                    Контактная информация
                  </span>
                </h1>

                <div className="mb-3">
                  <MyPhoneInputDub
                    placeholder={'Контактный телефон'}
                    value={watch('number')?.value}
                    onChange={(number, numberData) => {
                      setValue('number', {
                        value: number,
                        format: numberData.format,
                      })
                      trigger('number')
                    }}
                    {...getFieldProps('number')}
                  />
                </div>
                <div className="mb-40">
                  <NewInput placeholder={'Email'} {...getFieldProps('email')} />
                </div>
                <div className="h-line_2px background-purple mb-40"></div>
                <div className="d-flex justify-content-between mb-40">
                  <span className="f-geometria">Сумма к оплате</span>
                  <span>
                    <span className="f-geometria text-25">
                      {parseInt(params.amount)}
                    </span>
                    <span className="f-geometria text-20">₽</span>
                  </span>
                </div>
                <div className="justify-content-center d-flex">
                  <Button disabled={!isValid} className="btn btn_primary w-240">
                    Выставить счет
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import PageHeader from '../pageHeader/page-header'
import TextArea from '../UI/newInput/textArea'
import Button from '../UI/button/button'
import DateInput from '../UI/newInput/dateInput'
import { getBloggers } from '../../services/blogger.service'
import MySelect, { ControlledSelect } from '../UI/newInput/select'
import PriceInput from '../UI/newInput/priceInput'
import cls from './modals.module.scss'
import { BackArrowButton } from './backArrowButton'
import {
  notEmptyArray,
  notNegative,
  notZero,
  required,
  validateField,
  validDeadline,
} from '../../utils/validation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { auctionSchema } from '../../utils/schemas'
import useField from '../../hooks/useField'
import { date, number, string, object, mixed } from 'yup'

const PlaceOfferResponse = ({ show, onHide, onApply }) => {
  const placeOfferResponseSchema = object().shape({
    blogger: mixed(),
    message: string().required('Обязательное поле'),
    price: number()
      .typeError('Должно быть числом')
      .required('Обязательное поле')
      .min(0, 'Обязательное поле'),
    finishDeadline: date()
      .typeError('Введите дату')
      .test(
        'validDeadline',
        'Дедлайн не может быть раньше чем через три дня с этого момента',
        value => {
          return validDeadline(value)
        }
      ),
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({ resolver: yupResolver(placeOfferResponseSchema) })

  const getFieldProps = useField(register, trigger, errors)
  const [bloggers, setBloggers] = useState([])

  useEffect(() => {
    getBloggers('my').then(data => {
      setBloggers(data)
    })
  }, [show])

  const handleApply = ({ blogger, message, price, finishDeadline }) => {
    onApply({ blogger: blogger.value, message, price, finishDeadline })
  }

  console.log(watch())

  return (
    <div
      className={'modal modal-blur fade' + (show ? 'show ' : ' ') + cls.modal}
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form
          onSubmit={handleSubmit(handleApply)}
          className="modal-content bordered bordered--thick">
          <div className="">
            <div className={'d-flex justify-content-center w-100'}>
              {' '}
              <h2
                className={
                  cls.title + ' modal_title    text-uppercase fw-bolder'
                }>
                <button onClick={onHide}>
                  <BackArrowButton />
                </button>{' '}
                Отклик на предложение
              </h2>
            </div>
          </div>
          <div className="mb-3">
            <MySelect
              type="text"
              placeholder="Ваш аккаунт"
              options={bloggers.map(blogger => {
                return { label: blogger.username, value: blogger._id }
              })}
              value={watch('blogger')}
              onChange={option => {
                setValue('blogger', option)
              }}
              name={'blogger'}
            />
          </div>
          <div className="mb-3">
            <TextArea
              type="text"
              rows="4"
              style={{ height: `${47 * 4}px` }}
              placeholder="Сообщение"
              {...getFieldProps('message')}
            />
          </div>
          <div className="mb-3">
            <PriceInput
              type="number"
              className="form-control"
              placeholder="Ваша цена"
              {...getFieldProps('price')}
            />
          </div>
          <div className="mb-40">
            <DateInput
              error={errors?.finishDeadline?.message}
              label="Дата размещения"
              value={watch('finishDeadline')}
              onChange={e => {
                setValue('finishDeadline', e.target.value)
                trigger('finishDeadline')
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              type="submit"
              disabled={!isValid}
              className="w-240 btn btn_primary"
              data-bs-dismiss="modal">
              Отправить
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PlaceOfferResponse

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import payWidget from '../../services/payWidget'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import chatPlaceholder from '../../assets/title_advertiser.webp'
import cls from './deal.module.scss'

import AuthService from '../../services/auth.service'
import {
  acceptDealBlogger,
  cancelDealAdvertiser,
  declineDealBlogger,
  finishDealAdvertiser,
  finishDealBlogger,
  addStatisticsDealBlogger,
  getDealById,
  getDealOrdMarker,
  getOfferMessages,
  sendOfferMessage,
  declineDealFinishAdvertiser,
} from '../../services/offers.service'
import { getFilesMetadata } from '../../services/file.service'
import FinishOffer from '../../components/modals/finishOffer'
import FinishOfferUploadStatistics from '../../components/modals/finishOfferUploadStatistics'
import Bordered from '../../components/UI/bordered/bordered'
import OfferCondition from '../../components/UI/offerConditions/offerCondition'
import StatusIcon from '../../components/UI/icons/statusIcon/statusIcon'
import { statuses } from '../../utils/translate'
import Button from '../../components/UI/button/button'
import ChatMessage from '../../components/UI/chatMessage/chatMessage'

import NewInput from '../../components/UI/newInput/newInput.tsx'

import { formatDate } from '../../utils/formatters'
import authService from '../../services/auth.service'
import { INNModal } from '../../components/modals/INNModal'
import { toast } from 'react-hot-toast'
import { Toaster } from 'react-hot-toast'
import axios from 'axios'
import { PaymentModal } from '../../components/modals/paymentModal'
import { ConfirmModal } from '../../components/modals/confirmModal'
import { fixUrlProtocol } from '../../utils/functional'

import editIcon from '../../assets/icons/edit.svg'

const ViewDeal = () => {
  let { id } = useParams()
  let navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [ordMarker, setOrdMarker] = useState(null)
  const [finishOfferVisible, setFinishOfferVisible] = useState(false)
  const [addStatisticsVisible, setAddStatisticsVisible] = useState(false)
  const [editFinishOfferVisible, setEditFinishOfferVisible] = useState(false)
  const [myDeal, setMyDeal] = useState(false)
  const [myBlogger, setMyBlogger] = useState(false)
  const [bloggerInORD, setBloggerInORD] = useState(false)
  const [ownerInORD, setOwnerInORD] = useState(false)
  const [ordContent, setOrdContent] = useState(null)
  const [isInn, setIsInn] = useState(false)
  const [deal, setDeal] = useState({})
  const [showINNModal, setShowINNModal] = useState(false)
  const [message, setMessage] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [messages, setMessages] = useState([])
  const [files, setFiles] = useState([])
  const [cancelVisible, setCancelVisible] = useState(false)

  /*
  ORD Logic 
  useEffect(() => {
    setOwnerInORD(deal?.user?.isRegisteredOnORD)
    setBloggerInORD(deal?.blogger?.user?.isRegisteredOnORD)
  }, [deal])

  
  useEffect(() => {
    setOrdContent(getORDMarker())
  }, [ownerInORD, bloggerInORD])

  
  const getORDMarker = () => {
    const registeredInORD = (
      <div className="align-items-start d-flex column">
        <p>
          Мы успешно проверили данные креатива и передали их в ОРД. Рекламный
          маркер:
        </p>
        <p className="fw-bold  text--purple py-1 px-5">{ordMarker}</p>
      </div>
    )
    const notRegisteredInORD = (
      <div className="align-items-start d-flex column">
        <p>
          Для регистрации креатива в ОРД, нам необходима дополнительная
          информация{' '}
        </p>
        <Button onClick={setShowINNModal} className={'btn btn_secondary'}>
          Указать ИНН
        </Button>
      </div>
    )
    const anotherNotRegisteredInORD = (
      <div className="align-items-start d-flex column">
        <p>
          Для регистрации креатива в ОРД, нам необходима дополнительная
          информация{' '}
        </p>
      </div>
    )

    if (myBlogger) {
      if (!bloggerInORD) {
        return notRegisteredInORD
      }
      if (!ownerInORD) {
        return anotherNotRegisteredInORD
      }
    } else if (myDeal) {
      if (!ownerInORD) {
        return notRegisteredInORD
      }
      if (!bloggerInORD) {
        return anotherNotRegisteredInORD
      }
    }
    if (deal.ordMarker) {
      setOrdMarker(deal.ordMarker)
    } else {
      getDealOrdMarker(id)
        .then(data => {
          setOrdMarker(data.ordMarker)
        })
        .catch(e => console.log(e))
    }
    if (!ordMarker) {
      return null
    }
    return registeredInORD
  }
  */

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + `user/profile`, {
        withCredentials: true,
      })
      .then(res => {
        if (res?.data?.legal?.inn) {
          console.log(res?.data?.legal?.inn)
          setIsInn(true)
        }
      })
  }, [])

  const sendMessage = () => {
    sendOfferMessage(id, { message: message }).then(() => {
      setMessage('')
      getOfferMessages(id).then(data => setMessages(data))
    })
  }

  const onCancel = async () => {
    cancelDealAdvertiser(id)
      .then(() => {
        toast.success('Оффер отменен')
        initDeal()
      })
      .catch(() => {
        toast.error('Ошибка')
      })
  }

  useEffect(() => {
    initDeal()
  }, [id])

  const initDeal = () => {
    getDealById(id)
      .then(data => {
        setDeal(data)
        if (!data.offerData || data.offerData.files?.length == 0) return
        getFilesMetadata(
          data.offerData &&
            data.offerData.files &&
            data?.offerData?.files?.join(',')
        )
          .then(files => {
            setFiles(files)
          })
          .catch(e => {
            console.log('files error', e)
          })
      })
      .catch(() => {
        navigate('/404')
      })
      .finally(() => setLoading(false))
    getOfferMessages(id).then(data => setMessages(data))
  }

  const onInnApply = () => {
    initDeal()
    setShowINNModal(false)
  }

  useEffect(() => {
    console.log(deal)
    setMyDeal(deal?.user?._id === authService.getCurrentUser().id)
    setMyBlogger(deal?.blogger?.user?._id === authService.getCurrentUser().id)
  }, [deal])

  useEffect(() => {
    console.log(messages)
  }, [messages])

  /*
    const sendMessage = async () => {
        // Create offer is there is no offer
        console.log(offer.deal)
        //if(!offer.deal.status)
        //    return await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/openDeal`, { offer: id }, { withCredentials: true })

        // If I am not tge owner of the offer, I am the blogger
        if(offer.user._id != AuthService.getCurrentUser().id){
            await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/sendMessage`, { offer: id, to: offer.user._id, deal: offer.deal._id, text: message }, { withCredentials: true }).then((res) => {
                //setDeal(res.data)
            })
        }else{
            await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/sendMessage`, { offer: id, to: offer.deal.blogger.user, deal: offer.deal._id, text: message }, { withCredentials: true }).then((res) => {
                //setDeal(res.data)
            })
        }
        getMessages();
        setMessage("");
    }

    const getMessages = async () => {
        let msgs = await axios.get(process.env.REACT_APP_BACKEND_API + `campaigns/${id}/messages/`, { withCredentials: true })
        setMessages(msgs.data)
    }

    const markComplete = () => {
        axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/complete`, { campaign: id }, { withCredentials: true }).then((res) => {
            //setDeal(res.data)
        })
    }
    */

  return (
    <div className="page">
      {/*Здесь должна быть логика проверки наличия ИНН*/}
      <INNModal
        onApply={() => onInnApply()}
        show={showINNModal}
        onHide={() => {
          setShowINNModal(false)
        }}></INNModal>
      <PaymentModal
        deal={deal}
        show={showPaymentModal}
        onHide={() => {
          setShowPaymentModal(false)
        }}></PaymentModal>
      <ConfirmModal
        show={cancelVisible}
        confirmText={'Вы действительно хотите отменить предложение?'}
        cancelText={'Нет'}
        acceptText={'Да'}
        onApply={apply => {
          if (apply) {
            onCancel().then(navigate('/bloggers'))
          }
          setCancelVisible(false)
        }}></ConfirmModal>
      <Navbar campaigns></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Рекламное предложение" row>
          <div className="btn-list">
            {myDeal && deal?.status === 'pending' && (
              <>
                <Link
                  to={`/offer/edit/${id}`}
                  className={'btn btn_secondary desktop'}>
                  Редактировать
                </Link>
                <Link to={`/offer/edit/${id}`} className="mobile">
                  <img src={editIcon} alt="Редактировать" />
                </Link>
              </>
            )}
          </div>
        </PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="">
              <div className="card-body">
                {loading && <div className="spinner-border" role="status" />}

                {!loading && (
                  <div className="row mb-3 row-gap-3">
                    <div className="col">
                      <div className="bordered h-100 d-flex column justify-content-between">
                        <div className=" col p-5 pb-0 d-flex column justify-content-between">
                          <div className="w-100">
                            <h2 className="f-geometria text-break text-uppercase">
                              {deal.offerData.title}
                            </h2>
                            <p className="mb-3 text-break text-14-regular">
                              {deal.offerData.description}
                            </p>
                          </div>

                          <div
                            className={
                              'd-flex flex-wrap row-gap-3 column-gap-3 mb-7'
                            }>
                            {files &&
                              files?.map(file => {
                                const src =
                                  process.env.REACT_APP_BACKEND_API +
                                  'file/' +
                                  file.id
                                return (
                                  <a
                                    className="d-block h-25 w-25"
                                    key={file.id}
                                    href={src}
                                    type={file.ContentType}
                                    target="_blank"
                                    rel="noreferrer">
                                    {file.ContentType.split('/')[0] ==
                                    'image' ? (
                                      <img
                                        className="offer__thumbnail "
                                        style={{
                                          objectFit: 'contain',
                                          objectPosition: 'left',
                                        }}
                                        src={src}
                                      />
                                    ) : (
                                      decodeURI(file.Metadata.originalname)
                                    )}
                                  </a>
                                )
                              })}
                          </div>
                          {/*<div className={'d-flex' + files.length > 0 ? ' mb-7' : null}>*/}
                          {/*    {files?.map(file => {*/}
                          {/*        const src = process.env.REACT_APP_BACKEND_API + 'file/' + file.id;*/}
                          {/*        return (<a className='d-block h-25 w-25' key={file.id} href={src} type={file.ContentType} target="_blank">*/}
                          {/*            {file.ContentType.split('/')[0] == 'image' ?*/}
                          {/*                <img className='' style={{ objectFit: "contain", objectPosition: "left" }} src={src} />*/}
                          {/*                :*/}
                          {/*                decodeURI(file.Metadata.originalname)*/}
                          {/*            }*/}
                          {/*        </a>)*/}
                          {/*    })}*/}

                          {/*</div>*/}
                        </div>
                        {/*
                          {ordContent && (
                            <div className="text-16">
                              <h4 className="text-14-regular px-5 text--gradient m-0 d-inline-block mb-3">
                                Маркировка рекламы
                              </h4>
                              <div
                                className={
                                  'px-5 border-t_purple border-b_purple background-purple--pale  pt-3 mb-3 pb-3'
                                }>
                                {ordContent}
                              </div>
                            </div>
                          )}
                        */}

                        <div className="text-16">
                          <h4 className="text-14-regular px-5 text--gradient m-0 d-inline-block mb-3">
                            Ссылка на продукт
                          </h4>

                          <div className="border-t_purple border-b_purple background-purple--pale px-5 py-2 mb-3">
                            <a
                              className="text-break fw-bold text--purple "
                              href={fixUrlProtocol(deal.offerData.url)}>
                              {deal.offerData.url}
                            </a>
                          </div>
                          <span className="d-block px-5 mb-5">
                            Блогер будет должен разместить эту ссылку
                          </span>
                        </div>

                        {(deal?.status == 'completed' ||
                          deal?.status == 'finish') && (
                          <div className="text-16">
                            <h4 className="text-14-regular px-5 text--gradient m-0 d-inline-block mb-3">
                              Ссылка на размещение
                            </h4>
                            <div className="border-t_purple border-b_purple px-5 background-purple--pale py-2 mb-3">
                              <a
                                className="text-break fw-bold text--purple px-5"
                                href={deal?.completeData?.linkToResult}>
                                {deal?.completeData?.linkToResult}
                              </a>
                            </div>

                            <h4 className="text-14-regular px-5 text--gradient m-0 d-inline-block mb-3">
                              Статистика по размещению
                            </h4>
                            <div className="border-t_purple border-b_purple flex-wrap px-5 background-purple--pale py-2 mb-3">
                              <span className="text-break ">
                                {!deal?.completeData?.statisticsFiles[0] && (
                                  <>
                                    Блогер должен загрузить статистику спустя
                                    сутки после публикации материала
                                  </>
                                )}
                                {deal?.completeData?.statisticsFiles[0] &&
                                  deal?.completeData?.statisticsFiles?.map(
                                    file_id => {
                                      return (
                                        <a
                                          className="d-block h-25 w-25"
                                          key={file_id}
                                          href={
                                            'https://s3.bloggery.io/file/bloggery/' +
                                            file_id
                                          }
                                          target="_blank"
                                          rel="noreferrer">
                                          <img
                                            className=""
                                            style={{
                                              objectFit: 'contain',
                                              objectPosition: 'left',
                                            }}
                                            src={
                                              'https://s3.bloggery.io/file/bloggery/' +
                                              file_id
                                            }
                                          />
                                        </a>
                                      )
                                    }
                                  )}
                              </span>
                            </div>
                          </div>
                        )}

                        {deal.blogger.user._id ==
                          AuthService.getCurrentUser().id &&
                          !deal.payed &&
                          !(deal.status === 'cancelled') && (
                            <span
                              className={
                                'd-block px-5 mb-5 color-red text-red text-bold'
                              }>
                              Рекламодатель еще не внес оплату. Подождите пока
                              он внесет оплату за предложение, затем приступайте
                              к выполнению.
                            </span>
                          )}

                        {deal.user._id == AuthService.getCurrentUser().id &&
                          !deal.payed &&
                          !(deal.status === 'cancelled') && (
                            <span
                              className={
                                'd-block px-5 mb-5 color-red text-red text-bold'
                              }>
                              Оплатите предложение, чтобы блогер мог приступить
                              к выполнению.
                            </span>
                          )}

                        <div className="col p-5 pt-0 d-flex flex-grow-0 column justify-content-between">
                          <div className="d-flex">
                            <div className="d-flex column-gap-3 h-100 ">
                              <div className="d-flex flex-column flex-sm-row align-items-sm-center align-items-center">
                                {deal.user._id ==
                                  AuthService.getCurrentUser().id &&
                                  (!deal.status ||
                                    deal.status == 'pending') && (
                                    <>
                                      <Button
                                        className="m-1 btn btn_secondary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          setCancelVisible(true)
                                        }}>
                                        Отменить оффер
                                      </Button>
                                    </>
                                  )}

                                {/* blogger logic */}
                                {deal.blogger.user._id ==
                                  AuthService.getCurrentUser().id &&
                                  (!deal.status ||
                                    deal.status == 'pending') && (
                                    <div>
                                      <Button
                                        className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          acceptDealBlogger(id)
                                          initDeal()
                                        }}
                                        disabled={!deal.payed}>
                                        Взяться за выполнение
                                      </Button>
                                      <Button
                                        className="m-1 btn btn_secondary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          declineDealBlogger(id)
                                            .then(() => initDeal())
                                            .catch(e => toast.error(e.message))
                                        }}>
                                        Отказаться от оффера
                                      </Button>
                                    </div>
                                  )}

                                {deal.blogger.user._id ==
                                  AuthService.getCurrentUser()
                                    .id /* && i am blogger */ &&
                                  deal.status == 'inwork' && (
                                    <>
                                      <Button
                                        disabled={!deal.payed}
                                        className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                        onClick={() =>
                                          setFinishOfferVisible(true)
                                        }>
                                        Отметить как завершенное
                                      </Button>
                                    </>
                                  )}

                                {/*{(deal.user._id != AuthService.getCurrentUser().id && deal.status == "finish" /* && i am blogger */}
                                {/*    &&*/}
                                {/*    <div className='d-flex column-gap-3'>*/}
                                {/*        <Button className='btn  w-100' onClick={() => setFinishOfferVisible(true)}>*/}
                                {/*            Отредактировать результат*/}
                                {/*        </Button>*/}
                                {/*    </div>*/}
                                {/*}*/}

                                {deal.blogger?.user._id ==
                                  AuthService.getCurrentUser().id &&
                                  deal.status == 'completed' &&
                                  !deal?.completeData?.statisticsFiles[0] && (
                                    <>
                                      <Button
                                        className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          setAddStatisticsVisible(true)
                                        }}>
                                        Загрузить статистику
                                      </Button>
                                    </>
                                  )}

                                {/* advertiser logic */}
                                {deal.user._id ==
                                  AuthService.getCurrentUser().id &&
                                  deal.status == 'completed' && (
                                    <>
                                      <Button
                                        className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          finishDealAdvertiser(id)
                                            .then(() => {
                                              window.location.reload()
                                            })
                                            .catch(e => {
                                              console.log(
                                                'ERROR:finishDealAdvertiser: ',
                                                e
                                              )
                                              toast.error('Ошибка ' + e.status)
                                            })
                                        }}>
                                        Принять как выполненное
                                      </Button>
                                      <Button
                                        className="m-1 btn btn_secondary text-12-bold lh-1 py-3"
                                        onClick={() => {
                                          declineDealFinishAdvertiser(id)
                                            .then(() =>
                                              toast.success(
                                                'Заявка на спор отправлена администрации. Скоро с вами свяжется менеджер.'
                                              )
                                            )
                                            .catch(e => {
                                              console.log(
                                                'ERROR:declineDealFinishAdvertiser: ',
                                                e
                                              )
                                              toast.error('Ошибка')
                                            })
                                        }}>
                                        Открыть спор
                                      </Button>
                                    </>
                                  )}

                                {deal.user._id ==
                                  AuthService.getCurrentUser().id &&
                                  deal.status !== 'cancelled' &&
                                  !deal.payed && (
                                    <Button
                                      className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                      onClick={() => setShowPaymentModal(true)}>
                                      Внести оплату
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Bordered className="col-md-4">
                      <div className="p-3 h-100">
                        <div className="d-flex justify-content-center">
                          <h2 className="card-title ">Условия размещения</h2>
                        </div>
                        <OfferCondition
                          keyWord={'Заказчик'}
                          value={deal.user.lastName + ' ' + deal.user.firstName}
                        />
                        <OfferCondition
                          keyWord={'Исполнитель'}
                          value={
                            <Link
                              className="text--purple text-end text-bolder"
                              to={'/blogger/' + deal.blogger._id}>
                              @{deal.blogger.username}
                            </Link>
                          }
                        />
                        <OfferCondition
                          keyWord={'Платформа'}
                          value={deal.offerData.platform}
                        />
                        <OfferCondition
                          keyWord={'Бюджет'}
                          value={deal.price + 'р'}
                        />
                        <OfferCondition
                          keyWord={'Дата размещения'}
                          value={
                            deal.acceptDeadline &&
                            formatDate(deal.acceptDeadline)
                          }
                        />
                        <OfferCondition
                          keyWord={deal.payed ? 'Оплачен' : 'Не оплачен'}
                          value={''}
                        />
                        <OfferCondition
                          keyWord={'Статус'}
                          value={
                            <span className="text-black">
                              <StatusIcon status={deal.status} />
                              {statuses[deal.status] || deal.status}
                            </span>
                          }
                        />

                        {/* advertiser logic */}
                      </div>
                    </Bordered>
                  </div>
                )}
              </div>
              {/*CHAT*/}
              {deal.status != 'new' && (
                <div className="d-flex justify-content-center">
                  <div className={'col-md-8'}>
                    <div className="d-flex justify-content-center">
                      <h3 className="mt-3 mb-3 text-uppercase text--gradient">
                        Общение
                      </h3>
                    </div>
                    {messages.length > 0 ? (
                      <div
                        style={{ minHeight: '200px' }}
                        className="overflow-y-auto h-5 row-gap-2 mb-5">
                        {messages?.map((m, i) => (
                          <div className="chat-message" key={m._id}>
                            <div
                              className={
                                m.user == AuthService.getCurrentUser().id
                                  ? 'me'
                                  : 'companion'
                              }>
                              <p>{m.message}</p>
                            </div>
                          </div>
                        ))}
                        {deal.status === 'cancelled' && (
                          <div className="text-center text-muted">
                            Оффер отменен
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <img
                          className={cls['chat-placeholder'] + ' mb-8 mt-3'}
                          src={chatPlaceholder}
                        />
                      </div>
                    )}
                    <form
                      className={'mb-4'}
                      onSubmit={event => {
                        event.preventDefault()
                        sendMessage()
                      }}>
                      <NewInput
                        type="text"
                        disabled={deal.status == 'cancelled'}
                        className="form-control mb-3"
                        placeholder="Сообщение"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                      <div className={'d-flex justify-content-center'}>
                        <Button
                          disabled={message.length < 1}
                          className="btn btn_primary mt-2 px-6"
                          type="submit">
                          Отправить
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FinishOffer
        show={finishOfferVisible}
        onHide={() => {
          setFinishOfferVisible(false)
        }}
        onApply={payload => {
          finishDealBlogger(id, payload)
            .then(() => {
              window.location.reload()
            })
            .catch(e => console.log('ERROR:finish blogger: ', e))
        }}
      />
      <FinishOfferUploadStatistics
        show={addStatisticsVisible}
        onHide={() => {
          setAddStatisticsVisible(false)
        }}
        onApply={payload => {
          addStatisticsDealBlogger(id, payload)
            .then(() => {
              window.location.reload()
            })
            .catch(e => console.log('ERROR:add statistics: ', e))
        }}
      />
    </div>
  )
}

export default ViewDeal

import React, { useState } from 'react'
import { useEffect } from 'react'

import Navbar from '../components/UI/navBar/navbar'
import PageHeader from '../components/pageHeader/page-header'

const Chats = () => {
  const [chosenChat, setChosenChat] = useState()

  const [newMessage, setNewMessage] = useState('')

  const myUsername = 'user2'

  const messages = [
    {
      campaignId: '1',
      username: 'user1',
      message: 'Zdarova',
      date: '12.03.2022',
    },
    {
      campaignId: '1',
      username: 'user1',
      message: 'Alo ti tuta?',
      date: '13.03.2022',
    },
    {
      campaignId: '1',
      username: 'user2',
      message: 'Zdarova\nDa, ya tuta',
      date: '13.03.2022',
    },
    {
      campaignId: '1',
      username: 'user1',
      message: 'ok',
      date: '14.03.2022',
    },
  ]

  const testChats = [
    {
      campaignName: 'Comp1',
      campaignId: 1,
      lastMsg: {
        text: 'Last message',
        date: '12.03.2022',
        isMine: false,
      },
      isLastMsgChecked: false,
      conversationWith: 'blogger1',
    },
    {
      campaignName: 'Comp2',
      campaignId: 2,
      lastMsg: {
        text: 'Last message',
        date: '12.03.2022',
        isMine: false,
      },
      isLastMsgChecked: false,
      conversationWith: 'blogger1',
    },
  ]

  const sendMessage = () => {
    console.log('Sended')
  }

  useEffect(() => {
    console.log(chosenChat)
  }, [chosenChat])

  return (
    <div className="page">
      <Navbar />
      <div className="page-wrapper">
        <PageHeader title={'Сообщения'}></PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="row row-deck row-cards">
              <div className="col-lg-6">
                <div className="row row-cards">
                  <div className="col-12">
                    <div className="card" style={{ height: '70vh' }}>
                      <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                        <div className="divide-y">
                          {testChats.map(chat => (
                            <div
                              id={chat.campaignId}
                              key={chat.campaignId}
                              className="row chat-card pt-2"
                              onClick={e => setChosenChat(e.currentTarget.id)}
                            >
                              <div className="col-auto">
                                {chat.campaignName}
                              </div>
                              <div className="col">
                                <div className="text-truncate">
                                  {chat.lastMsg.isMine
                                    ? `Вы: ${chat.lastMsg.text}`
                                    : `${chat.conversationWith}: ${chat.lastMsg.text}`}
                                </div>
                                <div className="text-muted">
                                  {chat.lastMsg.date}
                                </div>
                              </div>
                              {chat.isLastMsgChecked && (
                                <div className="col-auto align-self-center">
                                  <div className="badge bg-primary"></div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row row-cards">
                  <div className="col-12">
                    <div className="card" style={{ height: '70vh' }}>
                      <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                        {chosenChat && (
                          <div className="h-100 column">
                            {
                              testChats.find(e => e.campaignId == chosenChat)
                                .campaignName
                            }
                            <hr className="my-3" />
                            <div className="column">
                              {messages.map(e =>
                                e.username == myUsername ? (
                                  <div
                                    className="mt-2"
                                    style={{
                                      margin: '0',
                                      alignSelf: 'flex-end',
                                    }}
                                  >
                                    {e.message}
                                    <div
                                      className="text-muted"
                                      style={{ textAlign: 'right' }}
                                    >
                                      {e.date}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mt-2" style={{ margin: '0' }}>
                                    {e.message}
                                    <div
                                      className="text-muted"
                                      style={{ textAlign: 'left' }}
                                    >
                                      {e.date}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>

                            <div style={{ marginTop: 'auto' }}>
                              <hr className="my-3" />
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Напишите сообщение"
                                  value={newMessage}
                                  onChange={e => setNewMessage(e.target.value)}
                                />
                                <button className="btn" onClick={sendMessage}>
                                  Отправить
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chats

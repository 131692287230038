import React, { useEffect, useState } from 'react'
import AuthService from '../../../services/auth.service'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Ad2,
  Messages,
  PigMoney,
  Users,
  Link as TLink,
} from 'tabler-icons-react'
import logo from '../../../assets/Bloggery.svg'

import { getWalletBalance } from '../../../services/wallet.service'
import {
  getNotifications,
  readNotificationById,
  readNotifications,
} from '../../../services/notifications.service'
import NotificationCard from '../../notificationCard'
import NavBarLink from './navBarLink/navBarLink'
import { navElems } from '../../../utils/navElems'
import NavBarDivider from './navBarDivider/navBarSeparator'

import exitIcon from '../../../assets/icons/exit.svg'
import avatarIcon from '../../../assets/icons/avatar.png'
import walletIcon from '../../../assets/icons/wallet.svg'

import './navBar.scss'
import { addSpacesToNumber } from '../../../utils/functional'

const Navbar = props => {
  const params = useParams()

  let navigate = useNavigate()

  const currentUser = AuthService.getCurrentUser()

  const [balance, setBalance] = useState({})
  const [notifications, setNotifications] = useState([])

  const handleLogout = () => {
    AuthService.logout()
    window.location.reload()
  }

  const removeNotification = id => {
    setNotifications(notifications.filter(elem => elem._id != id))
  }

  useEffect(() => {
    getWalletBalance().then(data => setBalance(data))
    getNotifications().then(data =>
      setNotifications(data.filter(e => e.read != true))
    )
  }, [])

  return (
    <div className="">
      <header className="navbar bg-transparent navbar-expand-md navbar-light d-print-none">
        <div className="container-xl py-1">
          <div className="mobile navbar__navigation nav-item dropdown ">
            <button
              className="navbar-toggler me-6"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-target="#navbar-menu"
              aria-controls="navbar-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                style={{ color: 'var(--main-purple)' }}
                className="navbar-toggler-icon"
              />
            </button>
            <div className="dropdown-menu py-0 mt-2" id="navbar-menu">
              <div className="new-nav-bar">
                <div className="navbar-nav flex-column">
                  {navElems.map((e, i) => (
                    <NavBarLink key={i} to={e.link} svg={e.icon}>
                      {e.name}
                    </NavBarLink>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <h1 className="navbar-brand d-none-navbar-horizontal pe-0 pe-md-3">
            <Link to="/">
              <img
                style={{ width: '126px' }}
                src={logo}
                alt="Bloggery logo"
                className="navbar-brand-image"
              ></img>
            </Link>
          </h1>
          <div className="navbar-nav flex-row order-md-last">
            <div
              className="align-items-center d-flex me-3"
              style={{ alignItems: 'center' }}
            >
              <div className="desktop navbar__balance flex-wrap row-gap-1 column-gap-5 fs-4 me-4">
                <div className="d-flex  column-gap-3 align-items-end">
                  <span>Баланс: </span>
                  <span className="fw-bold text--purple">
                    {addSpacesToNumber(balance.balance)}₽
                  </span>
                </div>
                <div className="d-flex column-gap-3  align-items-end">
                  <span>Заморожено: </span>
                  <span className="text--purple fw-bold">
                    {addSpacesToNumber(balance.frozenBalance)}₽
                  </span>
                </div>
              </div>
              <div className="nav-item dropdown d-md-flex">
                <a
                  href="src/components/UI/navBar/navbar#"
                  className="nav-link px-0"
                  data-bs-auto-close="outside"
                  data-bs-toggle="dropdown"
                  tabIndex="-1"
                  aria-label="Show notifications"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                  </svg>
                  {notifications.length != 0 && (
                    <span className="badge bg-red" />
                  )}
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Последние уведомления</h3>
                      <button
                        className="btn btn-icon ms-5"
                        onClick={() => {
                          readNotifications()
                          setNotifications([])
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-trash"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#206bc4"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="4" y1="7" x2="20" y2="7" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                      </button>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                      {notifications.map(
                        (e, i) =>
                          !e.read && (
                            <NotificationCard
                              readNotification={() => {
                                readNotificationById(e._id)
                                removeNotification(e._id)
                              }}
                              key={e._id}
                              title={e.title}
                              text={e.text}
                              date={e.date}
                              link={e.link}
                            />
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="src/components/UI/navBar/navbar#"
                className="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <span
                  className="avatar avatar-sm"
                  style={{ backgroundImage: `url(${avatarIcon})` }}
                ></span>
                <div className="d-none d-md-block ps-2">
                  <div>
                    {currentUser
                      ? currentUser.firstName +
                        ' ' +
                        (currentUser.lastName ? currentUser.lastName : '')
                      : 'not logged in'}
                  </div>
                  <div className="mt-1 small text-muted">
                    {currentUser.email}
                  </div>
                </div>
              </a>
              <div
                  style={{ width: '196px'}}
                  className="bordered p-0 navbar-profile dropdown-menu dropdown-menu-end"
              >
                <div className='p-3'>
                  {/*<Link to="#" className="dropdown-item">Мои объявления</Link>
                                <Link to="#" className="dropdown-item">Заказы рекламы</Link>
                                <Link to="#" className="dropdown-item">Мои отзывы</Link>*/}

                  <div className="d-flex align-items-center column-gap-2 mb-2">
                    <img style={{width: '24px', height: '24px'}} src={avatarIcon} className='d-block bg-transparent'></img>
                    <div className='d-flex column '>

                      <span  className='fs-14 text-capitalize'>{currentUser.firstName} {currentUser.lastName}</span>
                      <span style={{lineHeight: '12px'}} className='text-12-regular text-break text--disabled'>{currentUser.email}</span>
                    </div>
                  </div>




                  <div className="w-100 mx-auto border-t_gradient" />



                  <div className="desktop mt-2 d-flex row-gap-2 column">
                    <Link
                        to="/wallet"
                        className="text--purple align-items-center"
                    >
                      <img className="me-2" src={walletIcon} alt="" />
                      Кошелёк
                    </Link>
                    <Link to="/user" className="text--black">
                      Профиль
                    </Link>
                    <Link to="/referrals" className=" text--black">
                      Реферальный кабинет
                    </Link>
                    <div className="mobile mt-5">
                      <div className="d-flex column-gap-3 justify-content-end">
                        <span>Баланс: </span>
                        <span className=" text--purple">
                      {balance.balance}₽
                    </span>
                      </div>
                      <div className="d-flex column-gap-3 w-100  justify-content-end">
                        <span>Заморожено: </span>
                        <span className="text--purple ">
                      {balance.frozenBalance}₽
                    </span>
                      </div>
                    </div>
                  </div>



                </div>
                <button
                    style={{ paddingTop: 0, paddingBottom: '0.75rem' }}
                    className="dropdown-item py-3 px-3 bg-purple-pale w-100 column-gap-2 d-flex justify-content-end"
                    onClick={handleLogout}
                >
                  <span className='text--black'>Выйти</span><img src={exitIcon} alt="Выйти" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="desktop navbar__navigation navbar-expand-md">
        <div className="new-nav-bar collapse navbar-collapse py-2">
          <div className="navbar navbar-light container-xl">
            <div className="container-xl">
              <ul className="navbar-nav">
                {navElems.map((e, i) => (
                  <NavBarLink key={i} to={e.link} svg={e.icon}>
                    {e.name}
                  </NavBarLink>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar

import cls from './plusIcon.module.scss'

const plusIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="52"
        height="52"
        rx="26"
        fill="url(#paint0_linear_1884_475)"
      />
      <path
        d="M35.9419 27.7902H28.334V35.9409H24.3928V27.7902H16.8242V24.1698H24.3928V16.0586H28.334V24.1698H35.9419V27.7902Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1884_475"
          x1="0.787878"
          y1="9.38347"
          x2="53.5218"
          y2="11.358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#515CD1" />
          <stop offset="1" stop-color="#FF5FAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const PlusButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className={cls['plus-button']}>
      <plusIcon></plusIcon>
    </button>
  )
}

export default plusIcon

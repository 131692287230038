import logo from '../assets/Bloggery.svg'
import Form from 'react-validation/build/form'
import Input from '../components/UI/newInput/input'
import { required, validEmail, validPassword } from '../utils/validation'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { translateServerMsg } from '../utils/functional'
import Button from '../components/UI/button/button'
import Bordered from '../components/UI/bordered/bordered'
import AuthCard from '../components/AuthCard/authCard'

export const ConfirmEmail = () => {
  const location = useLocation()
  const params: URLSearchParams = new URLSearchParams(location.search)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const code: string = params.get('code')

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_API + 'user/confirmEmail',
        { code: code },
        { withCredentials: true }
      )
      .then(response => {
        if (response.data.ok === true) {
          setError(null)
        } else {
          console.log(response.data.error)
          setError(translateServerMsg(response.data.error))
        }
      })
      .catch(error => {
        setError('Ошибка при подтверждении почты')
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <AuthCard>
      <div className="justify-content-center mb-4">
        {!isLoading ? (
          <h2 className="text-center text--auth-title text-uppercase text-1 ">
            {!error ? 'Ваш Email успешно подтвержден' : error}
          </h2>
        ) : (
          <span>waiting...</span>
        )}
      </div>

      <div>
        <div className="mb-3"></div>
        <div className="form-footer">
          <Link to="/user">
            {' '}
            <Button
              className="btn btn_primary w-100"
              onSubmit={e => {
                e.prevent.default()
              }}>
              В личный кабинет
            </Button>
          </Link>
        </div>
      </div>
    </AuthCard>
  )
}

import cls from './notification.module.scss'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/notifications/successIcon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/icons/notifications/errorIcon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/notifications/warningIcon.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/notifications/infoIcon.svg'
import { ReactComponent as PlacementIcon } from '../../../assets/icons/notifications/placementIcon.svg'
import Button from '../button/button'

export const MyNotification = ({
  type = 'placement',
  title = 'INFO',
  to = '/',
  buttonText = 'Перейти',
  ...props
}) => {
  return (
    <div
      className={
        cls.notification +
        ' ' +
        cls[type] +
        ' w-100 d-flex justify-content-between column-gap-3 align-items-center ' +
        props.className
      }
      style={props.style}
    >
      <div className="d-flex align-items-center column-gap-3">
        {type === 'success' ? (
          <SuccessIcon />
        ) : type === 'error' ? (
          <ErrorIcon />
        ) : type === 'warning' ? (
          <WarningIcon />
        ) : type === 'info' ? (
          <InfoIcon />
        ) : type === 'placement' ? (
          <PlacementIcon />
        ) : null}

        <div className="d-flex column  justify-content-center">
          <h5
            style={{ marginBottom: '4px' }}
            className="m-0 f-geometria notification-title fs-14 fw-bold text-uppercase"
          >
            {title}
          </h5>
          <p style={{ maxWidth: '280px' }} className="m-0 fs-12">
            {props.children}
          </p>
        </div>
      </div>
      <a
        href={to}
        style={{ width: '150px', height: '42px' }}
        className="btn  bg-transparent"
      >
        {buttonText}
      </a>
    </div>
  )
}

export const statuses = {
  new: 'Новый',
  started: 'Начался',
  inwork: 'В работе',
  completed: 'Завершен',
  declined: 'Отклонен',
  cancelled: 'Отклонен',
  pending: 'Ожидает',
  finish: 'Завершен',
  accepted: 'Принят',
}

export const transactionTypes = {
  freeze: 'Заморозка',
  unfreeze: 'Разморозка',
  addFrozen: 'Оплата (удержание средств)',
}

export const weekDays = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  7: 'Вс',
}

export const socialNetworks = {
  instagram: 'Instagram*',
  vk: 'VK',
  telegram: 'Telegram',
  youtube: 'YouTube',
  any: 'Любая',
}

export const translateSocials = string => {
  return socialNetworks[string] || string
}

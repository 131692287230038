export const BackArrowButton = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3059 23.1213L0.0400534 12L10.3059 0.878697L12.264 3.00002L5.34095 10.5L21.3828 10.5L21.3828 13.5L5.34095 13.5L12.264 21L10.3059 23.1213Z"
      fill="#515CD1"
    />
  </svg>
)

import React, { useState } from 'react'
import { useRef, useEffect } from 'react'
import axios from 'axios'

import { Link, useNavigate } from 'react-router-dom'

import { getDealsToMe } from '../services/offers.service'
import { MyTable } from './UI/myTable/myTable'

import { formatNumber } from '../utils/functional'
import PlusIcon, { PlusButton } from './UI/plusIcon/plusIcon'
import DashboardBloggerCard from './UI/dashboardCards/dashboardBloggerCard'
import DashboardAdvertiserCard from './UI/dashboardCards/dashboardAdvertiserCard'
import Button from './UI/button/button'

import bloggerImg from '../assets/title_blogger.webp'
import questionIcon from '../assets/icons/question.svg'
import plusIcon from '../assets/icons/plus.svg'
import searchIcon from '../assets/icons/search.svg'
import { MyNotification } from './UI/notification/myNotification'
import { Collapse } from 'react-bootstrap'

const DashboardBlogger = ({ setHasData }) => {
  const navigate = useNavigate()

  const [ordersInWork, setOrdersInWork] = useState(0)
  const [ordersCompleted, setOrdersCompleted] = useState(0)

  const [income, setIncome] = useState(0)

  const [rating, setRating] = useState('0')
  const [notifications, setNotifications] = useState([])

  const [bloggers, setBloggers] = useState([])
  const [dealsToMe, setDealsToMe] = useState([])

  const [isEmpty, setIsEmpty] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      dealsToMe.map(deal => {
        const todaysDate = new Date()
        todaysDate.setHours(0, 0, 0)
        const deadline = new Date(deal.finishDeadline)
        deadline.setHours(0, 0, 0)
        console.log(new Date(deadline), todaysDate)
        if (new Date(deal.finishDeadline) == todaysDate) {
          setNotifications([
            ...notifications,
            {
              title: 'У вас сегодня размещение!',
              type: 'placement',
              text:
                'Разместите материал согласно техническому\n' +
                'заданию и отправьте ссылку заказчику ',
              to: '/offer/' + deal._id,
            },
          ])
        }
      })
    }, 100)
  }, [dealsToMe])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + `bloggers?filter=my`, {
        withCredentials: true,
      })
      .then(response => {
        if (response.data.length == 0) return
        setBloggers(response.data)
        setHasData(true)
      })
    getDealsToMe().then(data => setDealsToMe(data))
  }, [])

  useEffect(() => {
    if (bloggers.length > 0 || dealsToMe.length > 0) {
      setHasData(true)
      setIsEmpty(false)
    }
    console.log('dealsToMe', dealsToMe)
  }, [bloggers, dealsToMe])

  return (
    <div className="row row-cards">
      {!isEmpty ? (
        <>
          {dealsToMe.length == 0 && (
            <div className="border-t_purple border-b_purple py-4 column align-items-center mb-40">
              <div
                style={{ marginBottom: '1.25rem' }}
                className="text-uppercase text-14-regular text-center"
              >
                посмотрите доступные рекламные предложения
              </div>
              <Button
                onClick={() => navigate('/offersauctions')}
                style={{ width: '240px' }}
                className="btn btn_primary"
              >
                ПОСМОТРЕТЬ
              </Button>
            </div>
          )}
          <div className="justify-content-center d-flex">
            <Collapse timeout={3000} in={notifications.length > 0}>
              <ul className="mb-6 col-md-6 d-flex column row-gap-3">
                {notifications.map((item, index) => {
                  return (
                    <MyNotification
                      title={item.title}
                      type={item.type}
                      to={item.to}
                      key={index}
                    >
                      {item.text}
                    </MyNotification>
                  )
                })}
              </ul>
            </Collapse>
          </div>

          <div className="col-lg-8 col-md-12 mt-2 mx-auto">
            <div className="row row-cards mx-0">
              {dealsToMe.length > 0 &&
                dealsToMe.some(e => e.status == 'pending') && (
                  <>
                    <div className="d-flex justify-content-center">
                      <h2 className="text-uppercase text-center">
                        у вас новое предложение!
                      </h2>
                    </div>

                    {dealsToMe.map(
                      deal =>
                        deal.status == 'pending' && (
                          <DashboardAdvertiserCard
                            key={deal._id}
                            offerLink={'/offer/'}
                            className="pink"
                            _id={deal._id}
                            blogger={deal.blogger[0]}
                            offerData={deal.offerData}
                            price={deal.price}
                            acceptDeadline={deal.acceptDeadline}
                            status={deal.status}
                          />
                        )
                    )}
                  </>
                )}

              {dealsToMe.length > 0 &&
                dealsToMe.some(
                  e =>
                    e.status != 'pending' ||
                    e.status != 'cancelled' ||
                    e.status != 'declined'
                ) && (
                  <>
                    <div className="d-flex justify-content-center">
                      <h2 className="text-uppercase text-center">
                        Текущие предложения
                      </h2>
                    </div>

                    {dealsToMe.map(
                      deal =>
                        deal.status != 'pending' &&
                        deal.status != 'cancelled' &&
                        deal.status != 'declined' && (
                          <DashboardAdvertiserCard
                            key={deal._id}
                            offerLink={'/offer/'}
                            _id={deal._id}
                            blogger={deal.blogger[0]}
                            offerData={deal.offerData}
                            price={deal.price}
                            acceptDeadline={deal.acceptDeadline}
                            status={deal.status}
                          />
                        )
                    )}
                  </>
                )}

              <div className="d-flex justify-content-center">
                <h2 className="text-uppercase">ваши аккаунты</h2>
              </div>
              {bloggers.length > 0 &&
                bloggers.map((blogger, i) => (
                  <DashboardBloggerCard
                    key={blogger._id}
                    i={i}
                    card={blogger}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className="col-md-8 mt-2 mx-auto column align-items-center text-center">
          <span className="text-gradient text-uppercase f-geometria">
            Добро пожаловать на платформу
          </span>
          <span className="h1-title text-uppercase mt-1">Bloggery</span>
          <div className="text-uppercase mt-6 text-14-regular">
            Получите доступ к базе рекламодателей и зарабатывайте
          </div>
          <div style={{ maxWidth: '270px', marginTop: '1.25em' }}>
            <img src={bloggerImg} alt="" />
          </div>
          <Button
            style={{ minWidth: '280px' }}
            onClick={() => navigate('/blogger/create')}
            className="mt-6 btn btn_secondary"
          >
            <img
              style={{ height: '13px' }}
              className="ms-1"
              src={plusIcon}
              alt=""
            />
            <span className="mx-auto text-14-bold">Добавить аккаунт</span>
          </Button>
          <Button
            style={{ minWidth: '280px' }}
            onClick={() => navigate('/offersauctions')}
            className="mt-3 btn btn_secondary"
          >
            <img
              style={{ height: '13px' }}
              className="ms-1"
              src={searchIcon}
              alt=""
            />
            <span className="mx-auto text-14-bold">найти рекламодателя</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default DashboardBlogger

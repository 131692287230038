import React, { useEffect, useState } from 'react'
///import statusIcon.module.scss///

const StatusIcon = ({ status }) => {
  const [color, setColor] = useState('orange')
  useEffect(() => {
    switch (status) {
      case 'inwork':
        setColor('blue')
        break
      case 'declined':
        setColor('red')
        break
      case 'cancelled':
        setColor('red')
        break
      case 'pending':
        setColor('orange')
        break
      case 'finish':
        setColor('green')
        break
      case 'completed':
        setColor('green')
        break
      case 'accepted':
        setColor('green')
        break
    }
  })

  return <span className="badge mx-2" style={{ backgroundColor: color }}></span>
}

export default StatusIcon

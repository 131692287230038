import React, { ComponentProps, useState } from 'react'
import { getValidatedClassnames } from '../../../utils/validation'
import cls from './newInput.module.scss'
import { UseFormRegister } from 'react-hook-form'
import {NewInputProps} from "../../../types/components";

const NewPasswordInput: React.FC<NewInputProps> = ({ register = (name) => {return {}}, error, className, name, ...props }) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  return (
    <div className={'column d-flex ' + className}>
      <div
        className={cls['password-wrapper'] + ' input-group'}
      >
        <input
          value={props.value}
          type={isShowPassword ? 'text' : 'password'}
          placeholder={props.placeholder}
          className={cls.input + ' form-control'}
          {...register(name)}
          {...props}
        />
        <button
          type="button"
          onClick={e => {
            e.preventDefault()
            setIsShowPassword(!isShowPassword)
          }}
          className={cls['show-password'] + ' '}
        >
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7842 1.8825C13.9625 0.633333 12.0129 0 9.99 0C8.16958 0 6.395 0.541666 4.71542 1.60333C3.02167 2.67625 1.36125 4.625 0 6.66667C1.10083 8.5 2.60667 10.385 4.175 11.4658C5.97417 12.705 7.93042 13.3333 9.99 13.3333C12.0317 13.3333 13.9837 12.7054 15.7942 11.4671C17.3879 10.375 18.9046 8.4925 20 6.66667C18.9008 4.85708 17.3792 2.97667 15.7842 1.8825ZM10 10.6667C9.20887 10.6667 8.43551 10.4321 7.77772 9.99254C7.11992 9.55302 6.60723 8.9283 6.30448 8.1974C6.00173 7.4665 5.92252 6.66223 6.07686 5.88631C6.2312 5.11038 6.61216 4.39765 7.17157 3.83824C7.73098 3.27883 8.44371 2.89787 9.21964 2.74353C9.99556 2.58918 10.7998 2.6684 11.5307 2.97115C12.2616 3.2739 12.8864 3.78659 13.3259 4.44439C13.7654 5.10218 14 5.87554 14 6.66667C13.9988 7.72716 13.577 8.74387 12.8271 9.49375C12.0772 10.2436 11.0605 10.6655 10 10.6667Z"
              fill="#CECECE"
            />
          </svg>
        </button>
      </div>
      {error && error}
    </div>
  )
}

export default NewPasswordInput

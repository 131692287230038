import React from 'react'
import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useState } from 'react'
import { useEffect } from 'react'
import NewInput from './newInput.tsx'
import cls from './newInput.module.scss'
import {DoubleInputProps} from "../../../types/components";

const InputDoubleRange = ({ initialRange, forwardRange, max, min, step }: DoubleInputProps) => {
  const [range, setRange] = useState(initialRange)

  useEffect(() => {
    setRange(initialRange)
  }, [initialRange])

  useEffect(() => {
    forwardRange(range)
  }, [range])

  return (
    <div>
      <div
        className={'d-flex ' + cls['double-range-input']}
        style={{ justifyContent: 'space-between' }}
      >
        <div className="input-group mb-2 d-flex flex-nowrap">
          <span className="input-group-text">От</span>
          <NewInput
            type="number"
            value={range[0]}
            onChange={e => setRange([e.target.value, range[1]])}
            name="followersFrom"
            placeholder="10000"
            autoComplete="off"
          />
        </div>

        <div
          className="ms-2 input-group mb-2 d-flex flex-nowrap"
          style={{ borderLeft: '1px solid #515CD1' }}
        >
          <span className="input-group-text">До</span>
          <NewInput
            type="number"
            value={range[1]}
            onChange={e => setRange([range[0], e.target.value])}
            name="followersTo"
            placeholder="10000"
            autoComplete="off"
          />
        </div>
      </div>

      <Slider
        step={step || 1000}
        min={min === 0 || min ? min : 1000}
        max={max || 1000000}
        range
        allowCross={false}
        defaultValue={range}
        value={range}
        onChange={(value: number[]) => {
          setRange(value)
        }}
      />
    </div>
  )
}

export default InputDoubleRange

import React, { useState } from 'react'
import NewInput from '../UI/newInput/newInput.tsx'
import MyPhoneInputDub from '../UI/newInput/MyPhoneInputDub.tsx'
import PriceInput from '../UI/newInput/priceInput'
import DateInput from '../UI/newInput/dateInput'
import Button from '../UI/button/button'
import { BackArrowButton } from './backArrowButton'

import cls from './modals.module.scss'
import { isUrl, validDeadline, validNumberFormat } from '../../utils/validation'
import axios from 'axios'
import { fromatDateString } from '../../utils/functional'
import { date, mixed, number, object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useField from '../../hooks/useField'

const ReadyAdModal = ({ show, onHide }) => {
  const readyAdSchema = object().shape({
    phone: mixed()
      .required('Обязательное поле')
      .test('phone', 'Некорректный номер', phone => {
        return validNumberFormat(phone.value, phone.format)
      }),
    link: string()
      .required('Обязательное поле')
      .test('isUrl', 'Введите URL', value => {
        return isUrl(value)
      }),
    price: number().required('Обязательное поле'),
    deadline: date().test(
      'validDeadline',
      'Дата не может быть раньше чем через 3 дня с этого момента',
      value => {
        return validDeadline(value)
      }
    ),
  })

  const getValidDate = () => {
    const date = new Date()
    date.setDate(date.getDate() + 4)
    return date.toLocaleDateString().split('.').reverse().join('-')
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({
    defaultValues: {
      phone: { value: '', format: '' },
      deadline: getValidDate(),
    },
    resolver: yupResolver(readyAdSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const [isDataSent, setIsDataSent] = useState(false)

  const onApply = ({ link, price, phone, deadline }) => {
    const payload = {
      link: link,
      price: price,
      phone: phone.value,
      date: fromatDateString(deadline),
    }

    axios
      .post(
        process.env.REACT_APP_BACKEND_API + `other/orderPersonalMarketing`,
        payload,
        { withCredentials: true }
      )
      .then(res => {
        if (res.data.ok) setIsDataSent(true)
      })
      .catch(error => {
        console.log(error.toJSON())
      })

    /* amo api
    await axios.post('https://supportbloggeryio.amocrm.ru/api/v4/leads', {
      name: 'тест "Реклама под ключ"',
      price: price,
      _embedded: {
        contacts: [
          {
            first_name: '',
            custom_fields_values: [
              {
                id: 1645781, //ФИО
                value: `${userData.lastName} ${userData.firstName} ${userData.middleName}`
              },
              {
                field_id: 1309911, //email
                values: [
                  {
                    enum_id: 1413801,
                    value: userData.email
                  }
                ]
              },
              {
                field_id: 1309909, //phone
                values: [
                  {
                    enum_id: 1413789,
                    value: phone || userData.phone
                  }
                ]
              }
            ]
          }
        ],
      },
      custom_fields_values: [
        {
          field_id: 1310803, // ссылка на товар
          value: url || '',
        },
        {
          field_id: 1515921, // дата размещения
          value: deadline || ''
        }
      ]

    })
    */
  }

  // useEffect(() => {

  // validateField(phone.value, [required, { validator: validNumberFormat, args: [phone.format] }]).then(error => {
  //   setPhone({ ...phone, error: error })
  // })
  // validateField(price.value, [required, isNumber, notZero]).then(error => {
  //   setPrice({ ...price, error: error })
  // })

  //}, [phone.value, phone.format, price.value])

  console.log(watch())

  return (
    <div
      className={'modal modal-blur fade' + (show ? 'show' : '')}
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content"
          style={{ border: '2px solid var(--main-purple)', borderRadius: '0' }}>
          <form
            onSubmit={handleSubmit(onApply)}
            className="modal-body py-6 px-3 px-sm-6">
            <h3
              style={{ marginBottom: '0.75rem' }}
              className={'h2-title text-center text-uppercase ' + cls.title}>
              <button
                style={{ left: 0 }}
                type="button"
                onClick={e => {
                  //e.preventDefault()
                  onHide()
                  setIsDataSent(false)
                }}>
                <BackArrowButton />
              </button>
              реклама под ключ
            </h3>

            {!isDataSent ? (
              <>
                <div className="text-14-regular text-center">
                  Заполните заявку и менеджер свяжется с вами
                </div>
                <div className="mt-4">
                  <div className="mb-3">
                    <NewInput
                      type="text"
                      className="form-control"
                      placeholder="Ссылка на ваш продукт"
                      {...getFieldProps('link')}
                    />
                  </div>

                  <div className="mb-3">
                    <MyPhoneInputDub
                      value={watch('phone').value}
                      onChange={(number, numberData) => {
                        setValue('phone', {
                          value: number,
                          format: numberData.format,
                        })
                        trigger('phone')
                      }}
                      {...getFieldProps('phone')}
                    />
                  </div>

                  <div className="mb-3">
                    <PriceInput
                      placeholder="Бюджет на рекламу"
                      {...getFieldProps('price')}
                    />
                  </div>

                  <div>
                    <DateInput
                      error={errors?.deadline?.message}
                      value={watch('deadline')}
                      onChange={e => {
                        setValue('deadline', e.target.value)
                        trigger('deadline')
                      }}
                    />
                  </div>
                  <div className={'justify-content-center d-flex'}>
                    <Button
                      disabled={!isValid}
                      style={{
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                        marginTop: '2.5rem',
                      }}
                      className="btn btn_primary text-14-bold"
                      type="submit">
                      отправить заявку
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-16-regular">
                  <span className="text-gradient ">Ваша заявка отправлена</span>
                  <div className="mt-1">
                    Менеджер с Вами свяжется в течение 48 часов
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReadyAdModal

import React, { useEffect } from 'react'

import {
  isNumber,
  required,
  validateField,
  validINN,
  validPhysicalINN,
} from '../../utils/validation'
import { BackArrowButton } from './backArrowButton'
import cls from './modals.module.scss'
import { updateINN } from '../../services/user.service'
import { toast, Toaster } from 'react-hot-toast'
import payWidget from '../../services/payWidget'
import Button from '../UI/button/button'
import { useNavigate } from 'react-router-dom'
import { payDealFromAccount } from '../../services/offers.service'
import { translateServerMsg } from '../../utils/functional'

export const PaymentModal = ({ deal, show, onHide }) => {
  const navigate = useNavigate()
  const onLegalPay = () => {
    navigate(`/createinvoice?amount=${deal.price}&orderId=${deal._id}`)
  }
  const onAccountPay = () => {
    payDealFromAccount(deal._id).then(data => {
      console.log(data);
      if(data.ok) {
        onHide();
        navigate(0);
      } else {
        toast.error(translateServerMsg(data.message));
      }
    }).catch(error => {
      toast.error(translateServerMsg(error.response.data.message));
    })
  }

  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className=" modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content bordered bordered--thick bg-white pe-auto ">
          <div className="align-items-center d-flex column ">
            <h3
              className={
                'w-100 modal_title  text-uppercase text--page-title text-center ' +
                cls.title
              }
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onHide()
                }}
              >
                <BackArrowButton />
              </button>
              Оплата предложения
            </h3>
            <div className="w-100 column align-items-center text-center d-flex mb-40">
              <span className="text--gradient text-14-regular text-uppercase">
                Выберите способ оплаты
              </span>
              <p className="mt-3 p-3 bordered w-100 justify-content-center d-flex text-16-regular f-geometria align-items-center">
                {deal?.price}₽
              </p>
            </div>
            <div className="w-240">
              <Button
                className="btn btn_secondary mb-3 w-100"
                onClick={() => payWidget(deal)}
              >
                Оплатить картой
              </Button>
              <Button
                className="btn btn_secondary--purple mb-3 w-100"
                onClick={() => {
                  onLegalPay()
                }}
              >
                Оплатить как юрлицо
              </Button>
              <div className='w-100 text-center'>
                <a
                  className="cursor-pointer"
                  onClick={() => onAccountPay()}
                >
                  Оплатить со счёта платформы
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

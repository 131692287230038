import { Outlet, useSearchParams } from 'react-router-dom'
import { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { setUtm } from '../services/auth.service'

export const UtmProvider = ({ children, ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('utm_source')) {
      setUtm(searchParams.get('utm_source'))
    } else if (Cookies.get('utm')) {
      setUtm(Cookies.get('utm'))
    }
  }, [])

  console.log(searchParams.get('hello'))

  return <Outlet />
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ExternalLink } from 'tabler-icons-react'
import AuthService from '../../../services/auth.service'
import { useState } from 'react'
import { useEffect } from 'react'

import './bloggerCard.scss'
import Button from '../button/button'
import { formatNumber } from '../../../utils/functional'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as VKIcon } from '../../../assets/icons/social/vk.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/icons/social/instagram.svg'
import { ReactComponent as TelegramIcon } from '../../../assets/icons/social/telegram.svg'
import { ReactComponent as YouTubeIcon } from '../../../assets/icons/social/youtube.svg'

const calcStats = (followers, storiesCoverage, minPrice) => {
  const stats = {}

  if (storiesCoverage <= 1) {
    stats.er = Math.round(storiesCoverage * 1000) / 10 || ''
    stats.views = Math.round((stats.er / 100) * followers) || ''
    stats.cpv = isFinite(minPrice)
      ? Math.round((minPrice / stats.views) * 10) / 10
      : ''
  } else {
    stats.views = storiesCoverage || ''
    stats.er = Math.round((stats.views / followers) * 100) / 100 || ''
    stats.cpv = isFinite(minPrice)
      ? Math.round((minPrice / stats.views) * 10) / 10
      : ''
  }

  return stats
}

const BloggerCard = ({ card, i }) => {
  let navigate = useNavigate()
  const avatarsEnum = {
    vk: <VKIcon />,
    instagram: <InstagramIcon />,
    telegram: <TelegramIcon />,
    youtube: <YouTubeIcon />,
  }

  const [minPrice, setMinPrice] = useState(() =>
    Math.min(...Object.values(card.pricing).filter(e => e > 0 && e))
  )
  const [bloggerStats, setBloggerStats] = useState(() =>
    calcStats(
      card.followers,
      card.storiesCoverage,
      Math.min(...Object.values(card.pricing).filter(e => e > 0 && e))
    )
  )

  const createDeal = id => {
    navigate(`/offer/to/${id}`)
  }

  useEffect(() => {
    if (!isFinite(minPrice)) setMinPrice(false)
  }, [minPrice])

  return (
    <div
      className={`blogger-card ${
        i % 2 ? 'light' : 'purple'
      } w-100 row row-deck justify-content-between align-items-center p-0 p-md-0 m-0`}>
      <div className="title position-relative  border-r_purple pb-md-3 pb-lg-2 col-sm-3 p-2  ">
        <div
          className="avatar avatar-md"
          style={{
            backgroundImage: `url(${
              card.avatarFile
                ? process.env.REACT_APP_BACKEND_API + 'file/' + card.avatarFile
                : card.avatar
                ? card.avatar
                : '/avatar.png'
            })`,
          }}></div>
        <div className="w-100 column d-flex justify-content-between">
          <div>
            <h4
              title={card.username}
              className="w-100 mb-0 text-14-bold text-start text-truncate">
              <a href={'/blogger/' + card._id}>
                {card.platform !== 'telegram'
                  ? '@' + card.username
                  : card.fullname}
              </a>
            </h4>
            <div
              title={card.fullname}
              style={{ cursor: 'default' }}
              className="fullname mt-1 text-12-regular text-truncate">
              {card.platform !== 'telegram' && card.fullname}
            </div>
          </div>

          <div className=" text-wrap">
            {card.themes.slice(0, 1).join(', ')}
            {card.themes.length > 1 && (
              <a
                type="button"
                className="ms-1 badge badge-outline text-muted border fw-normal badge-pill"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={card.themes.slice(1).join(', ')}>
                +{card.themes.length - 1}
              </a>
            )}
          </div>
        </div>
        <div className="ps-3">
          <div className="position-absolute platform-icon ">
            {avatarsEnum[card?.platform]}
          </div>
        </div>
      </div>

      <div className="stats mb-3 mb-lg-0   col-sm-3 ">
        <div className="column align-items-center justify-content-between">
          <span className="text-muted">Подписчики:</span>
          <span className="purple d-flex">
            <svg
              className="me-1"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.2634 1.09084C9.56172 0.387386 8.5817 0 7.5 0C6.41253 0 5.42926 0.385042 4.73085 1.08415C4.02486 1.79095 3.68087 2.75155 3.76164 3.78882C3.92173 5.83523 5.59873 7.49996 7.5 7.49996C9.40127 7.49996 11.0754 5.83557 11.238 3.78949C11.3198 2.76159 10.9737 1.803 10.2634 1.09084ZM13.846 14.9999H1.15402C0.987895 15.0019 0.823376 14.9695 0.672434 14.905C0.521493 14.8406 0.387925 14.7457 0.281448 14.6273C0.0470799 14.3671 -0.0473886 14.0119 0.0225614 13.6526C0.32688 12.085 1.27661 10.7681 2.76936 9.84369C4.09553 9.02305 5.77541 8.57138 7.5 8.57138C9.22459 8.57138 10.9045 9.02339 12.2306 9.84369C13.7234 10.7678 14.6731 12.0846 14.9774 13.6523C15.0474 14.0115 14.9529 14.3668 14.7186 14.6269C14.6121 14.7454 14.4786 14.8404 14.3276 14.9049C14.1767 14.9694 14.0121 15.0019 13.846 14.9999Z"
                fill="#515CD1"
              />
            </svg>
            {formatNumber(card.followers)}
          </span>
        </div>

        <div
          className={`column align-items-center justify-content-between' ${
            card.storiesCoverage ? '' : ' d-none'
          }`}>
          <span className="text-muted">Реакции:</span>
          <span className="pink">
            {card.views
              ? formatNumber(card.views)
              : formatNumber(bloggerStats.views)}
          </span>
        </div>
        <OverlayTrigger
          overlay={
            <Tooltip>
              ER (engagement rate, коэффициент вовлеченности)- это показатель,
              который позволяет определить эффективность рекламного объявления
              путем измерения активности и вовлеченности пользователей.
            </Tooltip>
          }>
          <div
            className={`column align-items-center justify-content-between' ${
              card.storiesCoverage ? '' : ' d-none'
            }`}>
            <span
              className="text-muted"
              data-bs-toggle="tooltip"
              data-bs-placement="top">
              ER
            </span>
            <span className="purple">{bloggerStats.er}%</span>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip>
              CPV (cost per view) - это метрика, используемая для определения
              стоимости каждого просмотра контента блога со стороны
              пользователей.
            </Tooltip>
          }>
          <div
            className={`column align-items-center justify-content-between ${
              card.storiesCoverage ? '' : ' d-none'
            }`}>
            <span className="text-muted">CPV:</span>
            <span className="pink">
              {card.views
                ? Math.round((minPrice / card.views) * 100) / 100
                : bloggerStats.cpv}
              ₽
            </span>
          </div>
        </OverlayTrigger>
      </div>

      <div className="price p-md-2 p-0 pb-3 border-l_purple column align-items-center justify-content-center ">
        <Button
          style={{ maxWidth: '150px' }}
          className="btn mb-1 btn_primary text-12-bold py-2"
          onClick={() => createDeal(card._id)}>
          ПРЕДЛОЖИТЬ
          <br />
          РЕКЛАМУ
        </Button>

        <div className="f-geometria text-16-bold">
          {minPrice
            ? minPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + '₽'
            : 'цена не указана'}
        </div>
      </div>
    </div>
  )
}

export default BloggerCard

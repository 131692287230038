import { useState } from 'react'

export const platformRadio = [
  { id: 'platform_1', value: 'any', label: 'Любая' },
  { id: 'platform_2', value: 'instagram', label: 'Instagram*' },
  { id: 'platform_5', value: 'telegram', label: 'Telegram' },
  { id: 'platform_4', value: 'vk', label: 'VK' },
  { id: 'platform_3', value: 'youtube', label: 'Youtube' },
]

export const ageRadio = [
  { id: 'age_1', value: '18-', label: 'до 18' },
  { id: 'age_2', value: '18-24', label: '18-24' },
  { id: 'age_3', value: '25-34', label: '25-34' },
  { id: 'age_4', value: '35-44', label: '35-44' },
  { id: 'age_5', value: '45-54', label: '45-54' },
  { id: 'age_6', value: '55-64', label: '55-64' },
  { id: 'age_7', value: '65+', label: '65+' },
]

export const genderRadio = [
  { id: 'gender_2', value: 'male', label: 'Мужская' },
  { id: 'gender_3', value: 'female', label: 'Женская' },
  { id: 'gender_1', value: 'any', label: 'Смешанная' },
]

export const profileAccTypeRadio = [
  {
    id: 'reg-acc-type_1',
    value: 1,
    label: 'Блогер',
  },
  { id: 'reg-acc-type_2', value: 2, label: 'Рекламодатель' },
  { id: 'reg-acc-type_3', value: 3, label: 'Агентство' },
]

export const profileAccTypeRadioExt = [
  {
    id: 'reg-acc-type_1',
    value: 1,
    label: (
      <div className="h-100  d-flex column align-items-center">
        <h3 className="mb-0 text-14-bold f-geometria d-flex justify-content-center text-uppercase mb-0">
          <span>Блогер</span>
        </h3>
        <div className="w-100">
          <p className="text-14-regular col-md-9 m-0">
            Хочу получать рекламные заказы
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 'reg-acc-type_2',
    value: 2,
    label: (
      <div className="h-100 d-flex column align-items-center">
        <h3 className="mb-0 text-14-bold f-geometria text-uppercase mb-0">
          Рекламодатель
        </h3>
        <div className="w-100">
          <p className="text-14-regular col-md-8 m-0">
            Хочу найти исполнителей
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 'reg-acc-type_3',
    value: 3,
    label: (
      <div className="h-100 d-flex column align-items-center ">
        <h3 className="text-14-bold f-geometria text-uppercase mb-0">
          Агентство
        </h3>
        <p className="flex-grow-1"></p>
      </div>
    ),
  },
]

export const accTypeRadio = [
  { id: 'acc-type_1', value: 'any', label: 'Любой' },
  { id: 'acc-type_2', value: 'personal', label: 'Персональный' },
  { id: 'acc-type_3', value: 'public', label: 'Публичный' },
  { id: 'acc-type_4', value: 'publicperson', label: 'Публичная личность' },
]

export const legalTypeRadio = [
  {
    id: 'legal-type_1',
    value: 'LEGAL_TYPE_INDIVIDUAL',
    label: 'Самозанятый/ Физическое лицо',
  },
  {
    id: 'legal-type_2',
    value: 'LEGAL_TYPE_ENTREPRENEUR',
    label: 'Индивидуальный предприниматель',
  },
  { id: 'legal-type_3', value: 'LEGAL_TYPE_LEGAL', label: 'Юридическое лицо' },
]

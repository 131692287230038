import React from 'react'
///import tableStatsButton.module.scss///
import cls from './icons.module.scss'

const tableStatsButton = () => {
  return (
    <svg
      className={cls.stats}
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.26923 19H0V11.4H3.26923V19ZM12.4231 19H9.15385V7.6H12.4231V19ZM17 19H13.7308V3.16667H17V19ZM7.84615 19H4.57692V0H7.84615V19Z"
        fill="#FF5FAB"
      />
    </svg>
  )
}

export default tableStatsButton

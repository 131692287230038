import React from 'react'
import { DropzoneComponent } from 'react-dropzone-component'
import cls from './filesUploader.module.scss'
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server'
import { useState, useEffect } from 'react'

const FilesUploader = ({
  setFiles,
  initialFiles,
  style,
  border,
  page = 'common',
  setFilesInProgress,
  acceptedFiles = 'video/mp4,video/*,image/jpeg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
}) => {
  const [myDropzone, setMyDropzone] = useState(null)
  const [alreadyShownThumbs, setAlreadyShownThumbs] = useState([]) // Костыль

  const [fileUploading, setFileUploading] = useState([])

  var componentConfig = {
    iconFiletypes: [
      '.jpg',
      '.png',
      '.gif',
      '.pdf',
      '.doc',
      '.docx',
      '.ppt',
      'pptx',
    ],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_API + `upload`,
    withCredentials: true,
    headers: {},
  }
  var djsConfig = {
    // previewTemplate: renderToStaticMarkup(
    //     <div class="dz-preview \">
    //         <div class="dz-image">
    //             <img data-dz-thumbnail={true}/>
    //             <div class="dz-details">
    //                 <div class="dz-size"><span data-dz-size=""><strong>95.2</strong> KB</span></div>    <div class="dz-filename"><span data-dz-name="">garri-potter.jpg</span></div>  </div>  <div class="dz-progress">
    //             <span class="dz-upload" data-dz-uploadprogress=""></span>
    //
    //         </div>
    //             <div class="dz-error-message">
    //             <span data-dz-errormessage="">X</span>
    //         </div>
    //             <div class="dz-success-mark">
    //                 V
    //         </div>
    //         <div class="dz-error-mark">
    //         </div>
    //         </div>
    //         </div>),
    autoProcessQueue: true,
    addRemoveLinks: true,
    withCredentials: true,
    dictDefaultMessage: '📂 Перетащите сюда файлы или кликните для загрузки',
    acceptedFiles: acceptedFiles,
    params: {
      page: page,
    },
  }

  // Инициализация компонента
  const initCallback = dropzone => {
    setMyDropzone(dropzone)
  }

  useEffect(() => {
    //console.log("DROPZONE FILES",files)
    //console.log("DZ ", myDropzone)
    // Добавляем файлы, требуемые для отображения в компоненте
    if (myDropzone && initialFiles) {
      for (let i = 0; i < initialFiles.length; i++) {
        //console.log("DROPZONE FILE ", files[i]);
        console.log(alreadyShownThumbs, initialFiles[i].name)
        if (!alreadyShownThumbs.includes(initialFiles[i].name)) {
          // Очень костыльный фикс - не добавлять файлы в отображение, если они уже добавлены (без него каждое изменеие стейта удваивает файлы)
          console.log(alreadyShownThumbs, initialFiles[i].name)
          let file = {
            url: process.env.REACT_APP_BACKEND_API + `file/` + initialFiles[i],
            name: initialFiles[i],
          }
          myDropzone.emit('addedfile', file)
          myDropzone.emit('thumbnail', file, file.url)
          myDropzone.emit('complete', file)
          setFileUploading([]) //После получения изначальных файлов, инициализирую пустой массив, чтобы компонент не думал, что файлы в процессе загрузки

          // TODO: BUG - при добавлении нового файла, с компонента выше прилетает обновленный массив files - с новым файлом. (добавленный файл при этом так же локально хранится в компоненте дропзоны), в связи с этим 2 раза рисуется файл

          setAlreadyShownThumbs(pr => [...pr, initialFiles[i]])
        }
      }
    }
  }, [initialFiles])

  useEffect(() => {
    console.log('Files in progress', fileUploading)
    if (setFilesInProgress) setFilesInProgress(fileUploading)
  }, [fileUploading])

  var uploadEventHandlers = {
    init: initCallback,
    addedfile: file => {
      console.log('Started file uploading', file)
      setFileUploading(prevFiles => [...prevFiles, file.name])
    },
    success: async file => {
      const fileJson = await JSON.parse(file.xhr.response)
      console.log('Success', file)
      setFiles(prevFiles => [...prevFiles, fileJson._id])

      //const updatedList = [...fileUploading];
      // Remove the element at the specified index
      //updatedList.splice(index, 1);
      const updatedList = fileUploading.filter(item => item !== file.name)
      // Update the state with the updated array
      setFileUploading(updatedList)
    },
    complete: async file => {
      const fileJson = await JSON.parse(file.xhr.response)
      console.log('Complete', fileJson)
      console.log('Success', await JSON.parse(file.xhr.response))
    },
    removedfile: async file => {
      console.log('removing...', file)
      console.log(file)
      // const fileJson = await JSON.parse(file.xhr.response)

      // TODO send call to _unlink_ API to delete file in DB
      setFiles(prevFiles => {
        const index = prevFiles.indexOf(file.name)
        console.log('index', index)
        prevFiles.splice(index, 1)
        return [...prevFiles]
      })

      const updatedList = fileUploading.filter(item => item !== file.name)
      // Update the state with the updated array
      setFileUploading(updatedList)
    },
    error: (file, message) => {
        console.log('Error', file, message)
        const updatedList = fileUploading.filter(item => item !== file.name)
      setFileUploading(updatedList)
    }
  }

  return (
    <div style={style} className={cls['dropzone-47']}>
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={uploadEventHandlers}
        djsConfig={djsConfig}
      />
    </div>
  )
}

export default FilesUploader

import React from 'react'
import logo from '../../assets/Bloggery.svg'
import cls from '../modals/modals.module.scss'

const AuthCard = ({ children, bottomContent }) => {
  return (
    <div className={'d-flex flex-column theme-light ' + cls.modal}>
      <div className="page page-center">
        <div className=" container container-xl align-items-center column d-flex">
          <div className="text-center mb-5 mt-5">
            <a href="https://bloggery.io" className="navbar-brand">
              <img src={logo} height="36" alt=""></img>
            </a>
          </div>
          <div className="w-100 bordered--thick bordered border-sm-none card col-md-5 mxw-550">
            <div className="card-body modal-content auth">{children}</div>
          </div>
          {bottomContent}
        </div>
      </div>
    </div>
  )
}

export default AuthCard

import React, { useEffect, useState } from 'react'
import {
  Link,
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  deleteBlogger,
  getBloggerById,
  putBlogger,
} from '../../services/blogger.service'
import { toast, Toaster } from 'react-hot-toast'
import Navbar from '../../components/UI/navBar/navbar'
import trashIcon from '../../assets/icons/trash.svg'
import PageHeader from '../../components/pageHeader/page-header'
import cls from '../../scss/components.scss'
import Form from 'react-validation/build/form'
import Select, { ControlledSelect } from '../../components/UI/newInput/select'
import Bordered from '../../components/UI/bordered/bordered'
import DateInput from '../../components/UI/newInput/dateInput'
import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'
import FilesUploader from '../../components/UI/FilesUploader/filesUploader'
import Button from '../../components/UI/button/button'
import TextArea from '../../components/UI/newInput/textArea'
import { CityInput } from '../../components/UI/newInput/cityInput'
import { ReactComponent as VKIcon } from '../../assets/icons/social/vk.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/social/instagram.svg'
import { ReactComponent as TelegramIcon } from '../../assets/icons/social/telegram.svg'
import { ReactComponent as YouTubeIcon } from '../../assets/icons/social/youtube.svg'

import MySelect from '../../components/UI/newInput/select'
import { themes } from '../../utils/consts'
import PriceInput from '../../components/UI/newInput/priceInput'
import { notEmptyArray, required, validateField } from '../../utils/validation'
import {
  parseFormObj,
  setKeyError,
  setKeyValue,
  validateFormObj,
} from '../../utils/formData'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { bloggerSchema } from '../../utils/schemas'
import useFormField, { useFormCheckbox } from '../../hooks/useField'
import { Collapse } from 'react-bootstrap'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { ageRadio, genderRadio } from '../../utils/radioConsts'
import { ConfirmModal } from '../../components/modals/confirmModal'
import plusIcon from '../../assets/icons/plus.svg'
import policy from '../../assets/privacy_policy.pdf'
import { Blogger, BloggerSchema } from '../../types/abstract'
///import editBlogger.module.scss///

const EditBlogger = () => {
  const navigate: NavigateFunction = useNavigate()
  const [screenshots, setScreenshots] = useState<string[]>([])
  const [initialScreenshots, setInitialScreenshots] = useState<string[]>([])
  const [screenshotsInProgress, setScreenshotsInProgress] = useState<string[]>(
    []
  )
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm<BloggerSchema>({
    resolver: yupResolver(bloggerSchema),
  })

  const getFieldProps = useFormField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)

  const updateBlogger = (data: BloggerSchema) => {
    const {
      description,
      location,
      themes,
      story,
      post,
      advLayout,
      age,
      gender,
      _id,
    } = data

    console.log('data', data)

    if (screenshotsInProgress.length > 0) {
      toast.error('Подождите, пока загрузятся скриншоты')
      return
    }

    putBlogger(
      {
        id: _id,
        screenshots,
        description,
        location,
        themes: themes.map(theme => theme.value),
        pricing: { story, post, advLayout },
        gender,
        age,
      },
      id
    ).then(res => {
      if (res.status === 200) {
        console.log(res)
        navigate(`/blogger/${id}`)
      } else {
        toast.error('Произошла ошибка')
      }
    })
  }

  useEffect(() => {
    trigger()
  }, [screenshots])

  const id = useParams().id
  useEffect(() => {
    getBloggerById(id).then((res: Blogger) => {
      setValue('advLayout', res.pricing.advLayout)
      setValue('post', res.pricing.post)
      setValue('story', res.pricing.story)
      setValue('description', res.description)
      setValue('location', res.location)
      setValue(
        'themes',
        res.themes.map(item => {
          return { label: item, value: item }
        })
      )
      setValue('age', res.age)
      setValue('gender', res.gender)
      setValue('link', res.link)
      setScreenshots(res.screenshots)
      setInitialScreenshots(res.screenshots)
      setValue('isPolicy', false)
    })
  }, [])

  console.log(watch())
  return (
    <div className="page ">
      <Navbar />
      <ConfirmModal
        confirmText={'Вы точно хотите удалить свой профиль?'}
        show={showDeleteModal}
        onApply={value => {
          if (value) {
            deleteBlogger(id)
              .then(() => {
                setShowDeleteModal(false)
                navigate('/blogger')
                toast.success('Блогер успешно удален')
              })
              .catch(error => {
                console.log(error)
                toast.error('Произошла ошибка')
              })
          }
        }}
      />
      <div className="mt-5  mb-5 container-xl d-flex column align-items-center ">
        <div className="col-md-8 col-9 d-flex justify-content-start column-gap-3 align-items-center mb-5 flex-wrap row-gap-3">
          <div
            className=" avatar-md bg-black avatar-99px"
            style={{ backgroundImage: `url(${watch('avatar')})` }}></div>
          <h3 className="h-100 m-0 text--page-title text-start">
            Редактирование
            <br />
            Профиля
          </h3>
        </div>
        <div className="col-9 col-md-8">
          <form onSubmit={handleSubmit(updateBlogger)}>
            <div className="card-body">
              <div>
                {/*<h3>{concreteOAuth.service} - @{concreteOAuth.username}</h3>*/}
                <div className="col ">
                  <div className="">
                    <div className="mb-3">
                      <TextArea
                        rows="4"
                        type="text"
                        placeholder="Описание аккаунта"
                        {...getFieldProps('description')}
                      />
                    </div>

                    <div className="mb-3">
                      <CityInput
                        placeholder="Локация"
                        autoComplete="off"
                        {...getFieldProps('location')}
                      />
                    </div>
                    <div className="mb-3">
                      <ControlledSelect
                        control={control}
                        placeholder="Тематика"
                        options={themes}
                        isMulti={true}
                        name={'themes'}
                      />
                    </div>

                    <div className="">
                      <div>
                        <div className="mb-2">Основная аудитория</div>
                        <div className="d-flex column-gap-2 form-label mb-4">
                          <RadioGroup
                            className="w-100"
                            name={'gender'}
                            between
                            big
                            options={genderRadio}
                            currentValue={watch('gender')}
                            getFieldProps={getFieldProps}></RadioGroup>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="mt-2 form-label">Возраст аудитории</div>
                        <RadioGroup
                          name={'age'}
                          between
                          options={ageRadio}
                          currentValue={watch('age')}
                          getFieldProps={getFieldProps}></RadioGroup>
                      </div>

                      <div>
                        <div className="d-flex justify-content-center justify-content-sm-start ">
                          <h3 className="m-0 mb-2 text-14-bold mb-sm-3  text-center text-md-start text-uppercase text--gradient">
                            Рекламные предложения
                          </h3>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="d-sm-none d-block h-line background-gradient mb-3"></div>
                        <label className="form-label d-flex justify-content-center text-uppercase">
                          <span className="text-14-bold text--purple mb-2 ">
                            Минимальная цена
                          </span>
                        </label>
                        <div className="row gy-3">
                          <div className={'col-md-4'}>
                            <PriceInput
                              placeholder={
                                watch()?.platform !== 'telegram'
                                  ? 'История'
                                  : 'Репост'
                              }
                              {...getFieldProps('story')}
                            />
                          </div>

                          <div className={'col-md-4'}>
                            <PriceInput
                              placeholder="Публикация"
                              {...getFieldProps('post')}
                            />
                          </div>

                          <div className={'col-md-4'}>
                            <PriceInput
                              placeholder="Рилс"
                              {...getFieldProps('advLayout')}
                            />
                          </div>
                        </div>
                        <p className="text--disabled mt-2 text-center text-md-start">
                          Комиссия сервиса составляет 30% от указанной суммы.{' '}
                          <span className="d-none d-md-inline">
                            Например за публикацию вы получите{' '}
                            <span>{Math.round(watch('post') * 0.7)}₽</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <label className="form-check d-flex justify-content-center mb-3">
                      <MyCheckBox {...getCheckboxProps('isPolicy')} />
                      <span className="form-check-label">
                        Согласен с{' '}
                        <a href={policy} tabIndex="-1" target="_blank">
                          политикой конфиденциальности
                        </a>
                        .
                      </span>
                    </label>
                  </div>
                  <div className="mb-3 row">
                    <label className="form-label text-16-regular">
                      Скриншот статистики аккаунта
                    </label>
                    <FilesUploader
                      setFiles={e => {
                        setScreenshots(e)
                      }}
                      initialFiles={initialScreenshots}

                      setFilesInProgress={setScreenshotsInProgress}
                      page="blogger"
                      acceptedFiles="image/jpeg,image/png,image/gif"></FilesUploader>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row align-items-center">
                  <div className="d-flex align-items-center column row-gap-5 pt-4">
                    <Button
                      className="btn btn_primary w-280"
                      type="submit"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        screenshotsInProgress.length > 0
                      }>
                      Сохранить
                    </Button>
                    {/*Вернуть когда будет эндпоинт на бэке*/}
                    {/*<Button*/}
                    {/*    type={'button'}*/}
                    {/*    onClick={() => setShowDeleteModal(true)}*/}
                    {/*    to="/blogger/create"*/}
                    {/*    className='btn w-280 btn_secondary text-14-bold'*/}
                    {/*>*/}
                    {/*    <img style={{ height: '13px' }} className="ms-1" src={trashIcon} alt='' />*/}
                    {/*    <span className="mx-auto">Удалить профиль</span>*/}
                    {/*</Button>*/}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditBlogger

// Модуль для работы с объектами формата {name: {data: value, error: error, validators: [validator]}}

import { validateField } from './validation'

export const getKeyValue = (key, obj) => {
  // Функция, принимающая в себя ключ и объект формата {name: {data: value, error: error, validators: [validator]}} и возвращающая значение по ключу
  return obj[key].data
}

export const parseFormObj = formObj => {
  // Функция, принимающая в себя объект формата {name: {data: value, error: error, validators: [validator]}} и возвращающая объект формата {name: value}
  let output = {}
  for (let key of Object.keys(formObj)) {
    output[key] = formObj[key].data
  }
  return output
}
export const setKeyValue = (key, value, setter) => {
  // Функция, принимающая в себя ключ, значение и сеттер состояния useState для работы с объектами формата {name: {data: value, error: error, validators: [validator]}}
  setter(prev => ({
    ...prev,
    [key]: { ...prev[key], data: value },
  }))
}
export const setKeyError = (key, error, setter) => {
  // Функция, принимающая в себя ключ, значение и сеттер состояния useState для работы с объектами формата {name: {data: value, error: error, validators: [validator]}}
  setter(prev => ({
    ...prev,
    [key]: { ...prev[key], error: error },
  }))
}

export const getDataArr = formObj => {
  // Функция, принимающая в себя объект формата {name: {data: value, error: error, validators: [validator]}} и возвращающая массив значений
  let output = []
  for (let key of Object.keys(formObj)) {
    output.push(formObj[key].data)
  }
  return output
}

export const isError = formObj => {
  // Функция, принимающая в себя объект формата {name: {data: value, error: error, validators: [validator]}} и возвращающая true, если в объекте есть хотя бы одна ошибка
  for (let key of Object.keys(formObj)) {
    if (formObj[key].error) {
      return true
    }
  }
  return false
}

export const validateFormObj = (
  formObj,
  errorSetter,
  submitEnabledSetter = e => {}
) => {
  // Функция, принимающая в себя объект формата {name: {data: value, error: error, validators: [validator]}}
  // и сеттер состояния useState для работы с объектами формата {name: {data: value, error: error, validators: [validator]}}
  // проходится циклом по объекту формы и валидирует все значения, передавай ошибки в errorSetter

  let set = false
  for (let key of Object.keys(formObj)) {
    setTimeout(() => {
      validateField(formObj[key].data, formObj[key].validators, false).then(
        error => {
          if (!set) {
            if (error) {
              submitEnabledSetter(false)
              set = true
            } else {
              submitEnabledSetter(true)
            }
          }
          errorSetter(key, error)
        }
      )
    }, 10)
  }
}

import React, { useEffect, useState } from 'react'
import NewInput from '../UI/newInput/newInput.tsx'
import { BackArrowButton } from './backArrowButton'
import cls from './modals.module.scss'
import { toast, Toaster } from 'react-hot-toast'
import DateInput from '../UI/newInput/dateInput'
import TextArea from '../UI/newInput/textArea'
import PriceInput from '../UI/newInput/priceInput'
import { generateTask } from '../../services/neural.service'
import { isUrl, required, validDeadline } from '../../utils/validation'
import InputError from '../UI/inputAlert/inputError'
import Button from '../UI/button/button'
import { date, number, string, object } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useField from '../../hooks/useField'

export const GenTaskModal = ({ setOutput, show, onHide }) => {
  const genTaskSchema = object().shape({
    subject: string().required('Обязательное поле'),
    price: number().required('Обязательное поле'),
    deadline: date().test(
      'validDeadline',
      'Дата не может быть раньше чем через 3 дня с этого момента',
      value => {
        return validDeadline(value)
      }
    ),
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm({
    defaultValues: { phone: { value: '', format: '' } },
    resolver: yupResolver(genTaskSchema),
  })

  const getFieldProps = useField(register, trigger, errors)

  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [exceedMessage, setExceedMessage] = useState(false)

  const onUpdate = data => {
    setIsGenerating(true)
    generateTask(data)
      .then(res => {
        console.log('yes')
        setOutput(res.data.result)
        setSubmitEnabled(false)
        onHide()
        setTimeout(() => {
          setSubmitEnabled(true)
        }, 10000)
      })
      .catch(err => {
        console.log(err)
        if (err.response.data.error === 'Exceed limit for today') {
          setExceedMessage(true)
        } else toast.error('Ошибка при генерации задания')
      })
      .finally(() => setIsGenerating(false))
  }

  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className="modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content bordered bordered--thick bg-white pe-auto ">
          <form onSubmit={handleSubmit(onUpdate)}>
            <h3
              className={
                'w-100 modal_title text-uppercase text--page-title ' + cls.title
              }
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onHide()
                }}
              >
                <BackArrowButton />
              </button>
              Генерация задания
            </h3>
            {isGenerating ? (
              <div className="spinner-border" role="status" />
            ) : (
              <>
                {/*<NewInput containerClass={'mb-3'} error={taskData.url.error} value={taskData.url.data} onChange={e => {setValue('url', e.target.value)}} placeholder={'Ваш аккаунт'}></NewInput>*/}
                <div className={'mb-3'}>
                  <NewInput
                    className={'w-100'}
                    placeholder={'Ваш товар/ услуга'}
                    {...getFieldProps('subject')}
                  />
                </div>
                <PriceInput
                  containerClass={'mb-3 w-100'}
                  unit={'₽'}
                  placeholder={'Бюджет'}
                  {...getFieldProps('price')}
                />
                <div className="mb-40 w-100">
                  <DateInput
                    label={'Дата размещения'}
                    error={errors?.deadline?.message}
                    value={watch('deadline')}
                    onChange={e => {
                      setValue('deadline', e.target.value, {
                        shouldValidate: true,
                      })
                    }}
                  ></DateInput>
                </div>
              </>
            )}
            <div className={'d-flex align-items-center column'}>
              <Button
                type="submit"
                disabled={!isValid || isGenerating}
                className={'w-240 btn btn_primary px-5'}
              >
                Сгенерировать
              </Button>
              {exceedMessage && (
                <InputError>Лимит запросов на сегодня исчерпан</InputError>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

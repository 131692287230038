import React from 'react'
import { ExternalLink } from 'tabler-icons-react'
import { Link } from 'react-router-dom'
import AuthService from '../services/auth.service'

const CampaignsCard = ({ i, card }) => {
  return (
    <div className="card mb-2">
      <div className="row g-0">
        <div className="col">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h3 className="mb-0">
                  <Link to={'/offersauction/' + card._id}> {card.title} </Link>
                </h3>
              </div>
              <div className="col-auto fs-3 text-green">
                {card.user == AuthService.getCurrentUser().id ? (
                  <button className={'btn btn-sm btn-outline-primary'}>
                    Редактировать
                  </button>
                ) : (
                  <button className={'btn btn-sm btn-outline-success'}>
                    Принять
                  </button>
                )}
              </div>
            </div>

            <div className="row align-items-end">
              <div className="col-md">
                <div className="mt-3 list-inline list-inline-dots mb-0 text-muted d-sm-block d-none">
                  <div className="list-inline-item">
                    {card.platform}{' '}
                    <a href="">
                      <ExternalLink />
                    </a>
                  </div>
                  <div className="list-inline-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-inline"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="11" r="3"></circle>
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                    {card.geo}
                  </div>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="mt-3">
                  <div className="list-inline-item display-6">
                    {card.price}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-inline icon-tabler-currency-rubel"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M8 19v-14h6a3 3 0 0 1 0 6h-8"></path>
                      <path d="M14 15h-8"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignsCard

import ad from '../assets/navbarIcons/ad.svg'
import bloggers from '../assets/navbarIcons/bloggers.svg'
import home from '../assets/navbarIcons/home.svg'
import instructions from '../assets/navbarIcons/instructions.svg'
import links from '../assets/navbarIcons/links.svg'
import templates from '../assets/navbarIcons/templates.svg'

export const navElems = [
  {
    name: 'Главная',
    link: '/',
    icon: home,
  },
  {
    name: 'Блогеры',
    link: '/bloggers',
    icon: bloggers,
  },
  {
    name: 'Рекламные предложения',
    link: '/offersauctions',
    icon: ad,
  },
  /*{
		name: 'Шаблоны',
		link: '/drafts',
		icon: templates
	},*/
  {
    name: 'Ссылки',
    link: '/links',
    icon: links,
  },
  /*{
		name: 'Инструкция',
		link: '/support',
		icon: instructions}*/
]

import moment from 'moment'

export function formatDate(date) {
  return moment(date).utc().format('DD.MM.YYYY')
}

export function formatDateForInput(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function formatHour(hour) {
  return String(hour).length === 1 ? '0' + hour : hour
}

export function formatToUTC(date) {
  return moment(date).utc().add(moment(date).utcOffset(), 'm').format()
  //moment(date).format('YYYY-MM-DDTHH:mm:ss')
}

import telegramIcon from '../assets/icons/social/telegram.svg'
import youtubeIcon from '../assets/icons/social/youtube.svg'
import instagramIcon from '../assets/icons/social/instagram.svg'
import vkIcon from '../assets/icons/social/vk.svg'

export const themes = [
  { value: 'Digital и IT', label: 'Digital и IT' },
  { value: 'Экономика и Финансы', label: 'Экономика и Финансы'},
  { value: "Психология", 'label': 'Психология'},
  { value: 'Авто и транспорт', label: 'Авто и транспорт' },
  { value: 'Анимация', label: 'Анимация' },
  { value: 'Аниме и Комиксы', label: 'Аниме и Комиксы' },
  { value: 'Архитектура', label: 'Архитектура' },
  { value: 'Бизнес', label: 'Бизнес' },
  { value: 'Блоги', label: 'Блоги' },
  { value: 'Гаджеты', label: 'Гаджеты' },
  { value: 'Дети и Семья', label: 'Дети и Семья' },
  { value: 'Дизайн и Графика', label: 'Дизайн и Графика' },
  { value: 'Для взрослых (18+)', label: 'Для взрослых (18+)' },
  { value: 'Животные и Природа', label: 'Животные и Природа' },
  { value: 'Здоровье', label: 'Здоровье' },
  { value: 'Игры', label: 'Игры' },
  { value: 'Искусство и фото', label: 'Искусство и фото' },
  { value: 'Кино и Сериалы', label: 'Кино и Сериалы' },
  { value: 'Красота и мода', label: 'Красота и мода' },
  { value: 'Криптовалюты и блокчейн', label: 'Криптовалюты и блокчейн' },
  { value: 'Кулинария', label: 'Кулинария' },
  { value: 'Личная страница', label: 'Личная страница' },
  { value: 'Маркетинг и Реклама', label: 'Маркетинг и Реклама' },
  { value: 'Медицина', label: 'Медицина' },
  { value: 'Музыка', label: 'Музыка' },
  { value: 'Мультфильмы', label: 'Мультфильмы' },
  { value: 'Наука и технологии', label: 'Наука и технологии' },
  { value: 'Новости', label: 'Новости' },
  { value: 'Обзоры', label: 'Обзоры' },
  { value: 'Образование', label: 'Образование' },
  { value: 'Путешествия', label: 'Путешествия' },
  { value: 'Развлечения и Юмор', label: 'Развлечения и Юмор' },
  { value: 'Ремонт и строительство', label: 'Ремонт и строительство' },
  { value: 'Социальное', label: 'Социальное' },
  { value: 'Спорт', label: 'Спорт' },
  { value: 'Хобби и Увлечения', label: 'Хобби и Увлечения' },
  { value: 'Хэндмэйд и DIY', label: 'Хэндмэйд и DIY' },
  { value: 'Другое', label: 'Другое' },
] //.sort((a, b) => { return a.value > b.value ? 1 : a === b ? 0 : -1 })

export const socialIcons = {
  telegram: telegramIcon,
  youtube: youtubeIcon,
  instagram: instagramIcon,
  vk: vkIcon,
}

import React, { useEffect } from 'react'
import NewInput from '../UI/newInput/newInput.tsx'
import { Button } from 'react-bootstrap'
import {
  isNumber,
  required,
  validateField,
  validINN,
  validPhysicalINN,
} from '../../utils/validation'
import { BackArrowButton } from './backArrowButton'
import cls from './modals.module.scss'
import { updateINN } from '../../services/user.service'
import { toast, Toaster } from 'react-hot-toast'
import { object, string } from 'yup'
import isURL from 'validator/es/lib/isURL'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useField from '../../hooks/useField'

export const INNModal = ({ onApply, show, onHide }) => {
  const INNSchema = object().shape({
    INN: string()
      .required()
      .test('validINN', 'Неверный ИНН', value => {
        return validINN(value)
      }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    defaultValues: {
      slug: (Math.random() + 1).toString(36).substring(7),
    },
    resolver: yupResolver(INNSchema),
  })

  const getFieldProps = useField(register, trigger, errors)
  const onUpdate = ({ INN }) => {
    updateINN({ inn: INN })
      .then(res => {
        console.log('yes')
        onApply()
      })
      .catch(err => {
        toast.error('Ошибка при изменении ИНН')
      })
  }
  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className=" modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content bordered bordered--thick bg-white pe-auto ">
          <form onSubmit={handleSubmit(onUpdate)} className="">
            <h3
              className={
                'w-100 modal_title text-uppercase text--page-title text-center ' +
                cls.title
              }
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onHide()
                }}
              >
                <BackArrowButton />
              </button>
              Указать ИНН
            </h3>
            <p className="text-14-regular text-center mb-6">
              Согласно закону “О рекламе”, мы обязаны маркировать рекламные
              материалы
            </p>
            <p className="text-14-regular text-center mb-2 text-gradient">
              Укажите свой ИНН, чтобы мы передавали всю информацию за вас
            </p>
            <div className="mb-3">
              <NewInput placeholder={'ИНН'} {...getFieldProps('INN')} />
            </div>

            <div className={'d-flex justify-content-center'}>
              <Button
                type="submit"
                disabled={!isValid}
                className={'px-6 btn btn_primary '}
              >
                Сохранить
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

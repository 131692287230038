import React from 'react'

import './buttonSwitch.scss'

const ButtonSwitch = ({ setValue, value }) => {
  return (
    <span className="button-switch">
      <input
        type="checkbox"
        id="btn_toggle"
        checked={value}
        onChange={() => setValue(prevValue => !prevValue)}
      />
      <label htmlFor="btn_toggle" />
    </span>
  )
}

export default ButtonSwitch

import Select from "react-select";
import {ReactComponent as SortIcon} from '../../../assets/icons/sort.svg'

export default function SortSelect({ options, onChange, error, ...props }) {
    return (
        <Select
            placeholder={!props.placeholder ? 'Выбрать' : props.placeholder}
            options={options}
            isMulti={false}
            isDisabled={false}
            defaultValue={props.defaultValue}
            onChange={onChange}
            {...props}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    neutral0: '#ffffff', // input bg color
                    neutral20: '#515CD1', // input border color
                    //neutral30: '#243049',
                    neutral80: '#1d273b', // input font color
                    primary25: '#206bc4', // dropdown on hover color
                    primary: '#206bc4', // dropdown chosen bg color, input bg on focus color
                },
            })}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    padding: '0',
                    border: 'none',
                    backgroundColor: 'rgba(96, 109, 255, 0.15)',
                    boxShadow: 'none',
                    width: '195px',
                    cursor: 'pointer',
                    minHeight: '28px',
                }),
                menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: '#ffffff',
                    border: '1px solid #515CD1',
                    marginTop: '0',
                    padding: '16px 0',
                }),
                option: (provided, state) => ({
                    ...provided,
                    color: '#8C8C8C',
                    backgroundColor: 'transparent !important',
                    cursor: 'pointer',
                    textAlign: 'center',
                    padding: '0',
                    margin: '0',
                    marginBottom: '8px',
                    '&:hover': {
                        color: '#515CD1',
                        backgroundColor: 'transparent',
                        fontWeight: 'bold',
                    },
                    '&:last-child': {
                        marginBottom: '0',
                    }
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'none',
                }),
                //внутреннние отступы для поля селекта
                valueContainer: (provided, state) => ({
                    ...provided,
                    height: '28px',
                    padding: '0 24px 0 40px',
                    lineHeight: '14.4px',
                    fontSize: '12px',
                    color: '#8C8C8C',

                }),
                input: (provided, state) => ({
                    ...provided,
                    margin: '0',
                    lineHeight: '16px',

                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '15px',
                    width: '12px',
                    position: 'absolute',
                    left: '16px',
                    top: 'calc(50% - 7.5px)',

                }),
            }}
            components={{
                DropdownIndicator: () => {return <SortIcon/>},
            }}
        />
    )
}
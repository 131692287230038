import React from 'react'
import { formatNumber } from '../../../utils/functional'
import Button from '../button/button'
import { Link, useNavigate } from 'react-router-dom'
import { socialIcons } from '../../../utils/consts'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const DashboardBloggerCard = ({ i, card }) => {
  const navigate = useNavigate()
  return (
    <div
      className={`card dashboard-card dashboard-card_blogger ${
        i % 2 ? 'light' : 'purple'
      }`}
      onClick={() => navigate('/blogger/' + card._id)}
    >
      <div className="card-body d-flex p-0 justify-content-center">
        <div className="w-100 row row-deck flex-nowrap justify-content-between align-items-center">
          <div className="first-block p-0 h-100 d-flex align-items-center">
            <img
              className="social-icon"
              src={socialIcons[card.platform]}
              alt=""
            />
            <div
              className="avatar avatar-md"
              style={{
                backgroundImage: `url(${
                  card.avatarFile
                    ? process.env.REACT_APP_BACKEND_API +
                      'file/' +
                      card.avatarFile
                    : card.avatar
                    ? card.avatar
                    : '/avatar.png'
                })`,
              }}
            ></div>
            <div className="column w-100 text-truncate h-100 ms-3">
              <h4 title={card.username} className="mb-0 w-100 text-truncate">
                <a className="blogger-username" href={'/blogger/' + card._id}>
                  {'@' + card.username}
                </a>
              </h4>
              <div
                title={card.fullname}
                style={{ cursor: 'default' }}
                className="fullname mt-1 text-truncate"
              >
                {card.fullname}
              </div>
              <div className="mt-auto text-wrap text-muted">
                {card.themes.slice(0, 1).join(', ')}
                {card.themes.length > 1 && (
                  <a
                    type="button"
                    className="ms-1 badge badge-outline text-muted border fw-normal badge-pill"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={card.themes.slice(1).join(', ')}
                  >
                    +{card.themes.length - 1}
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="middle-block h-100 d-flex justify-content-evenly align-items-center ">
            <div className="column align-items-center ms-2">
              <span className="text-muted">Подписчики:</span>
              <span className="purple d-flex">
                <svg
                  className="me-1"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2634 1.09084C9.56172 0.387386 8.5817 0 7.5 0C6.41253 0 5.42926 0.385042 4.73085 1.08415C4.02486 1.79095 3.68087 2.75155 3.76164 3.78882C3.92173 5.83523 5.59873 7.49996 7.5 7.49996C9.40127 7.49996 11.0754 5.83557 11.238 3.78949C11.3198 2.76159 10.9737 1.803 10.2634 1.09084ZM13.846 14.9999H1.15402C0.987895 15.0019 0.823376 14.9695 0.672434 14.905C0.521493 14.8406 0.387925 14.7457 0.281448 14.6273C0.0470799 14.3671 -0.0473886 14.0119 0.0225614 13.6526C0.32688 12.085 1.27661 10.7681 2.76936 9.84369C4.09553 9.02305 5.77541 8.57138 7.5 8.57138C9.22459 8.57138 10.9045 9.02339 12.2306 9.84369C13.7234 10.7678 14.6731 12.0846 14.9774 13.6523C15.0474 14.0115 14.9529 14.3668 14.7186 14.6269C14.6121 14.7454 14.4786 14.8404 14.3276 14.9049C14.1767 14.9694 14.0121 15.0019 13.846 14.9999Z"
                    fill="#515CD1"
                  />
                </svg>
                {formatNumber(card.followers)}
              </span>
            </div>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Суммарное количество предложений, поступивших на данный
                  аккаунт.
                </Tooltip>
              }
            >
              <div
                className={`column align-items-center justify-content-between me-2`}
              >
                <span className="text-muted">Заказы:</span>
                <span className="pink"> {card.offerDealsCount || '0'} </span>
              </div>
            </OverlayTrigger>
          </div>

          {/*
                    <div className='last-block column fw-bold h-100 align-items-center justify-content-center'>
                        <Button
                            onClick={() => navigate('/blogger/' + card._id)}
                            style={{ fontSize: '14px', width: '90%' }}
                            className='btn btn_primary'
                        >
                            Перейти
                        </Button>
                    </div>
                    */}
        </div>
      </div>
    </div>
  )
}

export default DashboardBloggerCard

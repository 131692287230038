import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import React, { useRef, useEffect, useState } from 'react'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import axios from 'axios'

import toast, { Toaster } from 'react-hot-toast'
import {
  ParamKeyValuePair,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import Range from 'rc-slider'
import 'rc-slider/assets/index.css'

import InputCheckBox from '../../components/UI/input/inputCheckBox'

import policy from '../../assets/privacy_policy.pdf'

import {
  isSocialLink,
  notEmptyArray,
  required,
  requiredCheckBox,
  validateField,
} from '../../utils/validation'
import DropzoneComponent from 'react-dropzone-component'

import Select, { ControlledSelect } from '../../components/UI/newInput/select'

import { themes } from '../../utils/consts'
import { CityInput } from '../../components/UI/newInput/cityInput'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import AccountTypeRadio from '../../components/UI/radioButton/radioButton'

import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'
import Button from '../../components/UI/button/button'

import Bordered from '../../components/UI/bordered/bordered'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { ageRadio, genderRadio } from '../../utils/radioConsts'

import FilesUploader from '../../components/UI/FilesUploader/filesUploader'
import isURL from 'validator/es/lib/isURL'

import TextArea from '../../components/UI/newInput/textArea'
import cls from '../../scss/components.scss'
import TransitionWrapper from 'react-bootstrap/TransitionWrapper'
import { Collapse, Fade } from 'react-bootstrap'
import PriceInput from '../../components/UI/newInput/priceInput'
import { INNModal } from '../../components/modals/INNModal'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useFormField, { useFormCheckbox } from '../../hooks/useField'
import { bloggerSchema } from '../../utils/schemas'
import MySelect from '../../components/UI/newInput/select'
import { BloggerSchema } from '../../types/abstract'

export default function CreateBlogger() {
  let navigate = useNavigate()
  const [linkAlert, setLinkAlert] = useState<string | null>('')
  const [screenshots, setScreenshots] = useState<string[]>([])
  const [screenshotsInProgress, setScreenshotsInProgress] = useState<string[]>(
    []
  )
  const [showFields, setShowFields] = useState<boolean>(false)
  const [fieldsTimeout, setFieldsTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const [oAuths, setOAuths] = useState<any[]>([])
  const [platofrm, setPlatform] = useState<string>('')
  const [searchParams, SetSearchParams] = useSearchParams()
  const [oAuthId, setOAuthId] = useState<string | null>(null)
  const [concreteOAuth, setConcreteOAuth] = useState<any>({})

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
    trigger,
  } = useForm<BloggerSchema>({ resolver: yupResolver(bloggerSchema) })

  const getFieldProps = useFormField(register, trigger, errors)
  const getCheckboxProps = useFormCheckbox(setValue, trigger, watch)

  useEffect(() => {
    const link = watch('link')
    setValue('themes', ['img'])
    clearTimeout(fieldsTimeout)

    if (link == '') return

    // Проверка соответсвует ли ссылка социальной сети
    const regex = new RegExp(
      /(tiktok.com\/(.*))|(youtube.com\/(.*))|(instagram.com\/(.*))|(vk.com\/(.*))|(twitter.com\/(.*))|(t.me\/(.*))/gi
    )
    if (!regex.test(link))
      setLinkAlert(
        'Вставьте сюда полную ссылку на Ваш профиль в социальной сети, например, в VK, Instagram*, Telegram, YouTube'
      )
    else {
      // Вычисление платформы по ссылке
      if (link.search('youtube.com')) setPlatform('youtube')
      if (link.search('instagram.com')) setPlatform('instagram')
      if (link.search('vk.com')) setPlatform('vk')
      if (link.search('t.me')) setPlatform('telegram')

      setLinkAlert(null)
      setFieldsTimeout(
        setTimeout(() => {
          if (link) {
            setShowFields(true)
          } else {
            setShowFields(false)
          }
        }, 500)
      )
    }
  }, [watch('link')])

  const registerBlogger = (data: BloggerSchema) => {
    if (screenshotsInProgress.length > 0)
      return toast.error('Дождитесь загрузки всех файлов')
    axios
      .post(
        process.env.REACT_APP_BACKEND_API + 'bloggers/create',
        {
          link: data.link,
          oAuthId: oAuthId,
          pricing: {
            story: data.story,
            post: data.post,
            advLayout: data.advLayout,
          },
          description: data.description,
          themes: data.themes.map(theme => {
            return theme.value
          }),
          location: data.location,
          age: data.age, // TODO age range
          gender: data.gender,
          screenshots: screenshots,
        },
        { withCredentials: true }
      )
      .then(res => {
        navigate(`/blogger/${res.data.id}`, { replace: true })
      })
      .catch(err => {
        console.log(err)
        if (err.response.data.message === 'Error while getting info') {
          toast.error(`Не удалось найти аккаунт по ссылке ${data.link}`)
        } else if (err.response.data.message === 'Not full info') {
          toast.error(`Неполная информация`)
        } else if (err.response.data.message === 'Blogger already exists.') {
          toast.error(`Блогер ${data.link} уже зарегистрирован`)
        } else {
          toast.error('Что-то пошло не так')
        }
      })
  }

  useEffect(() => {
    // Проверяем установил ли нам бэкенд _id oauth профиля нашего блогера
    const oauth = searchParams.get('oauth')
    setOAuthId(oauth)

    const code = searchParams.get('code')
    console.log(code)
    if (code) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_API +
            'oauth/instagram/callback?code=' +
            code,
          { withCredentials: true }
        )
        .then(res => {
          //setOAuths(res.data)
          setOAuthId(res.data._id)
          console.log(res.data)
          //let selectedOAuth = res.data.find(o => o._id === oauth);
          //setConcreteOAuth(selectedOAuth)
          setConcreteOAuth(res.data)
          navigate(`/blogger/create?oauth=${res.data._id}`, { replace: true }) // Меняем путь, чтобы засейвить oauth для рефреша
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
    }

    if (oauth) {
      // Get oAuths
      axios
        .get(process.env.REACT_APP_BACKEND_API + 'oauth/list', {
          withCredentials: true,
        })
        .then(res => {
          setOAuths(res.data)
          console.log(res.data)
          let selectedOAuth = res.data.find(o => o._id === oauth)
          setConcreteOAuth(selectedOAuth)
        })
        .catch(err => {
          toast.error(err.response.data.message)
        })
    }
  }, [])

  const connectOAuth = service => {
    // Перевод юзера на страницу подключения инсты
    window.location.replace(
      process.env.REACT_APP_BACKEND_API + `oauth/instagram/auth`
    )
    //navigate(process.env.REACT_APP_BACKEND_API + `oauth/instagram/auth`, { replace: true });
  }

  console.log(watch())
  console.log(isValid)
  console.log(errors)

  return (
    <div className="page">
      <Navbar />
      <div className="page-wrapper">
        <PageHeader title="Регистрация блогера" />
        <div className="page-body mt-4">
          <div
            className={
              'container-xl d-flex align-items-center column ' +
              cls['dashboard-form-container']
            }>
            <form
              className="col-md-8 col-9"
              onSubmit={handleSubmit(registerBlogger)}>
              <div className="card-body">
                <div>
                  {/*<h3>{concreteOAuth.service} - @{concreteOAuth.username}</h3>*/}
                  <div className="col ">
                    <div className="">
                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Ссылка на профиль"
                          {...getFieldProps('link')}
                        />
                        <p className="mt-2">
                          Ссылка на свой профиль в социальной сети
                        </p>
                      </div>

                      <div>
                        <p className="mt-2">{linkAlert}</p>
                      </div>

                      <Collapse in={showFields}>
                        <div className="mb-3">
                          <TextArea
                            rows="4"
                            type="text"
                            placeholder="Описание аккаунта"
                            {...getFieldProps('description')}
                          />
                        </div>
                      </Collapse>

                      <Collapse timeout={500} in={showFields}>
                        <div className="mb-3">
                          <CityInput
                            placeholder="Локация"
                            autoComplete="off"
                            {...getFieldProps('location')}
                          />
                        </div>
                      </Collapse>
                      <Collapse timeout={1000} in={showFields}>
                        <div className="mb-3">
                          <ControlledSelect
                            control={control}
                            placeholder="Тематика"
                            options={themes}
                            isMulti={true}
                            name={'themes'}
                          />
                        </div>
                      </Collapse>
                      <div className="">
                        <Collapse timeout={1500} in={showFields}>
                          <div>
                            <div className="mb-2">Основная аудитория</div>
                            <div className="d-flex column-gap-2 form-label mb-4">
                              <RadioGroup
                                className="w-100"
                                name={'gender'}
                                between
                                big
                                options={genderRadio}
                                currentValue={watch('gender')}
                                getFieldProps={getFieldProps}></RadioGroup>
                            </div>
                          </div>
                        </Collapse>
                        <Collapse timeout={2500} in={showFields}>
                          <div className="mb-3">
                            <div className="mt-2 form-label">
                              Возраст аудитории
                            </div>
                            <RadioGroup
                              name={'age'}
                              between
                              options={ageRadio}
                              currentValue={watch('age')}
                              getFieldProps={getFieldProps}></RadioGroup>
                          </div>
                        </Collapse>
                        <Collapse timeout={3000} in={showFields}>
                          <div>
                            <div className="d-flex justify-content-center justify-content-sm-start ">
                              <h3 className="m-0 mb-2 text-14-bold mb-sm-3  text-center text-md-start text-uppercase text--gradient">
                                Рекламные предложения
                              </h3>
                            </div>
                          </div>
                        </Collapse>

                        <Collapse timeout={3500} in={showFields}>
                          <div className="mb-3">
                            <div className="d-sm-none d-block h-line background-gradient mb-3"></div>
                            <label className="form-label d-flex justify-content-center text-uppercase">
                              <span className="text-14-bold text--purple mb-2 ">
                                Минимальная цена
                              </span>
                            </label>
                            <div className="row gy-3">
                              <div className={'col-md-4'}>
                                <PriceInput
                                  placeholder={
                                    platofrm != 'telegram'
                                      ? 'История'
                                      : 'Репост'
                                  }
                                  {...getFieldProps('story')}
                                />
                              </div>

                              <div className={'col-md-4'}>
                                <PriceInput
                                  placeholder="Публикация"
                                  {...getFieldProps('post')}
                                />
                              </div>

                              <div className={'col-md-4'}>
                                <PriceInput
                                  placeholder="Рилс"
                                  {...getFieldProps('advLayout')}
                                />
                              </div>
                            </div>
                            <p className="text--disabled mt-2 text-center text-md-start">
                              Комиссия сервиса составляет 30% от указанной
                              суммы.{' '}
                              <span className="d-none d-md-inline">
                                Например за публикацию вы получите{' '}
                                <span>{Math.round(watch('post') * 0.7)}₽</span>
                              </span>
                            </p>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <Collapse in={showFields} timeout={4000}>
                      <div className="">
                        <label className="form-check d-flex justify-content-center mb-3">
                          <MyCheckBox {...getCheckboxProps('isPolicy')} />
                          <span className="form-check-label">
                            Согласен с{' '}
                            <a
                              href={policy}
                              tabIndex="-1"
                              target="_blank"
                              rel="noreferrer">
                              политикой конфиденциальности
                            </a>
                            .
                          </span>
                        </label>
                      </div>
                    </Collapse>
                    <Collapse in={showFields}>
                      <div className="mb-3 row">
                        <label className="form-label text-16-regular">
                          Скриншот статистики аккаунта
                        </label>
                        <FilesUploader
                          setFiles={e => {
                            setScreenshots(e)
                          }}
                          setFilesInProgress={setScreenshotsInProgress}
                          page="blogger"
                          acceptedFiles="image/jpeg,image/png,image/gif"></FilesUploader>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <Collapse mountOnEnter={true} in={showFields}>
                  <div className="card-footer">
                    <div className="row align-items-center">
                      <div className="d-flex justify-content-center pt-4">
                        <Button
                          className="btn btn_primary w-280"
                          type="submit"
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            screenshotsInProgress.length > 0
                          }>
                          Зарегистрировать
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

/*<div className="mb-3">
                                                <label className="myForm-label">Подключите Instagram</label>
                                                <a href={process.env.REACT_APP_BACKEND_API + "instagram/connect"}>Instagram</a>
                                                </div>
                                            <div className="mb-3">
                                                <label className="myForm-label">Выберите подключенный аккаунт</label>

                                                {instagramAuths.map((auth) => (<a onClick={() => setUsername(auth.data.username)}> {auth.data.username} </a>))}

                                                <input type="text" className="myForm-control" placeholder="Username аккаунта" value={username} onChange={(e) => setUsername(e.target.value)} />
                                            </div>*/

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import React, { useRef, useEffect, useState } from 'react'
import axios from 'axios'
import { json, useParams, useNavigate } from 'react-router-dom'

import AuthService from '../../services/auth.service'
import Select from '../../components/UI/newInput/select'
import { Link } from 'react-router-dom'
import {
  cancelOfferAuction,
  acceptOfferAuctionResponse,
  getOfferAuctionById,
  sendOfferAuctionResponse,
} from '../../services/offers.service'
import { getFilesMetadata } from '../../services/file.service'
import PlaceOfferResponse from '../../components/modals/placeOfferResponse'
// Response
import { toast, Toaster } from 'react-hot-toast'
import Bordered from '../../components/UI/bordered/bordered'
import OfferCondition from '../../components/UI/offerConditions/offerCondition'
import StatusIcon from '../../components/UI/icons/statusIcon/statusIcon'
import { statuses, translateSocials } from '../../utils/translate'
import Button from '../../components/UI/button/button'

import { formatDate } from '../../utils/formatters'
import authService from '../../services/auth.service'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Slider from '../../components/UI/slider/slider'
import userPurpleIcon from '../../assets/icons/user.svg'
import { addSpacesToNumber, fixUrlProtocol } from '../../utils/functional'
import { getBloggers } from '../../services/blogger.service'
import { ConfirmModal } from '../../components/modals/confirmModal'

import editIcon from '../../assets/icons/edit.svg'

const ViewOffer = () => {
  let { id } = useParams()
  let navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [finishOfferVisible, setFinishOfferVisible] = useState(false)
  const [myAcc, setMyAcc] = useState(false)
  const [recommendedBloggers, setRecommendedBloggers] = useState([])
  const [cancelVisible, setCancelVisible] = useState(false)
  const [bloggerHasISent, setBloggerHasISent] = useState(false) // Я блогер и отправил ли я отклик

  const [offer, setOffer] = useState({})

  const [files, setFiles] = useState([])
  const onCancel = async () => {
    try {
      await cancelOfferAuction(id)
      toast.success('Оффер отменен')
      initOffer()
    } catch (err) {
      toast.error(err.message)
    }
  }

  const initOffer = () => {
    getOfferAuctionById(id)
      .then(data => {
        setOffer(data)
        if (!data) {
          navigate('/404')
        }
        getFilesMetadata(data.offerData.files.join(',')).then(files =>
          setFiles(files)
        )
      })
      .catch(() => {
        navigate('/404')
      })
      .finally(() => setLoading(false))
    //getOfferMessages(id).then(data => setMessages(data))
  }

  useEffect(() => {
    initOffer()
    getBloggers('').then(data => setRecommendedBloggers(data))
  }, [id])

  useEffect(() => {
    console.log(offer)
    setMyAcc(offer?.user?._id === authService.getCurrentUser().id)
    offer?.responses?.map((m, i) => {
      if (m.user == authService.getCurrentUser().id) setBloggerHasISent(true)
    })
  }, [offer])

  /*
    const sendMessage = async () => {
        // Create offer is there is no offer
        console.log(offer.offer)
        //if(!offer.offer.status)
        //    return await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/openDeal`, { offer: id }, { withCredentials: true })

        // If I am not tge owner of the offer, I am the blogger
        if(offer.user._id != AuthService.getCurrentUser().id){
            await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/sendMessage`, { offer: id, to: offer.user._id, offer: offer.offer._id, text: message }, { withCredentials: true }).then((res) => {
                //setOffer(res.data)
            })
        }else{
            await axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/sendMessage`, { offer: id, to: offer.offer.blogger.user, offer: offer.offer._id, text: message }, { withCredentials: true }).then((res) => {
                //setOffer(res.data)
            })
        }
        getMessages();
        setMessage("");
    }

    const getMessages = async () => {
        let msgs = await axios.get(process.env.REACT_APP_BACKEND_API + `campaigns/${id}/messages/`, { withCredentials: true })
        setMessages(msgs.data)
    }

    const markComplete = () => {
        axios.post(process.env.REACT_APP_BACKEND_API + `campaigns/complete`, { campaign: id }, { withCredentials: true }).then((res) => {
            //setOffer(res.data)
        })
    }
    */

  return (
    <div className="page">
      <ConfirmModal
        show={cancelVisible}
        confirmText={'Вы действительно хотите отменить предложение?'}
        cancelText={'Нет'}
        acceptText={'Да'}
        onApply={apply => {
          if (apply) {
            onCancel().then(navigate('/offersauctions'))
          }
          setCancelVisible(false)
        }}></ConfirmModal>
      <Navbar campaigns></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Рекламное предложение" row>
          <div className="btn-list">
            {myAcc && offer.status === 'pending' && (
              <>
                <Link
                  to={`/offersauction/edit/${id}`}
                  className={'btn btn_secondary desktop'}>
                  Редактировать
                </Link>
                <Link to={`/offersauction/edit/${id}`} className="mobile">
                  <img src={editIcon} alt="Редактировать" />
                </Link>
              </>
            )}
          </div>
        </PageHeader>

        <div className="page-body">
          <div className="container-xl">
            <div className="">
              <div className="card-body mb-40">
                {loading && <div className="spinner-border" role="status" />}

                {!loading && (
                  <div className="row mb-3 row-gap-3">
                    <div className=" col-md-8">
                      <div className="h-100">
                        <div className="bordered col  h-100 d-flex column justify-content-between">
                          <div className="p-5 pb-0">
                            <div className="w-100">
                              <h2 className="f-geometria text-break text-uppercase">
                                {offer.title}
                              </h2>
                              <p className="text-break text-14-regular">
                                {offer.text}
                              </p>
                            </div>

                            <div className="d-flex mb-7 flex-wrap column-gap-3 row-gap-3">
                              {files?.map(file => {
                                const src =
                                  process.env.REACT_APP_BACKEND_API +
                                  'file/' +
                                  file.id
                                return (
                                  <a
                                    className="d-block h-25 w-25"
                                    key={file.id}
                                    href={src}
                                    type={file.ContentType}
                                    target="_blank"
                                    rel="noreferrer">
                                    {file.ContentType.split('/')[0] ==
                                    'image' ? (
                                      <img
                                        className=" offer__thumbnail"
                                        style={{
                                          objectFit: 'contain',
                                          objectPosition: 'left',
                                        }}
                                        src={src}
                                      />
                                    ) : (
                                      decodeURI(file.Metadata.originalname)
                                    )}
                                  </a>
                                )
                              })}
                            </div>
                          </div>
                          <div className="text-16">
                            <h4 className="text-14-regular px-5 text--gradient m-0 d-inline-block mb-3">
                              Ссылка на продукт
                            </h4>

                            <div className="border-t_purple border-b_purple background-purple--pale px-5 py-2 mb-3">
                              <a
                                className="text-break fw-bold text--purple "
                                href={fixUrlProtocol(offer.offerData.url)}>
                                {offer.offerData.url}
                              </a>
                            </div>
                            <span className="d-block px-5 mb-5">
                              Блогер будет должен разместить эту ссылку
                            </span>
                          </div>

                          <div className="d-flex p-5 pt-0     flex-column flex-sm-row align-items-sm-center align-items-center">
                            {/* advertiser logic */}
                            {offer.user._id ==
                              AuthService.getCurrentUser().id &&
                              (!offer.status || offer.status == 'pending') && (
                                <div className="m-1">
                                  <Button
                                    className="m-1 btn btn_secondary text-12-bold lh-1 py-3"
                                    type={'button'}
                                    onClick={() => setCancelVisible(true)}>
                                    Отменить
                                  </Button>
                                </div>
                              )}

                            {/* blogger logic */}
                            {offer.user._id !=
                              AuthService.getCurrentUser().id &&
                              (!offer.status || offer.status == 'pending') && (
                                <div className="m-1">
                                  <Button
                                    className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                                    onClick={() => {
                                      setFinishOfferVisible(true)
                                      initOffer()
                                    }}>
                                    Откликнуться
                                  </Button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 bordered">
                      <div
                        style={{ padding: '0.5rem' }}
                        className="p-sm-5 h-100 column d-flex align-items-center ">
                        <h3 className="f-geometria text-uppercase mb-3">
                          Условия размещения
                        </h3>
                        <div className="w-100">
                          <OfferCondition
                            keyWord={'Заказчик'}
                            value={
                              offer.user.lastName + ' ' + offer.user.firstName
                            }
                          />
                          <OfferCondition
                            keyWord={'Платформа'}
                            value={translateSocials(offer.offerData.platform)}
                          />
                          <OfferCondition
                            keyWord={'Бюджет'}
                            value={offer.price + 'р'}
                          />
                          <OfferCondition
                            keyWord={'География'}
                            value={offer.offerData.geo || 'Не важна'}
                          />
                          <OfferCondition
                            keyWord={'Минимум подписчиков'}
                            value={offer.minFollowers}
                          />
                          <OfferCondition
                            keyWord={
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    (?) - чтобы изменить дату, отредактируйте
                                    предложение
                                  </Tooltip>
                                }>
                                <span>Дата размещения(?)</span>
                              </OverlayTrigger>
                            }
                            value={
                              offer.acceptDeadline &&
                              formatDate(offer.acceptDeadline)
                            }
                          />
                          <OfferCondition
                            keyWord={'Статус'}
                            value={
                              <span className="text-black fw-bold text-lowercase">
                                <StatusIcon status={offer.status} />
                                {statuses[offer.status] || offer.status}
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {offer?.status != 'new' && (
                <div className="mb-6">
                  <div className="justify-content-center d-flex">
                    <h3 className="mb-5 f-geometria text-uppercase fs-24 text--gradient">
                      Отклики
                    </h3>
                  </div>
                  <div className="d-flex justify-content-center">
                    {myAcc && (
                      <div className="list-group px-0 list-group-flush overflow-auto container-sm col-md-8">
                        {offer?.responses?.length > 0 ? (
                          offer.responses?.map((m, i) => (
                            //Отклик
                            <div
                              className="bordered row-wrap-961 fs-12 offer_response row-gap-3 d-flex mb-3 column-gap-3"
                              key={m?._id}>
                              <div
                                className=" col-md-3 avatar-lg avatar"
                                style={{
                                  backgroundImage: `url('${m?.blogger?.avatar}')`,
                                  borderRadius: 0,
                                }}></div>
                              <div className=" overflow-hidden flex-grow-1 d-flex row flex-wrap  ">
                                <div className="col d-flex min-w-fit column row-gap-2 justify-content-between ">
                                  <Link to={'/blogger/' + m?.blogger?._id}>
                                    <span className="f-geometria text-uppercase fs-14 fw-bold">
                                      @{m?.blogger?.username}
                                    </span>
                                  </Link>
                                  <p
                                    style={{ maxWidth: '80%' }}
                                    className="text-muted text-break text-wrap mt-n1 ">
                                    {m?.message}
                                  </p>
                                  <div className="text-muted text-truncate mt-n1">
                                    Дедлайн блогера:{' '}
                                    {m.finishDeadline.split('T')[0]}
                                  </div>
                                </div>
                                {m.blogger.user !==
                                  AuthService.getCurrentUser().id &&
                                  (offer.status === 'pending' ||
                                    offer.status === 'cancelled') && (
                                    <div
                                      style={{ marginTop: '20px' }}
                                      className="d-flex w-auto column justify-content-start row-gap-2 align-items-center">
                                      <Button
                                        className="btn btn_primary btn--small"
                                        style={{ width: '170px' }}
                                        onClick={() => {
                                          acceptOfferAuctionResponse(
                                            offer?._id,
                                            m?.blogger?._id
                                          ).then(res => {
                                            navigate('/offer/' + res.offerDeal)
                                          })
                                        }}>
                                        Принять блогера
                                      </Button>
                                      <div className="text-muted d-inline-block ">
                                        <span className="text-black">
                                          <span className="fs-14 f-geometria fw-bolder">
                                            {m.price}₽
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>
                              {/*<div className="text-muted text-truncate mt-n1">{m.accepted}</div>*/}
                              {/*<div className="text-muted text-truncate mt-n1">{m.sendDate}</div>*/}
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center fs-3">
                            Пока на это предложение нет ни одного отклика
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Не мой аукцион */}
                  {!myAcc && (
                    <>
                      {' '}
                      {offer?.responses?.length === 0 && (
                        <div className="text-14-regular text-center">
                          У предложения пока еще нет откликов. Откликнитесь,
                          чтобы получить шанс разместить этот заказ у себя
                        </div>
                      )}
                      {offer?.responses?.length > 0 && (
                        <>
                          <p className={'text-14-regular text-center '}>
                            На это предложение уже {offer?.responses?.length}{' '}
                            отклик(ов)
                          </p>
                          {bloggerHasISent && (
                            <p className={'text-14-regular text-center '}>
                              Вы оставили отклик
                            </p>
                          )}

                          {/* Показать только мои отклики */}
                          <div className="d-flex justify-content-center">
                            <div className=" list-group row-gap-4 px-0 list-group-flush w-100 overflow-auto container-sm col-md-8">
                              {bloggerHasISent &&
                                offer.responses?.map(
                                  (m, i) =>
                                    m.user ==
                                      authService.getCurrentUser().id && (
                                      <div
                                        className="bordered fs-12 offer_response d-flex flex-nowrap column-gap-3"
                                        key={m?._id}>
                                        <div
                                          className="col-md-3 avatar-lg avatar"
                                          style={{
                                            backgroundImage: `url('${m?.blogger?.avatar}')`,
                                            borderRadius: 0,
                                          }}></div>
                                        <div
                                          style={{ maxWidth: '100%' }}
                                          className="flex-grow-1 d-flex row flex-nowrap  ">
                                          <div className="col d-flex row-gap-2 column justify-content-between ">
                                            <Link
                                              to={
                                                '/blogger/' + m?.blogger?._id
                                              }>
                                              <span className="text-wrap f-geometria text-uppercase fs-14 fw-bold">
                                                @{m?.blogger?.username}
                                              </span>
                                            </Link>
                                            <p
                                              style={{ maxWidth: '80%' }}
                                              className="text-wrap text-muted text-break text-wrap mt-n1 ">
                                              {m?.message}
                                            </p>
                                            <div className="text-muted text-truncate mt-n1">
                                              Дедлайн блогера:{' '}
                                              {m.finishDeadline.split('T')[0]}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{ marginTop: '35px' }}
                                          className="d-flex w-auto column justify-content-start  row-gap-2 align-items-start ">
                                          <div className="text-muted d-inline-block ">
                                            <span className="fs-14 f-geometria text-black fw-bolder">
                                              {m.price}₽
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {!myAcc && !bloggerHasISent && (
                    <div className="text-center">
                      <Button
                        className="m-1 btn btn_primary text-12-bold lh-1 py-3"
                        onClick={() => {
                          setFinishOfferVisible(true)
                          initOffer()
                        }}>
                        Откликнуться
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/*<div className='mx-auto '>*/}
            {/*    <div className='d-flex justify-content-center mb-5'><h3 className='fw-bold f-geometria text-uppercase m-0'>Вам могут подойти</h3></div>*/}

            {/*    <div className='blogger__similar blogger__similar--offer position-relative mx-auto col-md-8'>*/}
            {/*        {recommendedBloggers &&*/}
            {/*            <Slider className={'w-100'} slidesPerView={4} spaceBetween={16}>*/}
            {/*                {recommendedBloggers?.map(e =>*/}
            {/*                    <div className='border_purple '>*/}
            {/*                        <div>*/}
            {/*                            <div className='avatar w-100'*/}
            {/*                                 style={{ backgroundImage: `url(${e?.avatar})`, height: '126px', borderRadius: 0 }}>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }} className='py-4 column'>*/}
            {/*                            <span title={e.username} className='text-uppercase f-geometria text--purple text-14-bold text-truncate'>@{e.username}</span>*/}
            {/*                            <span className='mt-3 text-muted text-12-regular'>Подписчики(ER):</span>*/}
            {/*                            <span className='purple mt-2 text-12-bold d-flex align-items-center justify-content-center'>*/}
            {/*                                        <img className='me-2' style={{ width: '10px' }} src={userPurpleIcon} />*/}
            {/*                                {addSpacesToNumber(e.followers)}{e.er ? `(${e.er}%)` : ''}*/}
            {/*                                    </span>*/}

            {/*                        </div>*/}

            {/*                    </div>*/}
            {/*                )}*/}
            {/*            </Slider>*/}
            {/*        }*/}

            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <PlaceOfferResponse
        show={finishOfferVisible}
        onHide={() => {
          console.log('hello')
          setFinishOfferVisible(false)
        }}
        onApply={payload => {
          sendOfferAuctionResponse(id, payload).then(() =>
            window.location.reload()
          )
        }}
      />
    </div>
  )
}

export default ViewOffer

import React from 'react'
import styles from './accountTypeRadio.module.scss'

const radioButton = ({
  className,
  register = (_) => {},
  id,
  value,
  onChange,
  children,
  disabled,
  name,
  style,
  checked,
  containerStyle,
  ...props
}) => {
  return (
    <div style={containerStyle} className={styles.radio + ' ' + className}>
      <input
        checked={checked}
        value={value}
        disabled={disabled}
        id={id ? id : value}
        type="radio"
        className={styles.radio}
        onChange={onChange}
        {...register(name)}
      />
      <label style={style} htmlFor={id ? id : value}>
        {children}
      </label>

      {props.error}
    </div>
  )
}

export default radioButton

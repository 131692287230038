import cls from './myTable.module.scss'

import { Link } from 'react-router-dom'
import React from 'react'
import Bordered from '../bordered/bordered'

export const MyTable = ({ children, header, ...props }) => {
  const fillSpace = diff => {
    let arr = []
    for (let i = 0; i < diff; i++) {
      arr.push(<tr key={i}></tr>)
    }
    return arr
  }

  const theadChildren = React.Children.toArray(children).filter(
    child => child.type === 'thead'
  )

  const thead = theadChildren.length > 0 ? theadChildren[0] : null
  const theadRows = thead ? React.Children.toArray(thead.props.children) : []

  const tbodyChildren = React.Children.toArray(children).filter(
    child => child.type === 'tbody'
  )

  const tbody = tbodyChildren.length > 0 ? tbodyChildren[0] : null
  const tbodyRows = tbody ? React.Children.toArray(tbody.props.children) : []

  const totalRows = theadRows.length + tbodyRows.length
  const shouldFillSpace = totalRows < 8

  return (
    <div className="card h-100  bg-transparent d-flex card-borderless shadow-none">
      {header && (
        <div className="card-header justify-content-center">
          <h3
            className={
              'card-title text--gradient text-uppercase ' + cls['table-header']
            }
          >
            {header}
          </h3>
        </div>
      )}
      <div className="h-100">
        <div
          style={props.tableContainer}
          className={
            cls['table-container'] + ' table-responsive h-100 w-100 m-0 '
          }
        >
          <div style={props.style}>
            <table
              style={{ height: '500px !important' }}
              {...props}
              className={cls.table + ' w-100 h-100' + props.className}
            >
              {thead && <thead>{theadRows}</thead>}
              {tbody && (
                <tbody>
                  {tbodyRows}
                  {shouldFillSpace &&
                    fillSpace(8 - totalRows).map((el, index) => (
                      <React.Fragment key={index}>{el}</React.Fragment>
                    ))}
                </tbody>
              )}
              {!thead && !tbody && children}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import '../../scss/admin.scss'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import { Grid } from 'gridjs'
import { _ } from 'gridjs-react'
import { useRef, useEffect } from 'react'

import AuthService from '../../services/auth.service'
import axios from 'axios'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function Admin() {
  const wrapperRef = useRef(null)
  const wrapperRef2 = useRef(null)

  const grid = new Grid({
    columns: [
      'Name',
      'Email',
      'Phone',
      'City',
      'BloggerAccs',
      'Type',
      'RegDate',
      {
        name: 'utm',
        //formatter: (cell, row) => _(<button onClick={()=>alert("penis")}>{row.cells[0].data} - {row.cells[1].data}</button>)
      },
    ],
    sort: {
      multiColumn: false,
      server: {
        url: (prev, columns) => {
          if (!columns.length) return prev

          const col = columns[0]
          //const dir = col.direction === 1 ;
          let colName = [
            'firstName',
            'email',
            'phone',
            'city',
            'bloggersCount',
            'accType',
            'card.createdAt',
            'utm',
          ][col.index]

          return `${prev}&order=${colName}&dir=${col.direction}`
        },
      },
    },
    search: {
      server: {
        url: (prev, keyword) => `${prev}&search=${keyword}`,
      },
    },
    data: [],
    className: {
      td: 'my-td-class',
      table: 'table table-vcenter card-table ',
      footer: 'card-footer datatable',
      pagination: 'pagination pagination-sm d-flex align-items-center',
      paginationSummary: 'text-muted text-sm text-secondary',
      paginationButton: 'page-item page-link ms-auto',
      paginationButtonCurrent: 'page-item page-link active',
    },
    pagination: {
      enabled: true,
      limit: 25,
      summary: true,
      server: {
        url: (prev, page, limit) =>
          `${prev}&limit=${limit}&offset=${page * limit}`,
      },
    },
    server: {
      url: process.env.REACT_APP_BACKEND_API + `admin/users?`,
      headers: { 'x-access-token': AuthService.getCurrentUser().accessToken },
      total: data => data.count,
      then: data =>
        data.data.map(card => [
          _(
            <a href={`/admin/user/${card._id}`}>
              {card.firstName + ' ' + card.lastName}
            </a>
          ),
          card.email,
          card.phone,
          card.city,
          card.bloggersCount,
          card.accType,
          moment(card.createdAt).format('DD.MM.YYYY hh:mm'),
          card.utm,
        ]),
      handle: res => {
        // no matching records found
        if (res.status === 404) return { data: [] }
        if (res.ok) return res.json()

        throw Error('oh no :(')
      },
    },
  })

  const grid2 = new Grid({
    columns: [
      'Username',
      'Platform',
      'Followers',
      'Themes',
      'User',
      'RegDate',
      'Action',
    ],
    sort: {
      multiColumn: false,
      server: {
        url: (prev, columns) => {
          if (!columns.length) return prev

          const col = columns[0]
          let colName = [
            'username',
            'platform',
            'followers',
            'themes',
            'user',
            'card.createdAt',
            'username',
          ][col.index]

          return `${prev}&order=${colName}&dir=${col.direction}`
        },
      },
    },
    search: {
      server: {
        url: (prev, keyword) => `${prev}&search=${keyword}`,
      },
    },
    data: [],
    className: {
      td: 'my-td-class',
      table: 'table table-vcenter card-table ',
      footer: 'card-footer datatable',
      pagination: 'pagination pagination-sm m-0 ms-auto',
      paginationSummary: 'text-muted text-sm me-auto',
      paginationButton: 'page-item page-link',
      paginationButtonCurrent: 'page-item page-link active',
    },
    pagination: {
      enabled: true,
      limit: 25,
      summary: true,
      server: {
        url: (prev, page, limit) =>
          `${prev}&limit=${limit}&offset=${page * limit}`,
      },
    },
    server: {
      url: process.env.REACT_APP_BACKEND_API + `admin/bloggers?`,
      headers: { 'x-access-token': AuthService.getCurrentUser().accessToken },
      total: data => data.count,
      then: data =>
        data.data.map(card => [
          _(
            <div>
              <a className={''} href={`/blogger/${card._id}`}>
                {card.username}
              </a>{' '}
              /{' '}
              <a className={''} href={`/admin/blogger/${card._id}`}>
                (a)
              </a>
            </div>
          ),
          card.platform,
          card.followers,
          card.themes,
          _(
            <a href={`/admin/user/${card.user?._id}`}>
              {card.user?.firstName + ' ' + card.user?.lastName}
            </a>
          ),
          moment(card.createdAt).format('DD.MM.YYYY hh:mm'),
          _(
            <div>
              <button className={''} onClick={() => verifyBlogger(card._id)}>
                {card.verified ? 'Подтвержден' : 'Подтвердить'}{' '}
              </button>
              <button className={''} onClick={() => hideBlogger(card._id)}>
                {card.hidden ? '-Скрыт' : '+Скрыть'}{' '}
              </button>
            </div>
          ),
        ]),
      handle: res => {
        // no matching records found
        if (res.status === 404) return { data: [] }
        if (res.ok) return res.json()
        throw Error('oh no :(')
      },
    },
  })

  const changeRole = async (id, role) => {
    //await axios.put(( process.env.REACT_APP_BACKEND_API || "http://127.0.0.1:8080/api/" ) +"admin/users/update", { id: id, role: role }, { withCredentials: true });
    //grid.forceRender();
  }

  const verifyBlogger = async id => {
    await axios.get(
      process.env.REACT_APP_BACKEND_API + `/admin/blogger/${id}/verify`,
      { withCredentials: true }
    )
    grid2.forceRender()
  }

  const hideBlogger = async id => {
    await axios.get(
      process.env.REACT_APP_BACKEND_API + `/admin/blogger/${id}/hide`,
      { withCredentials: true }
    )
    grid2.forceRender()
  }

  useEffect(() => {
    grid.render(wrapperRef.current)
    grid2.render(wrapperRef2.current)
  })

  return (
    <div className="page">
      <Navbar></Navbar>
      <div className="page-wrapper">
        <PageHeader></PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <h3>Юзеры</h3>
            <div className="card">
              <div className="table-responsive">
                <div ref={wrapperRef} />
              </div>
            </div>
            <h3>Блогеры</h3>
            <div className="card">
              <div className="table-responsive">
                <div ref={wrapperRef2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

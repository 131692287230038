import cls from './pageHeader.module.scss'

export default function PageHeader(props) {
  return (
    <header className="container-xl mt-4">
      <div className={`row ${props.row ? 'flex-row' : 'flex-column'} flex-xl-row align-items-center`}>
        <div className={`col-md-3 ${props.row ? 'col-2' : 'col-12 '} text-md-start text-center`} />
        <div className={`col-md-6 ${props.row ? 'col-8' : 'col-12'} text-center`}>
          <h2
            className="text-uppercase fw-bolder"
            style={{ verticalAlign: 'middle', marginBottom: 0 }}
          >
            {props.title || 'Главная'}
          </h2>
        </div>
        <div className={`col-md-3 ${props.row ? 'col-2' : 'col-12'} align-items-center text-md-end`}>
          <div className={(props.row ? 'mt-0 ' : '') + cls['page-header-buttons']}>{props.children}</div>
        </div>
      </div>
    </header>
  )
}

import cityList from '../../../assets/city-list.json'
import cls from './newInput.module.scss'

import InputError from '../inputAlert/inputError'

export const CityInput = ({
  register = (_) => {},
  name,
  error,
  onChange,
  ...props
}) => {
  return (
    <>
      <div>
        <input
          onChange={onChange}
          type="text"
          className={`${cls.input} form-control`}
          list="cities"
          {...register(name)}
          {...props}
        />
        <datalist id="cities">
          {cityList.map(city => {
            return <option key={city.id} value={city.name} />
          })}
        </datalist>
      </div>
      {error && <InputError>{error}</InputError>}
    </>
  )
}

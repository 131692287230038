import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import Select from '../../components/UI/newInput/select'

import { _ } from 'gridjs-react'
import { useRef, useEffect, useState } from 'react'

import AuthService from '../../services/auth.service'

import axios from 'axios'

import CampaignsCard from '../../components/campaignsCard'
import { NotFoundMessage } from '../../components/notFoundMessage'
import PlusButtonGroup from '../../components/UI/plusButtonGroup/plusButtonGroup'
import Button from '../../components/UI/button/button'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import { CityInput } from '../../components/UI/newInput/cityInput'
import InputDoubleRange from '../../components/UI/newInput/inputDoubleRange'
import { themes } from '../../utils/consts'
import RadioGroup from '../../components/UI/radioGroup/radioGroup'
import { platformRadio } from '../../utils/radioConsts'
import OfferAuctionCard from '../../components/UI/offerAuctionCard/offerAuctionCard'

import pencilIcon from '../../assets/icons/pencil_icon.svg'
import { Link } from 'react-router-dom'

export default function OfferAuctions() {
  const [filters, setFilters] = useState({ status: 'pending' })

  const [campaignPrice, setCampaignPrice] = useState('')

  const [campaigns, setCampaigns] = useState([])
  const [theme, setTheme] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [price, setPrice] = useState('')
  const [followersRange, setFollowersRange] = useState([0, 1000000])

  const fetchAuctions = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API +
          `/offers/auctions?filters=${JSON.stringify(filters)}`,
        { withCredentials: true }
      )
      .then(response => {
        setCampaigns(response.data)
      })
  }

  const applyFilter = e => {
    e.preventDefault()

    let _filters = {
      //acctype: e.target.acctype.value || "any",
      //gender: e.target.gender.value || "any",
      'offerData.platform': platforms || 'any',
      themes: theme.length > 0 ? { $in: theme.map(e => e.value) } : 'any',
      //age: { $gte: e.target.age.value || "any" },
      //ageTo: e.target.ageTo.value || 0,

      $and: [
        {
          minFollowers: { $gte: followersRange[0] },
        },
        { minFollowers: { $lte: followersRange[1] } },
      ],

      // card.minFollowers ? { $gte: followersRange.from || 0, } : "any",

      status: 'pending',
      price: { $gte: price || 0 },
      //'offerData.geo': e.target.location.value || "any"

      //coverage: e.target.coverage.value || 0,
    }

    console.log(_filters)

    setFilters(_filters)

    /*
        axios.get(process.env.REACT_APP_BACKEND_API + `campaigns?filters=${JSON.stringify(_filters)}`, { withCredentials: true }).then(response => {
            console.log(response.data)
            setData(response.data.map(card => [
                _(<a href={"/campaign/" + card._id} > {card.title} </a>),
                _(<>{card.platform} <a href={card.link}><ExternalLink /></a></>),
                card.price,
                _((card.user == AuthService.getCurrentUser().id) ? <button className={"btn btn-sm btn-outline-primary"} >Редактировать</button> : <button className={"btn btn-sm btn-outline-success"} >Принять</button>)
            ]))
        })
        */
  }

  const resetFilter = e => {
    e.preventDefault()

    setPrice('')
    setPlatforms([])
    setTheme([])
    setFollowersRange([0, 1000000])
    setFilters({ status: 'pending' })
  }

  useEffect(() => {
    console.log(filters)
  }, [filters])

  const handleMultiSelect = chices => {
    let a = []
    chices.map(({ value, name }) => {
      a.push(value)
    })

    console.log(a)
    setTheme(a)
  }

  useEffect(() => {
    fetchAuctions()
  }, [])

  useEffect(() => {
    fetchAuctions()
  }, [filters])

  useEffect(() => {
    console.log(campaigns)
  }, [campaigns])

  return (
    <div className="page offer-auctions">
      <Navbar campaigns></Navbar>
      <div className="page-wrapper">
        <PageHeader title="РЕКЛАМНЫЕ ПРЕДЛОЖЕНИЯ">
          <div className="btn-list">
            <Link
              to="/offersauction/create"
              style={{ minWidth: '280px' }}
              className="btn btn_secondary text-14-bold">
              <img
                style={{ height: '13px' }}
                className="ms-1"
                src={pencilIcon}
                alt=""
              />
              <span className="mx-auto">разместить предложение</span>
            </Link>
          </div>
        </PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="row g-4">
              <div
                className="col-md-4 border-r_gradient"
                style={{ overflow: 'auto' }}>
                <form
                  action="#"
                  method="get"
                  autoComplete="off"
                  noValidate=""
                  className="sticky-top"
                  onSubmit={applyFilter}
                  onReset={resetFilter}>
                  <div className="mb-3">
                    <div className="form-label">Платформа</div>
                    <RadioGroup
                      between={window.innerWidth > 1307 ? true : false}
                      buttonStyle={{ width: '75px', height: '45px' }}
                      options={platformRadio}
                      name={'platform'}
                      setter={setPlatforms}
                      getter={platforms}
                    />
                  </div>

                  {/* 
                                    <div className='mb-3'>
                                        <div className="form-label">Тип аккаунта</div>
                                        <RadioGroup options={accTypeRadio} name={'acctype'} setter={setAccTypes} getter={accTypes}></RadioGroup>
                                    </div> */}

                  <div className="mb-3">
                    <div className="mb-3">
                      <NewInput
                        type="number"
                        className="form-control mb-3"
                        name="price"
                        placeholder="Стоимость от"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  {/*<div className='mb-3'>
                                        <CityInput type="text" name="location" placeholder="Местоположение" autoComplete="off" />
                                    </div>

                                    <div className="mb-3">
                                        <Select placeholder="Тематика" options={themes} isMulti onChange={e => setTheme(e)} value={theme}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    neutral0: '#1a2234',
                                                    neutral20: '#243049',
                                                    neutral10: '#243049',
                                                    neutral80: '#fff',
                                                    primary25: '#206bc4',
                                                    primary: '#206bc4',
                                                }
                                            })}
                                            styles={{
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#242d42',
                                                    border: '1px solid white',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: 'white',
                                                }),
                                            }}
                                        />
                                        </div>*/}
                  <div className="mb-3">
                    <div className="form-label">Количество подписчиков</div>
                    <InputDoubleRange
                      initialRange={followersRange}
                      forwardRange={setFollowersRange}
                    />
                  </div>

                  <div className="mt-4 d-flex flex-wrap justify-content-between">
                    <Button
                      type="submit"
                      className="btn btn_primary"
                      style={{ width: '45%' }}>
                      Применить
                    </Button>
                    <Button
                      type="reset"
                      className="btn btn_secondary"
                      style={{ width: '45%' }}>
                      Сбросить
                    </Button>
                  </div>
                </form>
              </div>

              <div className="col-md-8">
                <div className="row row-deck g-4">
                  {campaigns.length > 0 ? (
                    campaigns.map((card, i) => (
                      <OfferAuctionCard key={card._id} i={i} card={card} />
                    ))
                  ) : (
                    <NotFoundMessage>
                      Не найдено активных рекламных предложений, вы можете
                      разместить новое
                    </NotFoundMessage>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

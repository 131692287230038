import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'
import '../../dropzone.css'

import { useRef, useEffect, useState } from 'react'
import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import Form from 'react-validation/build/form'

import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import DropzoneComponent from 'react-dropzone-component'
import Select from '../../components/UI/newInput/select'
import { createDraft } from '../../services/offers.service'
import NewInput from '../../components/UI/newInput/newInput.tsx'
import Bordered from '../../components/UI/bordered/bordered'

import MyCheckBox from '../../components/UI/myCheckBox/myCheckBox'
import Button from '../../components/UI/button/button'

const CreateDraft = () => {
  let navigate = useNavigate()

  const platforms = [
    { value: 'vk', label: 'VK' },
    { value: 'instagram', label: 'Instagram*' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'dzen', label: 'Дзен' },
    { value: 'odnoklassniki', label: 'Одноклассники' },
  ]

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [platform, setPlatform] = useState('')
  const [link, setLink] = useState('')
  const [geo, setGeo] = useState('')
  const [files, setFiles] = useState([])

  const [createLink, setCreateLink] = useState(false)
  const [hidden, setHidden] = useState(false)

  const create = () => {
    createDraft({
      title: title,
      description: description,
      platform: platform,
      link: link,
      geo: geo,
      files: files,
    })
      .then(res => {
        navigate(`/drafts`, { replace: true })
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  var componentConfig = {
    iconFiletypes: [
      '.jpg',
      '.png',
      '.gif',
      '.pdf',
      '.doc',
      '.docx',
      '.ppt',
      'pptx',
    ],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_API + `upload`,
    headers: { withCredentials: true },
  }
  var djsConfig = {
    autoProcessQueue: true,
    addRemoveLinks: true,
    acceptedFiles:
      'image/jpeg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
    params: {
      page: 'offer',
    },
  }
  var uploadEventHandlers = {
    addedfile: file => console.log(file),
    success: file => console.log('Complete', file),
    complete: file => {
      const fileJson = JSON.parse(file.xhr.response)
      console.log('Success', JSON.parse(file.xhr.response))
      setFiles(prevFiles => {
        return [...prevFiles, fileJson._id]
      })
    },
    removedfile: file => {
      console.log('removing...', file)
      const fileJson = JSON.parse(file.xhr.response)

      // TODO send call to _unlink_ API to delete file in DB
      setFiles(prevFiles => {
        const index = prevFiles.indexOf(fileJson._id)
        prevFiles.splice(index, 1)
        return [...prevFiles]
      })
    },
  }

  return (
    <div className="page">
      <Navbar drafts></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Создание шаблона" />
        <div className="page-body">
          <div className="container-xl">
            <div className="">
              <div className="card-body">
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <Select
                          placeholder="Платформа"
                          options={platforms}
                          value={platform}
                          onChange={choice => setPlatform(choice.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Название рекламного шаблона"
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <Bordered>
                          <textarea
                            type="text"
                            className="form-control"
                            rows="6"
                            placeholder="Описание
                                                (информация о продукте, какую задачу ставите перед
блогерами, техническое задание, описание креатива)"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                          ></textarea>
                        </Bordered>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="География будущих клиентов"
                          value={geo}
                          onChange={e => setGeo(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <NewInput
                          type="text"
                          className="form-control"
                          placeholder="Сайт, ссылка на магазин, социальные сети"
                          value={link}
                          onChange={e => setLink(e.target.value)}
                        />
                        <label className="form-check ">
                          <MyCheckBox
                            className="form-check-input "
                            type="checkbox"
                            checked={createLink}
                            onChange={e => setCreateLink(!createLink)}
                          />
                          <span className="form-check-label mt-3">
                            Создать короткую ссылку
                          </span>
                        </label>
                        <span className="text--disabled">
                          (?) Коротка ссылка позволяет отслеживать переходы по
                          ссылке
                        </span>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label text-gradient">
                        Материалы
                      </label>
                      <Bordered>
                        <DropzoneComponent
                          style={{ borderColor: 'red !important' }}
                          config={componentConfig}
                          eventHandlers={uploadEventHandlers}
                          djsConfig={djsConfig}
                        />
                      </Bordered>
                    </div>

                    <div className="col-md-6">
                      {JSON.stringify(files) ? files : null}
                    </div>
                  </div>
                </Form>
              </div>
              <div className="card-footer">
                <div className="row align-items-center">
                  <div className="col">
                    Свяжитесь с поддержкой, если нужна помощь при создании
                    оффера
                  </div>
                  <div className="col-auto">
                    <Button
                      className="btn btn_primary"
                      onClick={() => create()}
                    >
                      Создать шаблон
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDraft

import axios from 'axios'

const API_URL = process.env.REACT_APP_BACKEND_API

/*payload
    {
        "page": "any"
    }
*/
export const uploadFile = async payload => {
  const { data } = await axios.post(API_URL + `upload`, payload, {
    withCredentials: true,
  })
  return data
}

export const getFileById = async id => {
  const data = await axios.get(API_URL + `file/${id}`, {
    withCredentials: true,
  })
  return data
}

/* ids:
    fileId1,fileId2...
*/
export const getFilesMetadata = async ids => {
  const { data } = await axios.get(API_URL + `files/${ids}`, {
    withCredentials: true,
  })
  return data
}

import React, { useEffect, useState } from 'react'
import { required } from '../../utils/validation'
import {
  getDataArr,
  setKeyError,
  setKeyValue,
  validateFormObj,
} from '../../utils/formData'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast'
import cls from './modals.module.scss'
import { BackArrowButton } from './backArrowButton'

export const ShortLinkStats = ({ id, show, onHide }) => {
  const [stats, setStats] = useState({})
  useEffect(() => {
    if (id) {
      axios
        .get(process.env.REACT_APP_BACKEND_API + `links/${id}`, {
          withCredentials: true,
        })
        .then(response => {
          console.log(response.data)
          setStats(response.data)
        })
        .catch(error => {
          toast.error(error.response.data.message)
          console.log(error.toJSON())
        })
    }
  }, [])

  return (
    <div
      className={
        'modal modal-blur fade' + (show ? 'show' : '') + ` ${cls.modal}`
      }
      tabIndex="-1"
      style={{ display: show ? 'block' : 'none' }}
      aria-hidden={show ? 'false' : 'true'}
      aria-show={show ? 'true' : 'false'}
    >
      <div
        className=" modal-dialog justify-content-center modal-dialog-centered"
        role="document"
      >
        <div className="modal-content bordered bordered--thick bg-white pe-auto ">
          <div className=" ">
            <h3
              className={
                'w-100 modal_title text-uppercase text--page-title text-center ' +
                cls.title
              }
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  onHide()
                }}
              >
                <BackArrowButton />
              </button>
              Статистика
            </h3>
            <div className="">
              {stats?.visits?.length > 0 ? (
                stats.visits.map(visit => {
                  return (
                    <p>
                      👤 {visit.date} {visit.ua}
                    </p>
                  )
                })
              ) : (
                <p className={'text-center m-0'}>
                  По вашей ссылке пока нет статистики
                </p>
              )}
            </div>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

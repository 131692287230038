import axios from 'axios'

const API_URL = process.env.REACT_APP_BACKEND_API

export const getNotifications = async () => {
  const { data } = await axios.get(API_URL + `notifications`, {
    withCredentials: true,
  })
  return data
}

export const readNotificationById = async id => {
  await axios.get(API_URL + `notifications/read/${id}`, {
    withCredentials: true,
  })
}

export const readNotifications = async () => {
  await axios.get(API_URL + `notifications/read`, { withCredentials: true })
}

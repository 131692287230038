import React from 'react'

const HeartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8146 1.159C12.2349 -0.386334 9.67363 -0.386334 8.09391 1.159L7.4542 1.78478L6.90548 1.24801C5.32576 -0.297328 2.76452 -0.297328 1.18479 1.24801C-0.394931 2.79334 -0.394931 5.29883 1.18479 6.84416L7.47755 12.9999L13.1982 7.40378L13.1749 7.38094L13.8146 6.75516C15.3943 5.20982 15.3943 2.70434 13.8146 1.159Z"
        fill="white"
      />
    </svg>
  )
}

export default HeartIcon

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'
import '../../dropzone.css'

import { useRef, useEffect, useState } from 'react'
import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'
import Input from '../../components/UI/newInput/input'
import Form from 'react-validation/build/form'

import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Select from '../../components/UI/newInput/select'
import { isNumber, moreThan500 } from '../../utils/validation'
import {
  createOfferAuction,
  getDraftById,
  getDrafts,
} from '../../services/offers.service'
import { JewishStar } from 'tabler-icons-react'
import { getFilesMetadata } from '../../services/file.service'

const CreateOffer = () => {
  let navigate = useNavigate()

  const [drafts, setDrafts] = useState([])
  const [draft, setDraft] = useState({})
  const [offerDraftId, setOfferDraftId] = useState(undefined)
  const [files, setFiles] = useState([])

  const [price, setPrice] = useState(0)
  const [deadline, setDeadline] = useState('')

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  const createOffer = () => {
    createOfferAuction({
      offerDraftId: offerDraftId,
      title: title,
      text: text,
      price: price,
      acceptDeadline: deadline,
      finishDeadline: deadline,
    })
      .then(res => {
        navigate(`/offersauction.${res.data._id}`, { replace: true })
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const handleDraftChoice = id => {
    setOfferDraftId(id)
    getDraftById(id).then(data => setDraft(data))
  }

  useEffect(() => {
    getDrafts().then(data =>
      setDrafts(
        data.map(draft => {
          return { value: draft._id, label: draft.title }
        })
      )
    )
  }, [])

  useEffect(() => {
    draft.files?.length > 0 &&
      getFilesMetadata(draft.files.join(',')).then(files => setFiles(files))
  }, [draft])

  return (
    <div className="page">
      <Navbar campaigns></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Создание предложения (аукцион)"></PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="">
              <div className="card-body">
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Выберите шаблон</label>
                        <Select
                          placeholder="Выберите..."
                          options={drafts}
                          value={offerDraftId}
                          onChange={choice => handleDraftChoice(choice.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <span>Шаблон</span>
                        <div className="mb-3">
                          <label className="form-label">Название: </label>
                          {draft.title}
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Платформа: </label>
                          {draft.platform}
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Описание: </label>
                          {draft.description}
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Ссылка: </label>
                          {draft.url}
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Локация: </label>
                          {draft.geo}
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Файлы: </label>
                          <div
                            style={{ margin: '0 auto' }}
                            className="m-3 d-flex flex-wrap"
                          >
                            {files?.map(file => {
                              const src =
                                process.env.REACT_APP_BACKEND_API +
                                'file/' +
                                file.id
                              return (
                                <a
                                  className="btn m-1 w-25 text-wrap"
                                  key={file.id}
                                  href={src}
                                  type={file.ContentType}
                                  target="_blank" rel="noreferrer"
                                >
                                  {file.ContentType.split('/')[0] == 'image' ? (
                                    <img className="w-8" src={src} />
                                  ) : (
                                    decodeURI(file.Metadata.originalname)
                                  )}
                                </a>
                              )
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Заголовок предложения
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Возможность прорекламировать ..."
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Текст предложения</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="От исполнителя потребуется ..."
                          value={text}
                          onChange={e => setText(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Бюджет на рекламу</label>
                        <Input
                          type="text"
                          name="fullname"
                          className="form-control"
                          placeholder="1000"
                          value={price}
                          onChange={e => {
                            setPrice(e.target.value)
                          }}
                          validations={[moreThan500, isNumber]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Дедлайны</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="До .."
                          value={deadline}
                          onChange={e => setDeadline(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="card-footer">
                <div className="row align-items-center">
                  <div className="col">
                    Свяжитесь с поддержкой, если нужна помощь при создании
                    оффера
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => createOffer()}
                    >
                      Создать предложение
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOffer

import '@tabler/core/dist/css/tabler.min.css'
//import '@tabler/icons/iconfont/tabler-icons.min.css';
import '@tabler/core/dist/js/tabler.min.js'

import Navbar from '../../components/UI/navBar/navbar'
import PageHeader from '../../components/pageHeader/page-header'

import Select from '../../components/UI/newInput/select'

import { _ } from 'gridjs-react'
import { useRef, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import AuthService from '../../services/auth.service'
import { ExternalLink } from 'tabler-icons-react'

import { Grid } from 'gridjs-react'
import axios from 'axios'

import CampaignsCard from '../../components/campaignsCard'
import PlusButtonGroup from '../../components/UI/plusButtonGroup/plusButtonGroup'

export default function Campaigns() {
  const [filters, setFilters] = useState({})

  const [campaignPrice, setCampaignPrice] = useState('')

  const [data, setData] = useState([])

  const [campaigns, setCampaigns] = useState([])

  const [theme, setTheme] = useState([])

  const fetchCampaigns = () => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_API +
          `campaigns?filters=${JSON.stringify(filters)}`,
        { withCredentials: true }
      )
      .then(response => {
        setCampaigns(response.data)
      })
  }

  const applyFilter = e => {
    e.preventDefault()

    let _filters = {
      //acctype: e.target.acctype.value || "any",
      //gender: e.target.gender.value || "any",
      //platform: e.target.platform.value || "any",
      //themes: e.target.themes.value || "any",
      //age: { $gte: e.target.age.value || "any" },
      //ageTo: e.target.ageTo.value || 0,
      //followers: { $gte: e.target.followersFrom.value || 0 },
      //followersTo: e.target.followersTo.value || 0,
      price: { $gte: e.target.price.value || 0 },
      //priceTo: e.target.priceTo.value || 0,
      //location: e.target.location.value || "any",
      //coverage: e.target.coverage.value || 0,
    }

    console.log(JSON.stringify(_filters))

    setFilters(_filters)

    /*
        axios.get(process.env.REACT_APP_BACKEND_API + `campaigns?filters=${JSON.stringify(_filters)}`, { withCredentials: true }).then(response => {
            console.log(response.data)
            setData(response.data.map(card => [
                _(<a href={"/campaign/" + card._id} > {card.title} </a>),
                _(<>{card.platform} <a href={card.link}><ExternalLink /></a></>),
                card.price,
                _((card.user == AuthService.getCurrentUser().id) ? <button className={"btn btn-sm btn-outline-primary"} >Редактировать</button> : <button className={"btn btn-sm btn-outline-success"} >Принять</button>)
            ]))
        })
        */
  }

  const resetFilter = () => {
    setCampaignPrice('')
    setFilters({})
  }

  useEffect(() => {
    console.log(filters)
  }, [filters])

  const handleMultiSelect = chices => {
    let a = []
    chices.map(({ value, name }) => {
      a.push(value)
    })

    console.log(a)
    setTheme(a)
  }

  useEffect(() => {
    fetchCampaigns()
  }, [])

  useEffect(() => {
    fetchCampaigns()
  }, [filters])

  return (
    <div className="page">
      <Navbar campaigns></Navbar>
      <div className="page-wrapper">
        <PageHeader title="Рекламные кампании">
          <div className="btn-list">
            <PlusButtonGroup to="/campaign/create">
              Создать оффер
            </PlusButtonGroup>
            <Link
              to="/campaign/create"
              className={'btn btn-primary d-sm-inline-block'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Создать оффер
            </Link>
          </div>
        </PageHeader>
        <div className="page-body">
          <div className="container-xl">
            <div className="row g-4">
              <div className="col-md-4">
                <form
                  action="#"
                  method="get"
                  autoComplete="off"
                  noValidate=""
                  className="sticky-top"
                  onSubmit={applyFilter}
                >
                  {/*<div className="mb-3">
                                        <label className="myForm-label">Тематика</label>
                                        <Select placeholder="Выберите..." options={themes} isMulti onChange={(choice) => { handleMultiSelect(choice) }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                variables.scss: {
                                                    ...theme.variables.scss,
                                                    neutral0: '#1a2234',
                                                    neutral20: '#243049',
                                                    neutral10: '#243049',
                                                    neutral80: '#fff',
                                                    primary25: '#206bc4',
                                                    primary: '#206bc4',
                                                }
                                            })}
                                            styles={{
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#242d42',
                                                    border: '1px solid white',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: 'white',
                                                }),
                                            }}
                                        />
                                    </div>
                                        */}

                  <div className="mb-3">
                    <label className="form-label">Стоимость</label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      placeholder="100"
                      value={campaignPrice}
                      onChange={e => setCampaignPrice(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <button className="btn btn-primary w-100">Применить</button>
                    <a
                      href="#"
                      className="btn btn-link w-100"
                      onClick={resetFilter}
                    >
                      Сбросить
                    </a>
                  </div>
                </form>
              </div>

              {/*<div className="col-md-9">
                                <div className="card-body">
                                    <div className="btn-group w-100">
                                        <button className={"btn " + (filter == "all" ? "btn-primary" : "")} onClick={() => sf("all")}>Все</button>
                                        <button className={"btn " + (filter == "my" ? "btn-primary" : "")} onClick={() => sf("my")}>Мои офферы</button>
                                        <button className={"btn " + (filter == "vk" ? "btn-primary" : "")} onClick={() => sf("vk")}>VK</button>
                                        <button className={"btn " + (filter == "instagram" ? "btn-primary" : "")} onClick={() => sf("instagram")}>Instagram</button>
                                    </div>
                                </div>
                            </div>*/}

              <div className="col-md-8">
                {campaigns &&
                  campaigns.map((card, i) => (
                    <CampaignsCard key={card._id} i={i} card={card} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
